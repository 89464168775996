import React from "react";
import '@fontsource/dm-sans';
import { useNavigate } from "react-router-dom";

const VetCard = ({ vet }) => {
  const navigate = useNavigate();

  const handleSubmit = () => {
    navigate('/DoctorProfilePublic', { state: { docId: vet.id } });
  };

  const displaySlots = vet.timeslots.slice(0, 2);  // Get the next 2 time slots

  return ( 
    <a onClick={handleSubmit} style={{ cursor: 'pointer' }}> 
      <div className="vet-card">
        <div className="vet-header">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img src={vet.image} style={{ background: '#ddd' }} alt={vet.name} className="vet-image" />
            <div className="vet-info">
              <h2 className="nameVet" style={{ fontFamily: 'DM Sans, sans-serif', fontSize: '20px', color: '#404040' }}>{vet.name}</h2>
              <p className="titleVet" style={{ fontFamily: 'Inter, sans-serif', color: '#737373', fontSize: '16px' }}>{vet.title}, {vet.pnl}</p>
              <div className="vet-rating">
                <i className="fa-solid fa-star star"></i>
                <span className="rating">5 ·</span>
                <span className="reviews" style={{ fontFamily: 'Inter, sans-serif' }}>37 reviews</span>
              </div>
            </div>
          </div>
          <i className="fa-solid fa-chevron-right vet-arrow"></i>
        </div>
        <div className="license-verified">
          <span className="license-badge">
            <i className="fa-solid fa-check"></i> License verified
            <span className="tooltip-text">This vet has passed an extensive verification process with their FL veterinarian license</span>
          </span>
        </div>
        {/* Timeslots */}
        <div className="vet-prices-container">
          <div className="vet-prices">
            {displaySlots.length > 0 ? displaySlots.map((slot, index) => (
              <button className="timeslot-button" key={index}>
                <p style={{ cursor: 'pointer', fontFamily: 'Inter, sans-serif', fontSize: '16px' }}>
                  <strong style={{ fontFamily: 'Inter, sans-serif', fontSize: '16px' }}>{slot.displayTime}</strong>
                </p>
                <p><strong style={{ fontFamily: 'Inter, sans-serif', fontSize: '16px' }}>${slot.price}</strong></p>
              </button>
            )) : <p>No available time slots</p>}
          </div>
          <div className="more-timeslots">
            <p style={{ textDecoration: 'underline', cursor: 'pointer', fontFamily: 'Inter, sans-serif' }} onClick={handleSubmit}>See timeslots</p>
          </div>
        </div>
      </div>
    </a>
  );
}

export default VetCard;
