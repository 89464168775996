import { IoIosColorPalette } from "react-icons/io";
import { FaPaw, FaCat, FaDog, FaBirthdayCake, FaTransgender } from 'react-icons/fa';
import { FaWeightScale } from 'react-icons/fa6';
import NavbarPetParentsDashboard from '../../../Navbars/NavbarPetParentsDashboard/index';
import { FaUserDoctor } from "react-icons/fa6";
import { useLocation } from 'react-router-dom';
import { doc, getDoc, getFirestore, onSnapshot, updateDoc, collection } from "firebase/firestore";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { getAuth } from 'firebase/auth';
import { useState, useEffect, useRef } from 'react';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import '@fontsource/inter/100.css';  
import '@fontsource/inter/200.css'; 
import '@fontsource/inter/300.css';  
import '@fontsource/inter/400.css';  
import '@fontsource/inter/500.css'; 
import '@fontsource/inter/600.css'; 
import '@fontsource/inter/700.css';  
import '@fontsource/inter/800.css'; 
import '@fontsource/inter/900.css';

const AppointmentRoomPetParent = () => {
    const db = getFirestore();
    const auth = getAuth();
    const location = useLocation();
    const messagesEndRef = useRef(null);
    const quillRef = useRef(null);
    const videoInputRef = useRef(null);
    const [videoFile, setVideoFile] = useState(null);
    const [appointmentId, setAppointmentId] = useState(location.state?.appointmentId || '');
    const [appointmentData, setAppointmentData] = useState({});
    const [petData, setPetData] = useState({});
    const [newMessage, setNewMessage] = useState("");
    const [videoUrl, setVideoUrl] = useState("");

    useEffect(() => {
        if (!appointmentId) return;
        const appointmentDocRef = doc(db, 'Appointments', appointmentId);

        const unsubscribe = onSnapshot(appointmentDocRef, (doc) => {
            if (doc.exists()) {
                setAppointmentData(doc.data());
            }
        });

        return () => unsubscribe();
    }, [appointmentId]);

    useEffect(() => {
        const fetchPetData = async () => {
            if (!appointmentData.PetRefID) return;

            const user = auth.currentUser;
            if (!user) return;

            const parentDocRef = doc(db, 'Parents', user.uid);
            const petsCollectionRef = collection(parentDocRef, 'pets');
            const petDocRef = doc(petsCollectionRef, appointmentData.PetRefID);

            const petDoc = await getDoc(petDocRef);
            if (petDoc.exists()) {
                setPetData(petDoc.data());
            } else {
                console.error('No such pet document!');
            }
        };

        fetchPetData();
    }, [appointmentData.PetRefID, db, auth.currentUser]);

    useEffect(() => {
        const quillInstance = new Quill(quillRef.current, {
            theme: 'snow',
            modules: {
                toolbar: [
                    [{ 'header': '1'}, { 'header': '2' }, { 'font': [] }],
                    [{size: []}],
                    ['bold', 'italic', 'underline', 'strike'],
                    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                    ['link', 'blockquote', 'code-block', 'image'],
                    [{ 'align': [] }],
                    ['clean']
                ]
            }
        });

        quillInstance.on('text-change', () => {
            setNewMessage(quillInstance.root.innerHTML);
        });

        return () => {
            quillInstance.off('text-change');
        };
    }, []);

    const formatTimestamp = (timestamp) => {
        const date = new Date(timestamp);
        return date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric'
        }) + ', ' + date.toLocaleTimeString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        });
    };

    const handleVideoSelect = (e) => {
        const file = e.target.files[0];
        if (file) {
            setVideoFile(file);
        }
    };

    const handleCancelVideo = () => {
        setVideoFile(null);
    };

    const handleSendMessage = async () => {
        if (!newMessage.trim() && !videoFile) return;
    
        const appointmentDocRef = doc(db, 'Appointments', appointmentId);
    
        // Subir el video si existe un archivo seleccionado
        let uploadedVideoUrl = null;
        if (videoFile) {
            const storage = getStorage();
            const storageRef = ref(storage, `videos/${videoFile.name}`);
            const uploadTask = uploadBytesResumable(storageRef, videoFile);
    
            try {
                await new Promise((resolve, reject) => {
                    uploadTask.on('state_changed',
                        (snapshot) => {
                            // Mostrar progreso de la subida
                            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                            console.log('Upload is ' + progress + '% done');
                        },
                        (error) => {
                            console.error('Upload failed:', error);
                            reject(error);
                        },
                        async () => {
                            // Obtener URL de descarga del video
                            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                            uploadedVideoUrl = downloadURL;
                            console.log('Video disponible en:', downloadURL);
                            resolve(downloadURL);
                        }
                    );
                });
            } catch (error) {
                console.error('Error al subir el video:', error);
                return;
            }
        }
    
        const message = {
            text: newMessage,
            videoUrl: uploadedVideoUrl || null,
            sender: appointmentData.ParentName,
            timestamp: new Date().toISOString()
        };
    
        try {
            const appointmentDoc = await getDoc(appointmentDocRef);
            if (appointmentDoc.exists()) {
                const existingMessages = appointmentDoc.data().Messages || [];
                await updateDoc(appointmentDocRef, {
                    Messages: [...existingMessages, message]
                });
    
                setNewMessage("");
                setVideoFile(null);
                setVideoUrl("");
    
                if (quillRef.current) {
                    quillRef.current.querySelector('.ql-editor').innerHTML = '';
                }
    
                messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    
                console.log("Message sent successfully");
            } else {
                console.error('No such document!');
            }
        } catch (error) {
            console.error("Error sending message:", error);
        }
    };

    return (
    <div style={{ height: '100%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100vw' }}>
        <div className='dashboardmain' style={{ paddingBottom: '200px' }}>
            <NavbarPetParentsDashboard />

            <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'column', borderRadius: '10px', width: '90%', marginTop: '50px' }}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column', width: '100%', padding: '15px', borderBottom: '1px solid #dedcd7' }}>
                    <h4  style={{ fontWeight: '500', fontFamily:'Inter, sans-serif', fontSize:'2rem' }}>
                        <FaUserDoctor style={{ fontSize: '30px', margin: '0px 10px 0px 5px', color: '#0849c1' }} />
                        Appointment Details <span  style={{ fontWeight: '300', fontFamily:'Inter, sans-serif', fontSize:'1rem', marginRight:'20px', color:'grey' }}>ID: {appointmentId}</span>
                    </h4>
                </div>
            </div>

            <div className='AppointmentCodeMain'>
                <div className='AppointmentCodeLeft'>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', width: '100%', background: 'white', flexDirection: 'column', padding: '15px', marginBottom: '10px', borderRadius: '15px' }}>
                        <h5 style={{ fontWeight: '500', fontFamily:'Inter, sans-serif', fontSize:'1.3rem' }}>Your appointment with:<br></br> <strong style={{ fontWeight: '400', fontFamily:'Inter, sans-serif', fontSize:'1.2rem'  }} >{appointmentData.DoctorName}</strong></h5>
                        <a target='_blank'  className='meetingButton' href={appointmentData.MeetingLink}>
                            <p style={{fontFamily:'Inter, sans-serif'}}>Meeting Link</p>
                        </a>
                        <p style={{ margin: '0', paddingTop: '10px', fontSize: '12px', marginTop: '0px', fontWeight: '500', fontFamily:'Inter, sans-serif' }}>{appointmentData.AppointmentDate} | {appointmentData.AppointmentTime}</p>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', width: '100%', background: 'white', flexDirection: 'column', padding: '15px', marginBottom: '10px', borderRadius: '15px' }}>
                        <h5 style={{ fontWeight: '500', fontFamily:'Inter, sans-serif', fontSize:'1.3rem' }}>Appointment Status:</h5>
                        <p style={{ fontWeight: '400', fontFamily:'Inter, sans-serif', fontSize:'1rem' }}>{appointmentData.status || 'Pending'}</p>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', width: '100%', background: 'white', flexDirection: 'column', padding: '20px', borderRadius: '15px' }}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', width: '100%', flexDirection: 'column', borderBottom: '1px solid grey', paddingBottom: '10px' }}>
                            <h5  style={{ fontWeight: '600', fontFamily:'Inter, sans-serif', fontSize:'1.3rem' }}>Patient & client information:</h5>
                            <p  style={{ fontWeight: '600', fontFamily:'Inter, sans-serif', fontSize:'1rem' }}>{petData.Name}</p>
                            <p style={{ fontWeight: '500', fontFamily:'Inter, sans-serif', fontSize:'0.9rem', marginBottom:'15px' }}>Patient</p>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                {petData.Species === 'Cat' && <FaCat style={{ marginRight: '5px', fontSize: '0,9rem', color: '#002366' }} />}
                                {petData.Species === 'Dog' && <FaDog style={{ marginRight: '5px', fontSize: '0,9rem', color: '#002366' }} />}
                                {petData.Species !== 'Cat' && petData.Species !== 'Dog' && <FaPaw style={{ marginRight: '5px', fontSize: '12px'}} />}
                                <p style={{ fontWeight: '400', fontFamily:'Inter, sans-serif', fontSize:'0.9rem', marginLeft:'5px' }}>{petData.Species}</p>
                            </div>
                            <p style={{ fontWeight: '400', fontFamily:'Inter, sans-serif', fontSize:'0.9rem' }}><FaBirthdayCake style={{ marginRight: '5px', color: '#002366' }} /> {petData.AgeYears} years {petData.AgeMonths} months old</p>
                            <p style={{ fontWeight: '400', fontFamily:'Inter, sans-serif', fontSize:'0.9rem' }}><FaBirthdayCake style={{ marginRight: '5px', color: '#002366' }} /> {petData.Dob}</p>
                            <p style={{ fontWeight: '400', fontFamily:'Inter, sans-serif', fontSize:'0.9rem' }}><FaPaw style={{ marginRight: '5px', color: '#002366' }} /> {petData.Breed}</p>
                            <p style={{ fontWeight: '400', fontFamily:'Inter, sans-serif', fontSize:'0.9rem' }}><IoIosColorPalette style={{ marginRight: '5px', color: '#002366' }} /> {petData.PetColor}</p>
                            <p style={{ fontWeight: '400', fontFamily:'Inter, sans-serif', fontSize:'0.9rem' }}><FaTransgender style={{ marginRight: '5px', color: '#002366' }} /> {petData.Gender}</p>
                            <p style={{ fontWeight: '400', fontFamily: 'Inter, sans-serif', fontSize: '0.9rem' }}>
                                <FaWeightScale style={{ marginRight: '5px', color: '#002366' }} />
                                {petData.WeightUnit === "kg"
                                    ? `${petData.Weight} kg (${(petData.Weight * 2.2).toFixed(2)} lb)`
                                    : petData.WeightUnit === "lbs"
                                        ? `${petData.Weight} lb (${(petData.Weight / 2.2).toFixed(2)} kg)`
                                        : `${petData.Weight} ${petData.WeightUnit}`
                                }
                            </p>
                            <p style={{ fontWeight: '400', fontFamily:'Inter, sans-serif', fontSize:'0.9rem', marginTop:'15px' }}><strong style={{ fontWeight: '600', fontFamily:'Inter, sans-serif', fontSize:'0.9rem' }}>Spayed:</strong> {petData.Sprayed ? 'Yes' : 'No'}</p>
                            <p style={{ fontWeight: '400', fontFamily:'Inter, sans-serif', fontSize:'0.9rem' }}><strong style={{ fontWeight: '600', fontFamily:'Inter, sans-serif', fontSize:'0.9rem' }} >Concerns:</strong> {appointmentData.PetConcerns?.join(', ')}</p>
                            <p style={{ fontWeight: '400', fontFamily:'Inter, sans-serif', fontSize:'0.9rem' }}><strong style={{ fontWeight: '600', fontFamily:'Inter, sans-serif', fontSize:'0.9rem' }} >More Info:</strong> {appointmentData.MoreDetails}</p>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', width: '100%', flexDirection: 'column', marginTop: '8px' }}>
                            <p style={{ fontWeight: '600', fontFamily:'Inter, sans-serif', fontSize:'1rem' }}>{appointmentData.ParentName}</p>
                            <p  style={{ fontWeight: '500', fontFamily:'Inter, sans-serif', fontSize:'0.9rem' }}>Client</p>
                            <p  style={{ fontWeight: '400', fontFamily:'Inter, sans-serif', fontSize:'0.8rem' }}>{appointmentData.ParentEmail}</p>
                        </div>
                    </div>
                </div>

                <div className='AppointmentCodeRight'>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', padding: '10px', background: '#fbfbfb', borderRadius: '10px' }}>
                        <h5 style={{ fontWeight: '500', fontFamily:'Inter, sans-serif' }}>Messages</h5>
                        <div style={{ flex: 1, overflowY: 'scroll', padding: '10px', background: '#fff', borderRadius: '10px', maxHeight: '300px' }}>
                            {appointmentData.Messages?.map((msg, index) => {
                                const isDoctor = msg.sender.startsWith("Dr.");
                                const isDataAssessmentPlan = msg.formal;

                                return (
                                    <div
                                        key={index}
                                        style={{
                                            background: isDataAssessmentPlan
                                                ? '#e6ffe6'
                                                : isDoctor
                                                    ? '#e6f7ff'
                                                    : '#ffecd1',
                                            borderRadius: '0px 10px 10px 10px',
                                            padding: '20px',
                                            marginBottom: '15px',
                                            border: isDataAssessmentPlan
                                                ? '1px solid #b3ffb3'
                                                : isDoctor
                                                    ? '1px solid #a3daff'
                                                    : '1px solid #ffc966',
                                            color: '#333',
                                            alignSelf: isDoctor ? 'flex-start' : 'flex-end',
                                            width: 'fit-content',
                                            maxWidth: '70%'
                                        }}
                                    >
                                        {isDataAssessmentPlan ? (
                                            <div dangerouslySetInnerHTML={{ __html: msg.text }} />
                                        ) : (
                                            <>
                                                <strong style={{ fontWeight: '500', fontFamily: 'Inter, sans-serif', fontSize: '13px', marginBottom: '15px' }}>
                                                    {msg.sender}
                                                </strong>
                                                <p style={{ fontWeight: '400', fontFamily: 'Inter, sans-serif', fontSize: '10px', color: '#555', marginTop: '-3px' }}>
                                                    {formatTimestamp(msg.timestamp)}
                                                </p>
                                                <div dangerouslySetInnerHTML={{ __html: msg.text }} />
                                                {msg.videoUrl && (
                                                    <video controls style={{ width: '100%', marginTop: '10px' }}>
                                                        <source src={msg.videoUrl} type="video/mp4" />
                                                        Tu navegador no soporta el elemento de video.
                                                    </video>
                                                )}
                                            </>
                                        )}
                                    </div>
                                );
                            })}
                            <div ref={messagesEndRef} />
                        </div>

                        {/* Input para subir el video */}
                        <input
                            type="file"
                            accept="video/*"
                            ref={videoInputRef}
                            onChange={handleVideoSelect}
                            style={{ margin: '10px 0', padding: '10px', borderRadius: '5px', border: '1px solid #ccc' }}
                        />

                        {videoFile && (
                            <div style={{ marginBottom: '10px' }}>
                                <p style={{ fontWeight: '500' }}>Select Video: {videoFile.name}</p>
                                <button onClick={handleCancelVideo} style={{ padding: '5px 10px', borderRadius: '5px', border: 'none', backgroundColor: '#c40000', color: 'white', cursor: 'pointer' }}>
                                    Cancel Video
                                </button>
                            </div>
                        )}

                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            {/* Editor de mensajes Quill */}
                            <div ref={quillRef} style={{ height: '150px', marginBottom: '10px', background: 'white' }} />

                            {/* Botón para enviar el mensaje o video */}
                            <button
                                onClick={handleSendMessage}
                                style={{
                                    padding: '10px 20px',
                                    borderRadius: '5px',
                                    border: 'none',
                                    backgroundColor: '#0849c1',
                                    color: 'white',
                                    fontWeight: 'bold',
                                    cursor: 'pointer',
                                    marginTop: '10px'
                                }}
                            >
                                Send
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

};

export default AppointmentRoomPetParent;
