import { onAuthStateChanged } from "firebase/auth"; 
import React, { useState, useEffect } from 'react';
import Navbar from '../../Navbars/NavbarMain/NavbarMain';
import { FaHands } from "react-icons/fa";
import DogDP from '../../1_MediaAssets/OtherBasics/dog.jpg'; 
import CatDP from '../../1_MediaAssets/OtherBasics/cat.jpg'; 
import { MdOutlinePets } from "react-icons/md";
import { useNavigate, useLocation } from 'react-router-dom';
import { getFirestore, doc, getDoc, collection, getDocs, addDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { RxCross1 } from "react-icons/rx";
import PawTexture from '../../1_MediaAssets/OtherBasics/Texture.png';
import CatPeeking from '../../1_MediaAssets/Homepage/PetStickers/catpeeking.png';

const AppointmentConfirmationPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { DoctorID, DocType, Rate, Date: appointmentDate, Time } = location.state || {}; // Changed Date to appointmentDate
    const [otherConcern, setOtherConcern] = useState(''); // State for "Other" concern input
    const [showOtherInput, setShowOtherInput] = useState(false); // State to show/hide the "Other" input

    const [options, setOptions] = useState([]);
    const [pets, setPets] = useState([]);
    const [PetConcerns, setPetConcerns] = useState([]);
    const [doctor, setDoctor] = useState({});
    const [selectedPetId, setSelectedPetId] = useState(null);
    const [showPopUp, setShowPopUp] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [moreDetails, setMoreDetails] = useState(''); // State for "moreDetails" input

    const [formData, setFormData] = useState({
        Name: '',
        Species: '',
        Breed: '',
        Gender: '',
        Color: '',
        Weight: '',
        WeightUnit: '',
        Sprayed: '',
        Date: '',
        State: ''
    });

    const db = getFirestore();
    const auth = getAuth();

    const handleClosePopUp = () => setShowPopUp(false);
    const handleShowPopUp = () => setShowPopUp(true);

    useEffect(() => {
        const fetchData = async (user) => {
            try {
                // Fetch Doctor Info
                if (DoctorID && DocType) {
                    const doctorRef = doc(db, `Doctors/${DocType}/${DocType}`, DoctorID);
                    const doctorSnap = await getDoc(doctorRef);
                    if (doctorSnap.exists()) { 
                        setDoctor(doctorSnap.data());
                    } else {
                        console.log("No such doctor document!");
                    }
                }
    
                // Fetch Pets Info
                if (user) {
                    console.log("I am in the IF CONDITION FOR PETS RENDER");
                    const petsCollection = collection(db, `Parents/${user.uid}/pets`);
                    const petsSnapshot = await getDocs(petsCollection);
                    const petsList = petsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                    setPets(petsList);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
    
        // Subscribe to authentication state changes
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                await fetchData(user);
            } else {
                console.log("No user is authenticated.");
                // Handle case when there is no authenticated user
            }
        });
    
        // Clean up the subscription on unmount
        return () => unsubscribe();
    }, [DoctorID, DocType, auth, db]);

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData(prevFormData => ({ ...prevFormData, [id]: value }));
    };

    const submit = async (e) => {
        e.preventDefault();
        setErrorText('');
        
        const Name = document.getElementById('name').value;
        const Species = document.getElementById('species').value;
        const PetColor = document.getElementById('color').value;
        const Gender = document.getElementById('gender').value;
        const Breed = document.getElementById('breed').value;
        const Weight = document.getElementById('weight').value;
        const WeightUnit = document.getElementById('weightunit').value;
        const Sprayed = document.getElementById('sprayed').value;
        const dobValue = document.getElementById('dob').value;
        
        if (!Name || !Weight || !Species || !Gender || !Breed || !Sprayed || !dobValue || !PetColor) {
            setErrorText("Input all fields!");
            document.getElementById("errortext2").innerHTML = "Input all fields!";
            return;
        }
        
        const DOB = new Date(dobValue);
        const today = new Date();
        const birthDate = new Date(DOB);
        let ageYears = today.getFullYear() - birthDate.getFullYear();
        let ageMonths = today.getMonth() - birthDate.getMonth();
        
        if (ageMonths < 0) {
            ageYears--;
            ageMonths += 12;
        }
        
        const petData = { Name, Weight, WeightUnit, Species, Gender, Breed, Sprayed, AgeYears: ageYears, AgeMonths: ageMonths, PetColor };
        const user = auth.currentUser;
        
        if (user) {
            try {
                const petsCollection = collection(db, 'Parents', user.uid, 'pets');
                await addDoc(petsCollection, petData);
                handleClosePopUp();
                const petsSnapshot = await getDocs(petsCollection);
                const updatedPetsList = petsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setPets(updatedPetsList);
            } catch (error) {
                console.error("Error adding pet:", error);
            }
        }
    };
    
    const handleSubmit = async () => {
        if (selectedPetId && PetConcerns.length > 0) {
            try {
                const user = auth.currentUser;
                if (user) {
                    const appointmentData = {
                        DoctorID,
                        DocType,
                        Rate,
                        Date: appointmentDate,
                        Time,
                        PetConcerns,
                        selectedPetId,
                        moreDetails,
                        State: formData.State
                    };
                    const appointmentsCollection = collection(db, 'Parents', user.uid, 'appointments');
                    await addDoc(appointmentsCollection, appointmentData);
                    navigate('/AppointmentDetailsPage', { state: appointmentData });
                }
            } catch (error) {
                console.error("Error handling submit:", error);
            }
        } else {
            document.getElementById("errortext").innerHTML = "Please select a pet and at least one concern before submitting.";
            setErrorText("Please select a pet and at least one concern before submitting.");
        }
    };

    const optionClick = (option) => {
        if (option === "Other") {
            setShowOtherInput(true); // Show input field when "Other" is clicked
        } else {
            setShowOtherInput(false); // Hide input field if another option is clicked
            setPetConcerns(prevConcerns => {
                const concernsIndex = prevConcerns.indexOf(option);
                if (concernsIndex === -1) {
                    return [...prevConcerns, option];
                } else {
                    const updatedConcerns = [...prevConcerns];
                    updatedConcerns.splice(concernsIndex, 1);
                    return updatedConcerns;
                }
            });
        }

        setOptions(prevOptions => {
            const index = prevOptions.indexOf(option);
            if (index === -1) {
                return [...prevOptions, option];
            } else {
                const updatedOptions = [...prevOptions];
                updatedOptions.splice(index, 1);
                return updatedOptions;
            }
        });
    };

    const handleOtherConcernSubmit = () => {
        if (otherConcern) {
            setPetConcerns(prevConcerns => [...prevConcerns, otherConcern]); // Add the other concern
            setOtherConcern(''); // Clear the input
            setShowOtherInput(false); // Hide the input field
        }
    };

    const optionClick2 = (pet) => {
        setSelectedPetId(pet.id);
        const selectedPetName = pet.Name;
        const updatedPets = pets.map(p => {
            if (p.Name === selectedPetName) {
                return { ...p, selected: true };
            } else {
                return { ...p, selected: false };
            }
        });
        setPets(updatedPets);
    };

    return (
        <div style={{ display: 'flex',  justifyContent: 'center',  alignItems: 'flex-start', width: '100vw', height: '100vh', paddingTop: '70px', boxSizing: 'border-box' }}>
        <div className="responsiveConfirmationPage3"  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '70px',  width: '80vw' , height:'auto'}}>
            <div className='dashboardmain2'>
                <Navbar />

                <div style={{ padding: '20px', display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', flexDirection: 'column', background: '#fff', borderRadius: '10px', marginTop: '20px', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}}>
                    
                <div className='DocProfDivMain' style={{ display: 'flex', alignItems: 'flex-start', height:'auto' }}>
                    <div className='DocProfDivLeft' style={{ flex: '1', textAlign: 'center' }}>
                        <img src={doctor.profilePictureURL} alt="Profile" style={{ width: '120px', height: '120px', borderRadius: '50%', objectFit: 'cover', border: '2px solid #ddd' }} />
                        <h1 style={{ margin: '10px 0', fontSize: '24px', fontWeight: 'bold' }}>Dr. {doctor.FirstName} {doctor.LastName}</h1>
                        <p style={{ margin: '5px 0', fontSize: '16px', color: '#555' }}>{doctor.PostNominalLetters}</p>
                        <p style={{ margin: '5px 0', fontSize: '16px', color: '#555' }}>{doctor.City}, {doctor.State}</p>
                    </div>
                    <div className='DocProfDivRight' style={{ flex: '2' }}>
                        <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', flexDirection: 'column', background: '#fff', borderRadius: '10px', width: '100%'}}>
                            
                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column', width: '100%', padding: '15px', borderBottom: '1px solid #dedcd7', position: 'relative' }}>
                                <h6 style={{padding: '0', margin: '0', display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: '900'}}>
                                    <MdOutlinePets style={{fontSize: '20px', margin: '0px 5px 0px 0px', color: '#0849c1'}}/>
                                    For
                                </h6>
                                <button onClick={handleShowPopUp} style={{position: 'absolute', top: '10px', right: '10px', fontSize: '10px'}}>Add +</button>
                            </div> 
                            
                            <div className='YourPetsDiv' style={{justifyContent: 'flex-start'}}>
                                <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', width: '100%', margin: '0', padding: '0'}}>
                                    <p style={{fontSize: '14px'}}>Choose your pet:</p>
                                </div>
                                {pets.map(pet => (
                                <div className='PetDetailDiv' style={{width: '100%', maxWidth: '350px', height: '150px',backgroundColor: pet.selected ? '#FFE699' : '#edeef0'}} onClick={()=>{optionClick2(pet)}}>
                                    <img src={pet.Species === 'Dog' ? DogDP : CatDP} alt={pet.Name}style={{height: '50px', width: '50px', borderRadius: '100px', margin: '10px'}}/>
                                    <p style={{ margin: '0', padding: '0', marginTop: '5px' }}>{pet.Name}</p>
                                    <p style={{ marginTop: '-5px', padding: '0', fontSize: '12px' }}>{pet.Species}, {pet.AgeYears} years old</p>
                                </div>))}
                            </div>

                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column', width: '100%', padding: '15px', borderBottom: '1px solid #dedcd7' }}>
                                <h6 style={{padding: '0', margin: '0', display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: '900'}}>
                                    <FaHands style={{fontSize: '20px', margin: '0px 7px 2px 0px', color: '#0849c1'}}/>
                                    Concerns
                                </h6>
                            </div> 

                            <div className='YourPetsDiv' style={{ justifyContent: 'flex-start' }}>
                                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', width: '100%', margin: '0', padding: '0' }}>
                                    <p style={{ fontSize: '14px' }}>Choose all that apply:</p>
                                </div>
                                {[
                                    "Nutrition", "Skin and ear infections", "Eye issues", "Diarrhea and vomiting", "Urinary problems", "Mobility concerns",
                                    "Flea and tick", "Trauma and injury triage", "Preventative wellness", "Toxin ingestion", "Training and obedience", "Other"
                                ].map((option, index) => (
                                    <div
                                        key={index}
                                        className="ConcernDiv"
                                        style={{
                                            background: options.includes(option) ? "#FFE699" : "#edeef0"
                                        }}
                                        onClick={() => optionClick(option)}
                                    >
                                        <p style={{ color: 'black', margin: '0', padding: '0', fontSize: '14px' }}>{option}</p>
                                    </div>
                                ))}
                                
                                {showOtherInput && (
                                    <div style={{ marginTop: '10px', width: '100%', display: 'flex', alignItems: 'flex-end', flexDirection: 'column' }}>
                                        <input 
                                            type="text" 
                                            value={otherConcern} 
                                            onChange={(e) => setOtherConcern(e.target.value)} 
                                            placeholder="Please specify your concern" 
                                            style={{ padding: '5px 10px', width: '100%', borderRadius: '5px', border: '1px solid #ccc' }} 
                                        />
                                        <button 
                                            onClick={handleOtherConcernSubmit} 
                                            style={{ marginTop: '5px', padding: '5px 10px', backgroundColor: '#d49400', border: 'none', color: 'white', borderRadius: '5px', width: '100px' }}
                                        >
                                            Add
                                        </button>
                                    </div>
                                )}

                                <div style={{ marginTop: '20px', width: '100%' }}>
                                    <label htmlFor="moreDetails" style={{ fontWeight: 'bold', fontSize: '14px', color: '#333' }}>More details about your pet's problem:</label>
                                    <textarea
                                        id="moreDetails"
                                        value={moreDetails}
                                        onChange={(e) => setMoreDetails(e.target.value)}
                                        placeholder="Provide more information about your pet's condition"
                                        style={{ width: '100%', height: '100px', borderRadius: '5px', padding: '10px', border: '1px solid #ccc', marginTop: '10px' }}
                                    />
                                </div>
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: "100%", gap: '5px'}}>
    <select
        id="State"
        value={formData.State}
        onChange={handleChange}
        required
        style={{
            background: "white",
            border: "1px solid #ddd",
            borderRadius: '5px',
            width: "100%",
            height: '35px',
            color: "black",
            paddingLeft: '5px',
            paddingRight: '5px',
            fontSize: '13px',
            backgroundColor: 'white'
        }}
    >
        <option disabled selected value="">Select State</option>
        <option value="AL">Alabama</option>
        <option value="AK">Alaska</option>
        <option value="AZ">Arizona</option>
        <option value="AR">Arkansas</option>
        <option value="CA">California</option>
        <option value="CO">Colorado</option>
        <option value="CT">Connecticut</option>
        <option value="DE">Delaware</option>
        <option value="FL">Florida</option>
        <option value="GA">Georgia</option>
        <option value="HI">Hawaii</option>
        <option value="ID">Idaho</option>
        <option value="IL">Illinois</option>
        <option value="IN">Indiana</option>
        <option value="IA">Iowa</option>
        <option value="KS">Kansas</option>
        <option value="KY">Kentucky</option>
        <option value="LA">Louisiana</option>
        <option value="ME">Maine</option>
        <option value="MD">Maryland</option>
        <option value="MA">Massachusetts</option>
        <option value="MI">Michigan</option>
        <option value="MN">Minnesota</option>
        <option value="MS">Mississippi</option>
        <option value="MO">Missouri</option>
        <option value="MT">Montana</option>
        <option value="NE">Nebraska</option>
        <option value="NV">Nevada</option>
        <option value="NH">New Hampshire</option>
        <option value="NJ">New Jersey</option>
        <option value="NM">New Mexico</option>
        <option value="NY">New York</option>
        <option value="NC">North Carolina</option>
        <option value="ND">North Dakota</option>
        <option value="OH">Ohio</option>
        <option value="OK">Oklahoma</option>
        <option value="OR">Oregon</option>
        <option value="PA">Pennsylvania</option>
        <option value="RI">Rhode Island</option>
        <option value="SC">South Carolina</option>
        <option value="SD">South Dakota</option>
        <option value="TN">Tennessee</option>
        <option value="TX">Texas</option>
        <option value="UT">Utah</option>
        <option value="VT">Vermont</option>
        <option value="VA">Virginia</option>
        <option value="WA">Washington</option>
        <option value="WV">West Virginia</option>
        <option value="WI">Wisconsin</option>
        <option value="WY">Wyoming</option>
    </select>
</div>

                                <div id='errortext' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '13px', color: 'red', width: "95%", height: '13px' }}></div>

                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: "center", width: "100%" }}>
                                    <button id='SearchButton' onClick={handleSubmit} style={{ width: '100%', borderRadius: '5px', fontSize: '13px' }}>
                                        Submit
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>
                    </div>
                </div>
            </div>

            {showPopUp && (
                <div style={{position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.9)', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: '1000000'}}>
                    <RxCross1 style={{cursor: 'pointer', marginBottom: '10px', position: 'absolute', top: '10px', right: '10px', fontSize: '25px', fontWeight: '900', color: 'white'}} onClick={handleClosePopUp} />
                    <div style={{position: 'relative', background: '#fff', padding: '20px', borderRadius: '10px', width: '95vw', maxWidth: '800px', backgroundImage:`url('${PawTexture}')`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}}>
                        <img alt="CatPeeking" src={CatPeeking} style={{position: 'absolute', top: '-4px', right: '40px', height: '40px'}}/>
                        <h3 className='fontSpecial'style={{color: '#002366', fontSize: '20px'}}>Add Pet</h3>
                        <form onSubmit={submit} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: '7px', marginTop: '15px'}}>
                            
                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: "100%", gap: '5px'}}>
                                <input type="text" id="name" placeholder="Name" style={{background: "white", border: "1px solid #ddd", borderRadius: '5px', width: "100%", height: '35px', color: "black", paddingLeft: '5px', paddingRight: '5px', fontSize: '13px', backgroundColor: 'white'}}/>
                                <select id="species" required style={{background: "white", border: "1px solid #ddd", borderRadius: '5px', width: "100%", height: '35px', color: "black", paddingLeft: '5px', paddingRight: '5px', fontSize: '13px', backgroundColor: 'white'}}>
                                    <option disabled selected value="">Species</option>
                                    <option value="Dog">Dog</option>
                                    <option value="Cat">Cat</option>
                                    <option value="Horse">Horse</option>
                                    <option value="Bird">Bird</option>
                                    <option value="Rabbit">Rabbit</option>
                                    <option value="Small Mammal">Small Mammal</option>
                                    <option value="Reptile">Reptile</option>
                                    <option value="Amphibian">Amphibian</option>
                                    <option value="Spider">Spider</option>
                                    <option value="Fish">Fish</option>
                                    <option value="Farm Animal">Farm Animal</option>
                                    <option value="Other">Other</option>
                                </select>
                            </div>
                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: "100%", gap: '5px'}}>
                                <input type="text" id="breed" placeholder="Breed" style={{background: "white", border: "1px solid #ddd", borderRadius: '5px', width: "100%", height: '35px', color: "black", paddingLeft: '5px', paddingRight: '5px', fontSize: '13px', backgroundColor: 'white'}}/>
                                <select id="gender" required style={{background: "white", border: "1px solid #ddd", borderRadius: '5px', width: "100%", height: '35px', color: "black", paddingLeft: '5px', paddingRight: '5px', fontSize: '13px', backgroundColor: 'white'}}>
                                    <option disabled selected value="">Gender</option>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                </select>
                            </div>
                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: "100%", gap: '5px'}}>
                                <select id="color" required style={{background: "white", border: "1px solid #ddd", borderRadius: '5px', width: "100%", height: '35px', color: "black", paddingLeft: '5px', paddingRight: '5px', fontSize: '13px', backgroundColor: 'white'}}>
                                    <option disabled selected value="">Primary Color</option>
                                    <option value="Black">Black</option>
                                    <option value="Brown">Brown</option>
                                    <option value="Gold">Gold</option>
                                    <option value="White">White</option>
                                    <option value="Cream">Cream</option>
                                    <option value="Blue">Blue</option>
                                    <option value="Gray">Gray</option>
                                    <option value="Red">Red</option>
                                    <option value="Yellow">Yellow</option>
                                    <option value="Green">Green</option>
                                    <option value="Orange">Orange</option>
                                </select>
                                <select id="sprayed" required style={{background: "white", border: "1px solid #ddd", borderRadius: '5px', width: "100%", height: '35px', color: "black", paddingLeft: '5px', paddingRight: '5px', fontSize: '13px', backgroundColor: 'white'}}>
                                    <option disabled selected value="">Is your pet sprayed or neutered?</option>
                                    <option value="No">No</option>
                                    <option value="Yes">Yes</option>
                                </select>
                            </div>
                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: "100%", gap: '5px'}}>
                                <input type="text" id="weight" placeholder="Weight" style={{ background: "white", border: "1px solid #ddd", borderRadius: '5px', width: "100%", height: '35px', color: "black", paddingLeft: '5px', paddingRight: '5px', fontSize: '13px', backgroundColor: 'white'}}/>
                                <select id="weightunit" style={{width: '60px', height: '35px', margin: '0', border: "1px solid #ddd", borderRadius: '5px'}}>
                                    <option value="lbs">lbs</option>
                                    <option value="kg">kg</option>
                                </select>
                            </div>
                            <input type="date" id="dob" required style={{ background: "white", border: "1px solid #ddd", borderRadius: '5px', width: "100%", height: '35px', color: "black", paddingLeft: '5px', paddingRight: '5px', fontSize: '13px', backgroundColor: 'white' }} />
                            <div id="errortext2" style={{color: 'red', fontSize: '10px', height: '15px'}}></div>
                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: "100%", gap: '5px'}}>
                                <button onClick={handleClosePopUp} style={{width: '100%', background: 'transparent', border: '1px solid #002366', color: '#002366'}}>Cancel</button>
                                <button type="submit" id="submitpetdetails" style={{width: '100%', border: '1px solid #002366'}}>Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            )}

        </div>
        </div>
    );
};

export default AppointmentConfirmationPage;