import CutePets from "../../CutePets.js";
import React from "react";
import { FaPlus, FaTrash } from "react-icons/fa";
import Texture from '../../../1_MediaAssets/OtherBasics/Texture.png';
import Cover from '../../../1_MediaAssets/VetsandTechs/RegistrationVetsandTechs/Cover2.png';
import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const TimeOptions = () => {
  const options = [];
  for (let i = 0; i < 24; i++) {
    for (let j = 0; j < 60; j += 30) {
      let hour = i % 12 === 0 ? 12 : i % 12;
      let minute = j === 0 ? '00' : j;
      let period = i < 12 ? 'AM' : 'PM';
      const time = `${hour}:${minute} ${period}`;
      options.push(<option key={time} value={time}>{time}</option>);
    }
  }
  return options;
};

const TableComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { DocType, FirstName, LastName, EmailAddress, CountryCode, PhoneNo, PostNominalLetters, Gender, City, State, Password } = location.state;

  const [rows, setRows] = useState([
    { day: 'Monday', checked: true, timings: [{ from: '', until: '' }] },
    { day: 'Tuesday', checked: true, timings: [{ from: '', until: '' }] },
    { day: 'Wednesday', checked: true, timings: [{ from: '', until: '' }] },
    { day: 'Thursday', checked: true, timings: [{ from: '', until: '' }] },
    { day: 'Friday', checked: true, timings: [{ from: '', until: '' }] },
    { day: 'Saturday', checked: true, timings: [{ from: '', until: '' }] },
    { day: 'Sunday', checked: true, timings: [{ from: '', until: '' }] }
  ]);

  const handleAddTimeSlot = (index) => {
    const newRows = [...rows];
    newRows[index].timings.push({ from: '', until: '' });
    setRows(newRows);
  };

  const handleDeleteTimeSlot = (dayIndex, timingIndex) => {
    const newRows = [...rows];
    newRows[dayIndex].timings.splice(timingIndex, 1);
    setRows(newRows);
  };

  const handleCheckboxChange = (index) => {
    const newRows = [...rows];
    newRows[index].checked = !newRows[index].checked;
    setRows(newRows);
  };

  const handleInputChange = (dayIndex, timingIndex, e, field) => {
    const newRows = [...rows];
    newRows[dayIndex].timings[timingIndex][field] = e.target.value;

    const fromTime = newRows[dayIndex].timings[timingIndex].from;
    const untilTime = newRows[dayIndex].timings[timingIndex].until;

    const parseTime = (timeStr) => {
      const [time, period] = timeStr.split(' ');
      let [hour, minute] = time.split(':').map(Number);
      if (period === 'PM' && hour !== 12) hour += 12;
      if (period === 'AM' && hour === 12) hour = 0;
      return new Date(2000, 0, 1, hour, minute);
    };

    if (field === 'from' && untilTime) {
      const from = parseTime(fromTime);
      const until = parseTime(untilTime);
      if (from >= until) {
        newRows[dayIndex].timings[timingIndex].from = '';
        alert("The 'From' time should be before the 'Until' time.");
      }
    } else if (field === 'until' && fromTime) {
      const from = parseTime(fromTime);
      const until = parseTime(untilTime);
      if (until <= from) {
        newRows[dayIndex].timings[timingIndex].until = '';
        alert("The 'Until' time should be after the 'From' time.");
      }
    }

    setRows(newRows);
  };

  const handleSubmit = () => {
    const hasEmptyFrom = rows.some(row =>
      row.checked && row.timings.some(timing => timing.from === '')
    );
    const hasEmptyUntil = rows.some(row =>
      row.checked && row.timings.some(timing => timing.until === '')
    );

    if (hasEmptyFrom || hasEmptyUntil) {
      document.getElementById("errortext").innerHTML = "Select Start & End Times!";
      return;
    }

    console.log(Gender, "GENDER OF THE VET");
    navigate('/RegistrationVetsandTechsPage4', { state: {
      DocType, FirstName, LastName, EmailAddress, CountryCode, PhoneNo,
      PostNominalLetters, Gender, City, State, Schedule: rows, Password
    } });
  };

  return (
    <div style={{ minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden', backgroundImage: `url('${Cover}')`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundAttachment: 'fixed' }}>
      <div className='RegistrationWhiteBox' style={{ backgroundImage: `url('${Texture}')`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', marginTop: '30px', marginBottom: '30px' }}>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', padding: '30px', flexDirection: 'column' }}>
          <h2 style={{ fontWeight: '900', color: '#002366', fontSize: '30px' }}>Availability & rates </h2>
          <CutePets />
        </div>
        <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column', marginTop: '-15px' }}>
          <div className='optionBox' style={{ margin: '0', height: '100%', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
            <div className='OnlyColumnPage2' style={{ padding: '0px 2px 0px 2px', marginTop: '-30px' }}>
              <div style={{ display: 'flex', flexDirection: 'column', width: '100%', padding: '10px', marginTop: '20px', borderRadius: '15px', background: '#fff', justifyContent: 'center', alignItems: 'center' }}>
                <table style={{ width: '100%' }}>
                  <thead>
                    <tr>
                      <th style={{ fontSize: '12px', width: '25%' }}>Day</th>
                      <th style={{ fontSize: '12px', width: '30%' }}>From</th>
                      <th style={{ fontSize: '12px', width: '30%' }}>Until</th>
                      <th style={{ fontSize: '12px', width: '15%', textAlign: 'center' }}>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {rows.map((row, dayIndex) => (
                      <React.Fragment key={row.day}>
                        <tr>
                          <td style={{ border: '1px solid #c0c0c0', width: '25%', fontSize: '11px', borderRightColor: '#fff' }}>
                            <input type="checkbox" checked={row.checked} onChange={() => handleCheckboxChange(dayIndex)} style={{ width: '11px', height: '11px', marginRight: '5px'}} />
                            {row.day}
                          </td>
                          <td colSpan={3} style={{ border: '1px solid #c0c0c0' }}>
                            <table style={{ width: '100%' }}>
                              <tbody>
                                {row.timings.map((timing, timingIndex) => (
                                  <tr key={timingIndex}>
                                    <td style={{ width: '30%' }}>
                                      <select value={timing.from} onChange={(e) => handleInputChange(dayIndex, timingIndex, e, 'from')} disabled={!row.checked} style={{ width: '100%', fontSize: '12px', border: '1px solid #ddd', borderRadius: '5px', padding: '2px' }}>
                                        <option value="">Select Time</option>
                                        {TimeOptions()}
                                      </select>
                                    </td>
                                    <td style={{ width: '30%' }}>
                                      <select value={timing.until} onChange={(e) => handleInputChange(dayIndex, timingIndex, e, 'until')} disabled={!row.checked} style={{ width: '100%', fontSize: '12px', border: '1px solid #ddd', borderRadius: '5px', padding: '2px' }}>
                                        <option value="">Select Time</option>
                                        {TimeOptions()}
                                      </select>
                                    </td>
                                    <td style={{ width: '15%', textAlign: 'center' }}>
                                      {timingIndex === row.timings.length - 1 && (
                                        <button type="button" onClick={() => handleAddTimeSlot(dayIndex)} style={{ border: 'none', background: 'transparent', cursor: 'pointer' }}> <FaPlus style={{ color: '#002366', fontSize: '13px' }} /> </button>
                                      )}
                                      {row.timings.length > 1 && timingIndex === row.timings.length - 1 && (
                                        <button type="button" onClick={() => handleDeleteTimeSlot(dayIndex, timingIndex)} style={{ border: 'none', background: 'transparent', cursor: 'pointer', marginLeft: '5px' }}> <FaTrash style={{ color: '#FF0000', fontSize: '13px' }} /> </button>
                                      )}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              </div>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '0px', flexDirection: 'column', width: '100%', marginBottom: '10px' }}>
                <div id="errortext" style={{ color: 'red', fontSize: '12px', textAlign: 'center', height: '12px' }}></div>
                <button type="button" onClick={handleSubmit} style={{ width: '100%', marginTop: '12px' }}>
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  


};

export default TableComponent;
