import Navbar from '../../Navbars/NavbarMain/NavbarMain.js';
import Cover from '../../1_MediaAssets/VetsandTechs/RegistrationVetsandTechs/Cover2.png';
import DonationBox from './DonationBox.js';
import StripeContext from '../../../stripe/StripeContext.js';
import Texture from '../../1_MediaAssets/OtherBasics/Texture.png';

const DonatePage2 = () => {
    return (
    <>
        <Navbar />
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundImage: `url('${Cover}')`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundAttachment: 'fixed'}}>
            <div className='RegistrationWhiteBox' style={{ backgroundImage: `url('${Texture}')`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', margin: '120px 0px'}}>
                <StripeContext>
                    <DonationBox />
                </StripeContext>
            </div>
        </div>
    </>
    );
};

export default DonatePage2;
