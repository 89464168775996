import { FaPaw } from "react-icons/fa";
import { FaUserDoctor } from 'react-icons/fa6';
import { FaLock } from "react-icons/fa6";
import { MdAccountCircle } from "react-icons/md";
import React from 'react';
import { Link } from 'react-router-dom'; 
import NavbarPetParentsDashboard from '../../../Navbars/NavbarPetParentsDashboard';

const AccountSettings = () => {
    const settingsOptions = [
        {
            icon: <FaUserDoctor size={30} />,
            title: "Personal Information",
            subtext: "Update your details such as your name, photo, bio etc.",
            link: "/AccountMenuPetParents/PersonalInformationPetParents"
        },
        {
            icon: <FaPaw size={30} />,
            title: "Pets",
            subtext: "Manage your furry friends and their medical history",
            link: "/PetsPetParents"
        },
        {
            icon: <FaLock size={30} />,
            title: "Login and security",
            subtext: "Manage your login info and change your password",
            link: "/AccountMenuPetParents/LoginandSecurity"
        },
    ];

    return (
        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100vw', height: '100%', background: '#f5f5f5' }}>
            <div className='dashboardmain' style={{ paddingBottom: '50px' }}>
                <NavbarPetParentsDashboard />
                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'column', background: '#fff', borderRadius: '10px', width: '90%', marginTop: '50px', boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', padding: '15px', borderBottom: '1px solid #dedcd7' }}>
                        <h4 style={{ padding: '0', margin: '0', display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: '900' }}>
                            <MdAccountCircle style={{ fontSize: '30px', margin: '0px 10px 0px 5px', color: '#0849c1' }} />
                            Account Settings
                        </h4>
                    </div>
                    <div className="vetAccountMenuHolder">
                        {settingsOptions.map((option, index) => (
                            <Link to={option.link} key={index} style={{ textDecoration: 'none', color: 'inherit', flex: '1 1 calc(33.333% - 20px)', maxWidth: 'calc(33.333% - 20px)', minWidth: '300px' }}>
                                <div
                                    style={{ 
                                        background: 'rgba(250, 250, 250, 1)', 
                                        borderRadius: '10px', 
                                        padding: '20px', 
                                        display: 'flex', 
                                        flexDirection: 'column', 
                                        justifyContent: 'flex-start', 
                                        alignItems: 'flex-start', 
                                        textAlign: 'center', 
                                        boxShadow: '0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)', 
                                        transition: 'box-shadow 0.3s ease',
                                        border:'1px solid #dcdcdc ',
                                        minHeight: '180px'

                                    }}
                                    onMouseEnter={(e) => e.currentTarget.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.18), 0 6px 12px rgba(0, 0, 0, 0.15)'}
                                    onMouseLeave={(e) => e.currentTarget.style.boxShadow = '0 2px 4px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.12)'}
                                >
                                    <div style={{ fontSize: '30px', color: '#002366', marginBottom: '0' }}>
                                        {option.icon}
                                    </div>
                                    <h5 style={{ margin: '10px 0', fontSize: '18px', fontWeight: '700' }}>{option.title}</h5>
                                    <p style={{ margin: '0', fontSize: '14px', color: '#333', textAlign: 'left' }}>{option.subtext}</p>
                                </div>
                            </Link>
                        ))}
                    </div>
                </div>
            </div>
        </div>

    );
};

export default AccountSettings;
