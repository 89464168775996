const CutePets = () => { 

    return (
            <div className="Petty">
                <div className="catty" style={{position: 'fixed', bottom: '-95px', left: '60px'}}>
                    <div className="ears1"></div>
                    <div className="head1">
                    <div className="eyes1"></div>
                    <div className="nose1"></div>
                    </div>
                    <div className="body1">
                    <div className="left-paw1"></div>
                    <div className="right-paw1"></div>
                    </div>
                    <div className="tail1"></div>
                    <div className="PRlaptop">
                    <div className="PRscreen"></div>
                    <div className="PRkeyboard"></div>
                    </div>
                </div>
                <div className="doggy" style={{position: 'fixed', bottom: '-95px', right: '60px'}}>
                    <div className="ears2"></div>
                    <div className="head2">
                    <div className="eyes2"></div>
                    <div className="nose2"></div>
                    </div>
                    <div className="body2">
                    <div className="left-paw2"></div>
                    <div className="right-paw2"></div>
                    </div>
                    <div className="tail2"></div>
                    <div className="ORlaptop">
                    <div className="ORscreen"></div>
                    <div className="ORkeyboard"></div>
                    </div>
                </div>
            </div>
        )
}

export default CutePets