import TextAnim from '../../1_MediaAssets/Styles/FadeAnimation.jsx';
import Cover from '../../1_MediaAssets/MainPages/AboutUsCover.jpg';
import PawHeart from '../../1_MediaAssets/MainPages/PawHeart.png';
import About1 from '../../1_MediaAssets/MainPages/About1.jpg';
import About2 from '../../1_MediaAssets/MainPages/About2.jpg';
import CEO from '../../1_MediaAssets/MainPages/CEO.jpeg';
import Founder from '../../1_MediaAssets/MainPages/Founder.jpg';
import Footer from '../../Navbars/Footer/index.js';
import Navbar from '../../Navbars/NavbarMain/NavbarMain.js';

const About = () => { 
    return (
    <>
        <Navbar/>
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '100vw', height: '100%', paddingTop: '80px'}}>
            <div className="HeroMainDiv" style={{backgroundImage:`url('${Cover}')`, backgroundPosition: 'bottom', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundColor: '#002366', paddingTop: '30px'}}>
                <div className='HeroMainDivText2' style={{padding: '30px', borderRadius: '15px', background: 'transparent', width: '80%'}}>
                    <TextAnim Body={
                        <h2 className='reducemobFont' style={{fontWeight: '900', color: 'white'}}>Bringing joy to every <br></br> paw and heart</h2>
                    }/>
                </div>
            </div> 
            
            <div className='DogCatImgTextDiv' style={{position: 'relative'}}>
                <img src={PawHeart} className='cloud DPhider' alt='PawHeart' style={{height: '170px', width: '170px', marginLeft:'200px', position: 'absolute', top: '20px', right: '20px'}} />            
                <div className='DogCatImgHolder'>
                    <TextAnim Body={
                        <img src={About1} alt='PawHeart' className='HoverImage MaxWidthImage'/>
                    }/>
                    <TextAnim Body={
                        <img src={About2} alt='PawHeart' className='HoverImage DPhider' style={{height: '20vw', marginLeft: '-120px', width: '20vw'}}/>
                    }/>
                </div>
                <div className='DogCatTextHolder'>
                    <TextAnim Body={
                        <h3 className='fontsizee' style={{color: 'white', fontWeight: '900'}}>Building bonds that last a <span style={{color: '#0849c1'}}>lifetime,</span> one paw at a time</h3>
                    }/>
                    <TextAnim Body={
                        <p className='fontsizee' style={{color: '#ddd', marginTop: '10px'}}>At Rex Vets, we acknowledge the challenges that pet owners may face in accessing veterinary care. Our mission is to bridge this gap by providing advanced telemedicine services for pets rooted in scientific expertise. We offer regular monthly treatments and continuous support from experienced veterinarians. Our commitment stems from the belief that every pet should have the chance to enjoy a happy and healthy life.</p>
                    }/>
                </div>
            </div>

 
            <div style={{ width: '100vw', backgroundColor: '#f7f3e4',  display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', padding: '20px'}}>
                <div style={{ width: '100%', display: 'flex', padding: '20px', textAlign: 'center', flexDirection: 'column'}}>
                    <TextAnim Body={
                        <h3 style={{ width: '100%', color: 'black', fontWeight: '900', marginBottom: '30px'}}>Exploring Our Leadership</h3>
                    }/>
                    <div className='MeetTheTeam'>
                        <div className='meettheTeamDivier'>
                            <img src={CEO} alt='PawHeart' className='HoverImage' style={{height: '300px', width: '220px', borderRadius: '7px'}}/>
                            <div className='TiffDiv'>
                                <TextAnim Body={
                                    <h4 style={{fontWeight: '900', color: '#0849c1'}}>Tiffany Delacruz </h4>
                                }/>
                                <TextAnim Body={
                                    <p style={{fontSize: '12px', marginTop: '-8px', fontWeight: '900'}}>Chief Executive Officer</p>
                                }/>
                                <TextAnim Body={
                                    <p style={{fontSize: '13px', marginTop: '15px'}}>Tiffany Delacruz, the CEO of Rex Vets, is a licensed veterinarian with a profound dedication to preventive medicine, striving to enhance the well-being of pets. With extensive experience in veterinary practice, Tiffany possesses a comprehensive understanding of the concerns of pet owners and their beloved companions. Under her astute leadership, Rex Vets has emerged as a renowned entity in the veterinary realm. Tiffany's unwavering pursuit of excellence and her fervent advocacy for pets' optimal health are palpable in her every endeavor.</p>
                                }/>
                            </div>
                        </div>
                        <div className='meettheTeamDivier'>
                            <div className='JohnDiv'>
                                <TextAnim Body={
                                    <h4 style={{fontWeight: '900', color: '#0849c1'}}>Johnny Dominguez</h4>
                                 }/>
                                <TextAnim Body={
                                    <p style={{fontSize: '12px', marginTop: '-8px', fontWeight: '900'}}>Founder</p>
                                }/>
                                <TextAnim Body={
                                    <p style={{fontSize: '13px', marginTop: '15px'}}>Johnny Dominguez, the innovative force driving Rex Vets, boasts a doctorate in computer science philosophy and a profound affection for animals. Fuelled by his visionary aspirations, he envisioned a modernized approach to pet care. Johnny's unique blend of cutting-edge technology and veterinary expertise has seamlessly crafted a stress-free and accessible experience for both pets and their owners. Under his visionary leadership, Rex Vets has emerged as a trailblazing entity in the online veterinary services arena, revolutionizing traditional notions of veterinary care.</p>
                                }/>
                            </div>
                            <img src={Founder} alt='PawHeart' className='HoverImage' style={{height: '300px', width: '220px', borderRadius: '7px'}}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </>
    )
}

export default About