import { FaInstagram, FaFacebook} from 'react-icons/fa';
import { MdAlternateEmail } from "react-icons/md";
import { FaTiktok } from "react-icons/fa6";
import { FaWhatsapp } from "react-icons/fa6";
import Cover from '../../1_MediaAssets/Homepage/PawTexture.png';
import CutePets from "../../Registrations&Login/CutePets";
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const DonationSuccess = () => {
    const location = useLocation();
    const { transactionID } = location.state || {};

    return (
        <div style={{ backgroundImage: `url('${Cover}')`, backgroundAttachment: 'fixed', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundColor: '#FFE699', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100vw', minHeight: '100vh', flexDirection: 'column', gap: '10px', padding: '50px 0px' }} >
            <CutePets />
            <div className="checkout-form">
                
                <h3 style={{ margin: '10px 5px', fontWeight: '900', fontSize: '24px', textAlign: 'center', color: '#333' }}>Donation successful!</h3>
                <p style={{ margin: '10px', textAlign: 'center', color: '#555', fontSize: '13px' }}className='fontSpecial' >Transaction ID: {transactionID || 'Not available'}</p>

                <div style={{ marginBottom: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                    <p style={{ margin: '10px', textAlign: 'center', color: '#555', fontSize: '13px' }}>Thank you for your generous contribution! Your support makes a significant impact in providing essential veterinary care to pets in need.</p>
                    <p style={{ margin: '10px', textAlign: 'center', color: '#555', fontSize: '13px' }}>Every donation helps ensure that more animals receive the treatment and love they deserve. We are deeply grateful for your kindness and commitment to improving the lives of our furry friends.</p>
                    <p style={{ margin: '10px', textAlign: 'center', color: '#555', fontSize: '13px' }}>If you have any questions or need further assistance, please don’t hesitate to reach out to us.</p>
                </div>

                {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                    <p style={{ margin: '10px', textAlign: 'center', color: '#555', fontSize: '13px' }}>Consider sharing your donation on social media to encourage others to support our cause.</p>
                </div> */}

                <div style={{margin:'10px 0px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%', height: '100%', flexDirection: 'column'}}>
                    <p style={{color: '#555', padding: '0', margin: '0', textAlign: 'center', fontSize:'12px', fontWeight:'300'}}>© 2024 Rex Vets. All rights reserved.</p>
                    <div style={{display: 'flex', justifyContent:'center', alignItems: 'center', gap: '10px'}}>
                        <a target='_blank'  rel="noreferrer" href="https://www.facebook.com/rexvets" style={{textDecoration: 'none', color: '#555'}}>
                            <FaInstagram size={20} />
                        </a>
                        <a target='_blank'  rel="noreferrer" href="https://www.facebook.com/rexvets" style={{textDecoration: 'none', color: '#555'}}>
                            <FaFacebook size={21} />
                        </a>
                        <a target='_blank'  rel="noreferrer" href="https://www.tiktok.com/@rexvets" style={{textDecoration: 'none', color: '#555'}}>
                            <FaTiktok size={20} />
                        </a>
                        <a target='_blank'  rel="noreferrer" href="mailto:support@rexvets.com" style={{textDecoration: 'none', color: '#555'}}>
                            <MdAlternateEmail size={24} />
                        </a>
                    </div>
                </div>
                <Link to="/" style={{width: '100%'}}>
                    <button id='ConfirmButton' style={{ marginTop: '5px', width: '100%'}}>Return</button>
                </Link>
            </div>
        </div>
    );
};

export default DonationSuccess;
