import React from 'react'; 
import { Link } from 'react-router-dom';
// import Background from '../../1_MediaAssets/Homepage/bgwidget.png';
import BlogsData from './BlogsData'; // Ensure this import is correct

const BlogsPage = ({ blogs = BlogsData }) => {
  return (<>
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100vw', backgroundColor: 'white', flexDirection: 'column', backgroundPosition: 'bottom' }}>
    <h2 style={{ fontWeight: '900', color: '#001844', marginBottom: '-20px', marginTop: '25px' }}>Blogs</h2>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100vw', flexWrap: 'wrap', padding: '20px 0px' }}>
        {blogs.map((blog) => (
          <Link to={`/blog/${blog.slug}`} key={blog.slug} style={{ textDecoration: 'none' }}>
            <div className="blogcard">
              <img src={blog.mainImage} className="blogcard-img-top" alt={blog.title} />
              <div>
                <p className="blogcard-date">{blog.date} | {blog.by}</p>
                <h1 className="blogcard-title">{blog.title}</h1>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
    </>
  );
};

export default BlogsPage;
