import React, { useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import NavbarPetParentsDashboard from '../../../Navbars/NavbarPetParentsDashboard';
import LoadingScreen from '../../../LoadingScreen/index.js';

const PaymentsReporting = () => {
    const [loading, setLoading] = useState(true);
    const [appointmentsStats, setAppointmentsStats] = useState({
        completed: 0,
        paidOut: 0,
        pendingPayout: 0,
        platformRate: "20%"
    });
    const [earningsStats, setEarningsStats] = useState({
        grossEarnings: 0,
        platformFees: 0,
        paidOut: 0,
        pendingPayout: 0
    });
    const [appointments, setAppointments] = useState([]);
    const db = getFirestore();
    const auth = getAuth();

    useEffect(() => {
        const fetchData = async (userId) => {
            const vetDocRef = doc(db, "Doctors/Veterinarian/Veterinarian", userId);
            const vetDoc = await getDoc(vetDocRef);
        
            if (vetDoc.exists()) {
                const appointmentsArray = vetDoc.data().Appointments || [];
        
                let completed = 0, paidOut = 0, pendingPayout = 0;
                let grossEarnings = 0, platformFees = 0, paidOutTotal = 0, pendingPayoutTotal = 0;
                const fetchedAppointments = [];
        
                for (const appointmentId of appointmentsArray) {
                    const appointmentDocRef = doc(db, "Appointments", appointmentId);
                    const appointmentDoc = await getDoc(appointmentDocRef);
        
                    if (appointmentDoc.exists()) {
                        const appointmentData = appointmentDoc.data();
                        const netTotal = Number(appointmentData.AppointmentFee) || 0;
                        const platformFee = netTotal * 0.20;
                        const gross = netTotal + platformFee;
        
                        fetchedAppointments.push({
                            id: appointmentId,
                            date: appointmentData.AppointmentDate || '',
                            grossEarnings: gross.toFixed(2),
                            platformFee: platformFee.toFixed(2),
                            netTotal: netTotal.toFixed(2)
                        });
        
                        completed += 1;
                        grossEarnings += gross;
                        platformFees += platformFee;
        
                        // if (appointmentData.status === 'Paid') {
                        //     paidOut += 1;
                        //     paidOutTotal += netTotal;
                        // } else {
                        //     pendingPayout += 1;
                        //     pendingPayoutTotal += netTotal;
                        // }
                    }
                }
        
                setAppointmentsStats({ completed, paidOut, pendingPayout, platformRate: "20%" });
                setEarningsStats({
                    grossEarnings: grossEarnings.toFixed(2),
                    platformFees: platformFees.toFixed(2),
                    paidOut: paidOutTotal.toFixed(2),
                    pendingPayout: pendingPayoutTotal.toFixed(2)
                });
                setAppointments(fetchedAppointments);
            }
            setLoading(false);
        };
        
        

        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                fetchData(user.uid);
            } else {
                setLoading(false);
            }
        });

        return () => unsubscribe();
    }, [auth, db]);

    if (loading) {
        return <LoadingScreen />;
    }

    return (
        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100vw', height: '100%', background: '#f5f5f5' }}>
            <div className='dashboardmain' style={{ paddingBottom: '50px' }}>
                <NavbarPetParentsDashboard />
                <div style={{ padding: '20px', backgroundColor: '#fff', borderRadius: '10px', width: '90%', margin: '50px auto', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                    <h4 style={{ fontWeight: '900' }}>Payments & reporting</h4>
                    <p style={{ color: '#666', margin: '10px 0 30px' }}>Review your payout method, taxes, credits, and reporting.</p>
                    
                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                        {["completed", "paidOut", "pendingPayout", "platformRate"].map((stat, index) => (
                            <div key={index} style={{ flex: '1 1 22%', margin: '10px', padding: '20px', background: '#eaeaea', borderRadius: '10px', display: 'flex', alignItems: 'center' }}>
                                <div style={{ marginRight: '15px' }}>
                                    <span role="img" aria-label={stat} style={{ fontSize: '40px' }}>📊</span>
                                </div>
                                <div>
                                    <h5 style={{ margin: '0' }}>{appointmentsStats[stat]}</h5>
                                    <p style={{ margin: '0', color: '#666' }}>{stat.replace(/([A-Z])/g, ' $1').toUpperCase()}</p>
                                </div>
                            </div>
                        ))}
                    </div>

                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', marginTop: '30px' }}>
                        {["grossEarnings", "platformFees", "paidOut", "pendingPayout"].map((stat, index) => (
                            <div key={index} style={{ flex: '1 1 22%', margin: '10px', padding: '20px', background: '#eaeaea', borderRadius: '10px', display: 'flex', alignItems: 'center' }}>
                                <div style={{ marginRight: '15px' }}>
                                    <span role="img" aria-label={stat} style={{ fontSize: '40px' }}>💰</span>
                                </div>
                                <div>
                                    <h5 style={{ margin: '0' }}>{earningsStats[stat]}</h5>
                                    <p style={{ margin: '0', color: '#666' }}>{stat.replace(/([A-Z])/g, ' $1').toUpperCase()}</p>
                                </div>
                            </div>
                        ))}
                    </div>

                    <div style={{ marginTop: '50px' }}>
                        <h5 style={{ marginBottom: '20px' }}>Payout history</h5>
                        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                            <thead>
                                <tr>
                                    <th style={{ textAlign: 'left', padding: '10px', borderBottom: '2px solid #ddd' }}>Appointment</th>
                                    <th style={{ textAlign: 'left', padding: '10px', borderBottom: '2px solid #ddd' }}>Date</th>
                                    <th style={{ textAlign: 'left', padding: '10px', borderBottom: '2px solid #ddd' }}>Gross Earnings</th>
                                    <th style={{ textAlign: 'left', padding: '10px', borderBottom: '2px solid #ddd' }}>Platform Fee</th>
                                    <th style={{ textAlign: 'left', padding: '10px', borderBottom: '2px solid #ddd' }}>Net Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {appointments.map((appointment, index) => (
                                    <tr key={index}>
                                        <td style={{ padding: '10px', borderBottom: '1px solid #eee' }}>{appointment.id}</td>
                                        <td style={{ padding: '10px', borderBottom: '1px solid #eee' }}>{appointment.date}</td>
                                        <td style={{ padding: '10px', borderBottom: '1px solid #eee' }}>{appointment.grossEarnings}</td>
                                        <td style={{ padding: '10px', borderBottom: '1px solid #eee' }}>{appointment.platformFee}</td>
                                        <td style={{ padding: '10px', borderBottom: '1px solid #eee' }}>{appointment.netTotal}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PaymentsReporting;
