import { IoIosColorPalette } from "react-icons/io";
import { FaPaw, FaCat, FaDog, FaBirthdayCake, FaTransgender } from 'react-icons/fa';
import { FaWeightScale } from 'react-icons/fa6';
import NavbarVetsandTechsDashboard from '../../../Navbars/NavbarVetsandTechsDashboard/index';
import { FaUserDoctor } from "react-icons/fa6";
import { useLocation, useNavigate } from 'react-router-dom';
import { doc, getDoc, getFirestore, updateDoc, onSnapshot, collection } from "firebase/firestore";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { getAuth } from 'firebase/auth';
import { useState, useEffect, useRef } from 'react';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import '@fontsource/inter/100.css';  
import '@fontsource/inter/200.css'; 
import '@fontsource/inter/300.css';  
import '@fontsource/inter/400.css';  
import '@fontsource/inter/500.css'; 
import '@fontsource/inter/600.css'; 
import '@fontsource/inter/700.css';  
import '@fontsource/inter/800.css'; 
import '@fontsource/inter/900.css';

const AppointmentRoomPetParent = () => {
    const db = getFirestore();
    const auth = getAuth();
    const location = useLocation();
    const navigate = useNavigate();
    const messagesEndRef = useRef(null);
    const quillRef = useRef(null);
    const videoInputRef = useRef(null);
    const [videoFile, setVideoFile] = useState(null);
    const [appointmentId, setAppointmentId] = useState(location.state?.appointmentId || '');
    const [appointmentData, setAppointmentData] = useState({});
    const [petData, setPetData] = useState({});
    const [newMessage, setNewMessage] = useState("");
    const [videoUrl, setVideoUrl] = useState("");
    const [showPopup, setShowPopup] = useState(false);
    const [dataField, setDataField] = useState("");
    const [assessmentField, setAssessmentField] = useState("");
    const [planField, setPlanField] = useState("");
    const [status, setStatus] = useState(appointmentData.status || "");
    const [dataQuillRef, setDataQuillRef] = useState(null);
    const [assessmentQuillRef, setAssessmentQuillRef] = useState(null);
    const [planQuillRef, setPlanQuillRef] = useState(null);
    useEffect(() => {
        if (showPopup) {
            const dataQuillInstance = new Quill("#dataQuill", {
                theme: 'snow',
                modules: {
                    toolbar: [
                        [{ 'header': '1'}, { 'header': '2' }, { 'font': [] }],
                        [{size: []}],
                        ['bold', 'italic', 'underline', 'strike'],
                        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                        ['link', 'blockquote', 'code-block', 'image'],
                        [{ 'align': [] }],
                        ['clean']
                    ]
                }
            });
            setDataQuillRef(dataQuillInstance);
    
            const assessmentQuillInstance = new Quill("#assessmentQuill", {
                theme: 'snow',
                modules: {
                    toolbar: [
                        [{ 'header': '1'}, { 'header': '2' }, { 'font': [] }],
                        [{size: []}],
                        ['bold', 'italic', 'underline', 'strike'],
                        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                        ['link', 'blockquote', 'code-block', 'image'],
                        [{ 'align': [] }],
                        ['clean']
                    ]
                }
            });
            setAssessmentQuillRef(assessmentQuillInstance);
    
            const planQuillInstance = new Quill("#planQuill", {
                theme: 'snow',
                modules: {
                    toolbar: [
                        [{ 'header': '1'}, { 'header': '2' }, { 'font': [] }],
                        [{size: []}],
                        ['bold', 'italic', 'underline', 'strike'],
                        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                        ['link', 'blockquote', 'code-block', 'image'],
                        [{ 'align': [] }],
                        ['clean']
                    ]
                }
            });
            setPlanQuillRef(planQuillInstance);
        }
    }, [showPopup]);
    const handleSendAssessment = async () => {
        if (!dataQuillRef || !assessmentQuillRef || !planQuillRef) return;
        const dataField = dataQuillRef.root.innerHTML;
        const assessmentField = assessmentQuillRef.root.innerHTML;
        const planField = planQuillRef.root.innerHTML;
    
        if (!dataField.trim() || !assessmentField.trim() || !planField.trim()) return;
    
        const formattedMessage = `<strong>Data:</strong> ${dataField}<br/><strong>Assessment:</strong> ${assessmentField}<br/><strong>Plan:</strong> ${planField}`;
        const appointmentDocRef = doc(db, 'Appointments', appointmentId);
    
        const userId = auth.currentUser?.uid;
        if (!userId) {
            console.error('User is not authenticated');
            return;
        }
    
        const message = {
            text: formattedMessage,
            sender: "Doctor",
            timestamp: new Date().toISOString(),
            formal: true
        };
    
        try {
            const appointmentDoc = await getDoc(appointmentDocRef);
            if (appointmentDoc.exists()) {
                const existingMessages = appointmentDoc.data().Messages || [];
                await updateDoc(appointmentDocRef, {
                    Messages: [...existingMessages, message],
                    past: true 
                });
    
                setDataField("");
                setAssessmentField("");
                setPlanField("");
                setShowPopup(false);
                messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
            } else {
                console.error('No such document!');
            }
        } catch (error) {
            console.error("Error sending message:", error);
        }
    };
    const handleStatusChange = async (e) => {
        const newStatus = e.target.value;
        setStatus(newStatus);
    
        const appointmentDocRef = doc(db, 'Appointments', appointmentId);
        try {
            await updateDoc(appointmentDocRef, {
                status: newStatus
            });
            console.log('Status updated successfully');
        } catch (error) {
            console.error('Error updating status:', error);
        }
    };
    useEffect(() => {
        if (!appointmentId) return;
    
        const appointmentDocRef = doc(db, 'Appointments', appointmentId);
    
        const unsubscribe = onSnapshot(appointmentDocRef, (doc) => {
            if (doc.exists()) {
                setAppointmentData(doc.data());
                setStatus(doc.data().status || ""); // Actualiza el estado de status con el valor de la base de datos
            }
        });
    
        return () => unsubscribe();
    }, [appointmentId]);
    const getSenderName = async (userId) => {
        try {
            console.log("appointmentData:", appointmentData); 
            
           
            console.log(`Buscando usuario en la colección Technician con ID: ${userId}`);
            const technicianQuery = await getDoc(doc(db, "Doctors/Technician/Technician", userId));
            if (technicianQuery.exists()) {
                console.log("Usuario encontrado en Technician");
                return appointmentData.DoctorName; 
            }
    
           
            console.log(`Buscando usuario en la colección Veterinarian con ID: ${userId}`);
            const veterinarianQuery = await getDoc(doc(db, "Doctors/Veterinarian/Veterinarian", userId));
            if (veterinarianQuery.exists()) {
                console.log("Usuario encontrado en Veterinarian");
                console.log("appointmentData.VetName:", appointmentData.DoctorName); 
                return appointmentData.DoctorName; 
            }
    
            console.error('Usuario no encontrado en ninguna colección');
            return null;
        } catch (error) {
            console.error('Error buscando el nombre del remitente:', error);
            return null;
        }
    }; 

    const handleVideoSelect = (e) => {
        const file = e.target.files[0];
        if (file) {
            setVideoFile(file);
        }
    };
    
    // Manejar la cancelación del video
    const handleCancelVideo = () => {
        setVideoFile(null);
    };
    useEffect(() => {
        const fetchAppointmentData = async () => {
            if (!appointmentId) return;
            const appointmentDocRef = doc(db, 'Appointments', appointmentId);
            const appointmentDoc = await getDoc(appointmentDocRef);
            if (appointmentDoc.exists()) {
                setAppointmentData(appointmentDoc.data());
                console.log(appointmentDoc.data());
            } else {
                console.error('No such document!');
            }
        };

        fetchAppointmentData();
    }, [appointmentId, db]);

    const formatTimestamp = (timestamp) => {
        const date = new Date(timestamp);
        return date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric'
        }) + ', ' + date.toLocaleTimeString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        });
    };

    useEffect(() => {
        if (!appointmentId) return;

        const appointmentDocRef = doc(db, 'Appointments', appointmentId);

        const unsubscribe = onSnapshot(appointmentDocRef, (doc) => {
            if (doc.exists()) {
                setAppointmentData(doc.data());
            }
        });

        return () => unsubscribe();
    }, [appointmentId]);

    useEffect(() => {
        const fetchPetData = async () => {
            if (!appointmentData.ParentRefID || !appointmentData.PetRefID) return;

            const parentDocRef = doc(db, 'Parents', appointmentData.ParentRefID);
            const petsCollectionRef = collection(parentDocRef, 'pets');
            const petDocRef = doc(petsCollectionRef, appointmentData.PetRefID);

            const petDoc = await getDoc(petDocRef);
            if (petDoc.exists()) {
                setPetData(petDoc.data());
            } else {
                console.error('No such pet document!');
            }
        };

        fetchPetData();
    }, [appointmentData.ParentRefID, appointmentData.PetRefID, db]);

    useEffect(() => {
        const quillInstance = new Quill(quillRef.current, {
            theme: 'snow',
            modules: {
                toolbar: [
                    [{ 'header': '1'}, { 'header': '2' }, { 'font': [] }],
                    [{size: []}],
                    ['bold', 'italic', 'underline', 'strike'],
                    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                    ['link', 'blockquote', 'code-block', 'image'],
                    [{ 'align': [] }],
                    ['clean']
                ]
            }
        });

        quillInstance.getModule('toolbar').addHandler('link', () => {
            const url = prompt('Enter the URL');
            if (url) {
                const range = quillInstance.getSelection();
                quillInstance.formatText(range.index, range.length, 'link', url);
            }
        });

        quillInstance.on('text-change', () => {
            setNewMessage(quillInstance.root.innerHTML);
        });

        return () => {
            quillInstance.off('text-change');
        };
    }, []);

    const handleSendMessage = async () => {
        if (!newMessage.trim() && !videoFile) return; // Verificar que haya un mensaje o un video para enviar
    
        const appointmentDocRef = doc(db, 'Appointments', appointmentId);
    
        // Verifica si el usuario está autenticado
        const userId = auth.currentUser?.uid;
        if (!userId) {
            console.error('User is not authenticated');
            return;
        }
    
        console.log(`User ID: ${userId}`);
        
        const senderName = await getSenderName(userId);
        console.log(`Sender Name: ${senderName}`);
        
        if (!senderName) {
            console.error('User not found in any collection.');
            return;
        }
    
        // Subir el video si existe un archivo seleccionado
        let uploadedVideoUrl = null;
        if (videoFile) {
            const storage = getStorage();
            const storageRef = ref(storage, `videos/${videoFile.name}`);
            const uploadTask = uploadBytesResumable(storageRef, videoFile);
    
            try {
                await new Promise((resolve, reject) => {
                    uploadTask.on('state_changed',
                        (snapshot) => {
                            // Mostrar progreso de la subida
                            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                            console.log('Upload is ' + progress + '% done');
                        },
                        (error) => {
                            console.error('Upload failed:', error);
                            reject(error);
                        },
                        async () => {
                            // Obtener URL de descarga del video
                            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                            uploadedVideoUrl = downloadURL;
                            console.log('Video disponible en:', downloadURL);
                            resolve(downloadURL);
                        }
                    );
                });
            } catch (error) {
                console.error('Error al subir el video:', error);
                return;
            }
        }
    
        const message = {
            text: newMessage,
            videoUrl: uploadedVideoUrl || null, // Añadir la URL del video al mensaje si está disponible
            sender: senderName,
            timestamp: new Date().toISOString()
        };
    
        try {
            const appointmentDoc = await getDoc(appointmentDocRef);
            if (appointmentDoc.exists()) {
                const existingMessages = appointmentDoc.data().Messages || [];
                await updateDoc(appointmentDocRef, {
                    Messages: [...existingMessages, message]
                });
    
                setNewMessage("");
                setVideoFile(null); // Limpiar el archivo de video después de enviar
                setVideoUrl(""); // Limpiar la URL del video después de enviar
    
                if (quillRef.current) {
                    quillRef.current.querySelector('.ql-editor').innerHTML = '';
                }
    
                messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    
                console.log("Message sent successfully");
            } else {
                console.error('No such document!');
            }
        } catch (error) {
            console.error("Error sending message:", error);
        }
    };

    return (
    <div style={{ height: '100%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100vw' }}>
        <div className='dashboardmain' style={{ paddingBottom: '200px' }}>
            <NavbarVetsandTechsDashboard />

            <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'column', borderRadius: '10px', width: '90%', marginTop: '50px' }}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column', width: '100%', padding: '15px', borderBottom: '1px solid #dedcd7' }}>
                    <h4  style={{ fontWeight: '500', fontFamily:'Inter, sans-serif', fontSize:'2rem' }}>
                        <FaUserDoctor style={{ fontSize: '30px', margin: '0px 10px 0px 5px', color: '#0849c1' }} />
                        Appointment Details <span  style={{ fontWeight: '300', fontFamily:'Inter, sans-serif', fontSize:'1rem', marginRight:'20px', color:'grey' }}>ID: {appointmentId}</span>
                    </h4>
                </div>
            </div>

            <div className='AppointmentCodeMain'>
                <div className='AppointmentCodeLeft'>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', width: '100%', background: 'white', flexDirection: 'column', padding: '15px 15px 0px 15px', borderRadius: '15px' }}>
                        <h5 style={{ fontWeight: '500', fontFamily:'Inter, sans-serif', fontSize:'1.3rem' }}>Your appointment with:<br></br> <strong style={{ fontWeight: '400', fontFamily:'Inter, sans-serif', fontSize:'1.2rem'  }} >{appointmentData.ParentName}</strong></h5>
                        <a target='_blank'  className='meetingButton' href={appointmentData.MeetingLink}>
                            <p style={{fontFamily:'Inter, sans-serif'}}>Meeting Link</p>
                        </a>
                        <p style={{ margin: '0', paddingTop: '10px', fontSize: '12px', marginTop: '0px', fontWeight: '500', fontFamily:'Inter, sans-serif' }}>{appointmentData.AppointmentDate} | {appointmentData.AppointmentTime}</p>
                        
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', width: '100%', background: 'white', flexDirection: 'column', padding: '15px', marginBottom: '10px', borderRadius: '15px' }}>
    <h5 style={{ fontWeight: '500', fontFamily:'Inter, sans-serif', fontSize:'1.3rem' }}>Post-appointment:</h5>
    <p></p>
    <select value={status} onChange={handleStatusChange} style={{ padding: '10px', borderRadius: '5px', border: '1px solid #ccc', marginTop: '10px' }}>
        <option value="">Select status</option>
        <option value="Complete">Complete</option>
        <option value="Incomplete">Incomplete</option>
    </select>
</div>

                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', width: '100%', background: 'white', flexDirection: 'column', padding: '0px 20px 20px 20px', borderRadius: '15px' }}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', width: '100%', flexDirection: 'column', borderBottom: '1px solid grey', paddingBottom: '10px' }}>
                            <h5  style={{ fontWeight: '500', fontFamily:'Inter, sans-serif', fontSize:'1.3rem' }}>Patient & client information:</h5>
                            <p  style={{ fontWeight: '500', fontFamily:'Inter, sans-serif', fontSize:'1rem' }}>{petData.Name}</p>
                            <p style={{ fontWeight: '400', fontFamily:'Inter, sans-serif', fontSize:'0.9rem', marginBottom:'15px' }}>Patient</p>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                {petData.Species === 'Cat' && <FaCat style={{ marginRight: '5px', fontSize: '0,9rem', color: '#002366' }} />}
                                {petData.Species === 'Dog' && <FaDog style={{ marginRight: '5px', fontSize: '0,9rem', color: '#002366' }} />}
                                {petData.Species !== 'Cat' && petData.Species !== 'Dog' && <FaPaw style={{ marginRight: '5px', fontSize: '12px'}} />}
                                <p style={{ fontWeight: '400', fontFamily:'Inter, sans-serif', fontSize:'0.9rem', marginLeft:'5px' }}>{petData.Species}</p>
                            </div>
                            <p style={{ fontWeight: '400', fontFamily:'Inter, sans-serif', fontSize:'0.9rem' }}><FaBirthdayCake style={{ marginRight: '5px', color: '#002366' }} /> {petData.AgeYears} years {petData.AgeMonths} months old</p>
                            <p style={{ fontWeight: '400', fontFamily:'Inter, sans-serif', fontSize:'0.9rem' }}><FaBirthdayCake style={{ marginRight: '5px', color: '#002366' }} /> {petData.Dob}</p>
                            <p style={{ fontWeight: '400', fontFamily:'Inter, sans-serif', fontSize:'0.9rem' }}><FaPaw style={{ marginRight: '5px', color: '#002366' }} /> {petData.Breed}</p>
                            <p style={{ fontWeight: '400', fontFamily:'Inter, sans-serif', fontSize:'0.9rem' }}><IoIosColorPalette style={{ marginRight: '5px', color: '#002366' }} /> {petData.PetColor}</p>
                            <p style={{ fontWeight: '400', fontFamily:'Inter, sans-serif', fontSize:'0.9rem' }}><FaTransgender style={{ marginRight: '5px', color: '#002366' }} /> {petData.Gender}</p>
                            <p style={{ fontWeight: '400', fontFamily: 'Inter, sans-serif', fontSize: '0.9rem' }}>
  <FaWeightScale style={{ marginRight: '5px', color: '#002366' }} />
  {petData.WeightUnit === "kg"
    ? `${petData.Weight} kg (${(petData.Weight * 2.2).toFixed(2)} lb)`
    : petData.WeightUnit === "lbs"
      ? `${petData.Weight} lb (${(petData.Weight / 2.2).toFixed(2)} kg)`
      : `${petData.Weight} ${petData.WeightUnit}`
  }
</p>
                            <p style={{ fontWeight: '400', fontFamily:'Inter, sans-serif', fontSize:'0.9rem', marginTop:'15px' }}><strong style={{ fontWeight: '600', fontFamily:'Inter, sans-serif', fontSize:'0.9rem' }}>Spayed:</strong> {petData.Sprayed ? 'Yes' : 'No'}</p>
                            <p style={{ fontWeight: '400', fontFamily:'Inter, sans-serif', fontSize:'0.9rem' }}><strong style={{ fontWeight: '600', fontFamily:'Inter, sans-serif', fontSize:'0.9rem' }} >Concerns:</strong> {appointmentData.PetConcerns?.join(', ')}</p>
                            <p style={{ fontWeight: '400', fontFamily:'Inter, sans-serif', fontSize:'0.9rem' }}><strong style={{ fontWeight: '600', fontFamily:'Inter, sans-serif', fontSize:'0.9rem' }} >More Info:</strong> {appointmentData.MoreDetails}</p>
                            <p style={{ fontWeight: '400', fontFamily:'Inter, sans-serif', fontSize:'0.9rem' }}><strong style={{ fontWeight: '600', fontFamily:'Inter, sans-serif', fontSize:'0.9rem' }} >State:</strong>  {appointmentData.State}</p>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', width: '100%', flexDirection: 'column', marginTop: '8px' }}>
                            <p style={{ fontWeight: '600', fontFamily:'Inter, sans-serif', fontSize:'1rem' }}>{appointmentData.ParentName}</p>
                            <p  style={{ fontWeight: '500', fontFamily:'Inter, sans-serif', fontSize:'0.9rem' }}>Client</p>
                            <p  style={{ fontWeight: '400', fontFamily:'Inter, sans-serif', fontSize:'0.8rem' }}>{appointmentData.ParentEmail}</p>
                        </div>
                    </div>
                </div>

                <div className='AppointmentCodeRight'>
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', padding: '10px', background: '#fbfbfb', borderRadius: '10px' }}>
        <h5 style={{ fontWeight: '500', fontFamily:'Inter, sans-serif' }}>Messages</h5>
        <div style={{ flex: 1, overflowY: 'scroll', padding: '10px', background: '#fff', borderRadius: '10px', maxHeight: '300px' }}>
       
        {appointmentData.Messages?.map((msg, index) => {
    const isDoctor = msg.sender.startsWith("Dr.");
    const isDataAssessmentPlan = msg.formal;

    return (
        <div
        key={index}
        style={{
            background: isDataAssessmentPlan
                ? '#e6ffe6'
                : isDoctor
                ? '#e6f7ff' // Fondo diferenciado para mensajes del doctor no formales
                : '#ffecd1', // Color de fondo diferente para los mensajes del cliente
            borderRadius: '0px 10px 10px 10px',
            padding: '20px',
            marginBottom: '15px',
            border: isDataAssessmentPlan
                ? '1px solid #b3ffb3'
                : isDoctor
                ? '1px solid #a3daff' // Borde diferenciado para mensajes del doctor
                : '1px solid #ffc966', // Borde diferenciado para los mensajes del cliente
            color: isDataAssessmentPlan || isDoctor ? '#333' : '#333', // Color de texto para el cliente
            alignSelf: isDoctor ? 'flex-start' : 'flex-end', // Mensajes del doctor a la izquierda, cliente a la derecha
            width: 'fit-content',
            maxWidth: '70%'
        }}
    >
    {isDataAssessmentPlan ? (
        <div dangerouslySetInnerHTML={{ __html: msg.text }} />
    ) : (
        <>
            <strong style={{ fontWeight: '500', fontFamily: 'Inter, sans-serif', fontSize: '13px', marginBottom: '15px' }}>
                {msg.sender}
            </strong>
            <p style={{ fontWeight: '400', fontFamily: 'Inter, sans-serif', fontSize: '10px', color: isDoctor ? '#555' : '#555', marginTop: '-3px' }}>
                {formatTimestamp(msg.timestamp)}
            </p>
            <div dangerouslySetInnerHTML={{ __html: msg.text }} />
            {msg.videoUrl && (
                <video controls style={{ width: '100%', marginTop: '10px' }}>
                    <source src={msg.videoUrl} type="video/mp4" />
                    Tu navegador no soporta el elemento de video.
                </video>
            )}
        </>
    )}
    </div>
    );
})}

            <div ref={messagesEndRef} />
        </div>
        
        {/* Input para subir el video */}
        <input
    type="file"
    accept="video/*"
    ref={videoInputRef}
    onChange={handleVideoSelect}
    style={{ margin: '10px 0', padding: '10px', borderRadius: '5px', border: '1px solid #ccc' }}
/>

{videoFile && (
    <div style={{ marginBottom: '10px' }}>
        <p style={{ fontWeight: '500' }}>Select Video: {videoFile.name}</p>
        <button onClick={handleCancelVideo} style={{ padding: '5px 10px', borderRadius: '5px', border: 'none', backgroundColor: '#c40000', color: 'white', cursor: 'pointer' }}>
            Cancel Video
        </button>
    </div>
)}
        
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            {/* Editor de mensajes Quill */}
            <div ref={quillRef} style={{ height: '150px', marginBottom: '10px', background: 'white' }} />
            
            {/* Botón para enviar el mensaje o video */}
            <button
                onClick={handleSendMessage}
                style={{
                    padding: '10px 20px',
                    borderRadius: '5px',
                    border: 'none',
                    backgroundColor: '#0849c1',
                    color: 'white',
                    fontWeight: 'bold',
                    cursor: 'pointer',
                    marginTop: '10px'
                }}
            >
                Send
            </button>
        </div>
        <button
    onClick={() => setShowPopup(true)}
    style={{
        padding: '10px 20px',
        borderRadius: '5px',
        border: 'none',
        backgroundColor: '#0849c1',
        color: 'white',
        fontWeight: 'bold',
        cursor: 'pointer',
        marginTop: '10px'
    }}
>
    Data, Assessment, Plan
</button>

{showPopup && (
    <div style={{
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}}>
    <div style={{
        background: 'white',
        width: '90%',
        maxWidth: '800px', // Limita el ancho máximo
        height: 'auto',
        maxHeight: '90vh', // Limita la altura máxima para evitar que se corte
        padding: '20px',
        borderRadius: '10px',
        overflowY: 'auto', // Permite scroll si el contenido sobrepasa la altura
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        boxSizing: 'border-box'
    }}>
        <button
            onClick={() => setShowPopup(false)}
            style={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                background: 'transparent',
                border: 'none',
                fontSize: '1.5rem',
                cursor: 'pointer',
                color: '#0849c1',
            }}
        >
            &times;
        </button>
        <h4>Data, Assessment, Plan</h4>
        <p>Data:</p>
        <div id="dataQuill" style={{ height: '150px', marginBottom: '10px', background: 'white', width: '100%' }} />
        <p>Assessment:</p>
        <div id="assessmentQuill" style={{ height: '150px', marginBottom: '10px', background: 'white', width: '100%' }} />
        <p>Plan:</p>
        <div id="planQuill" style={{ height: '150px', marginBottom: '10px', background: 'white', width: '100%' }} />
        <button
            onClick={handleSendAssessment}
            style={{
                padding: '10px 20px',
                borderRadius: '5px',
                border: 'none',
                backgroundColor: '#0849c1',
                color: 'white',
                fontWeight: 'bold',
                cursor: 'pointer',
                alignSelf: 'center', // Centra el botón en pantallas pequeñas
                marginTop: '10px' // Añade un margen superior para espacio extra
            }}
        >
            Send
        </button>
    </div>
</div>

)}

    </div>
</div>
            </div>
        </div>
    </div>
);

};

export default AppointmentRoomPetParent;