import React from 'react';
import Priv from './Banner.jpg';
import Footer from '../../../Navbars/Footer';
import Navbar from '../../../Navbars/NavbarMain/NavbarMain';

const PrivacyPolicy = () => {
  return(
    <>
    <Navbar />
    <div className="tc-content-container">
      <section className="tc-hero" style={{ paddingTop: '50px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', backgroundImage: `url(${Priv})`, backgroundAttachment: 'fixed', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', minHeight: '70vh', width: '100vw' }}>
        <h1>Privacy Policy</h1>
      </section>
      <div className="tc-desc-section">
        <p>Welcome! Rex Vets ('we,' 'our,' or 'us') collects certain types of personal information when you use our website, online services, and mobile app (together, the "Site" and the "Services"). This privacy policy explains those types of information. We care about your privacy, so we do everything we can to avoid sharing or leaking your personal information. Just the information we need to give you useful online services is what we try to collect. Contact us at <a href="mailto:support@rexvets.com">support@rexvets.com</a> if you need help or have any questions.</p>
      
        <h3>For Pet Parents</h3>
        <p>This part, "For Pet Parents," is an easy-to-read explanation of our Privacy Policy. No, it's not a replacement, and you shouldn't think of it as the whole policy. Remember that by clicking "I agree," you're agreeing to our whole Privacy Policy, not just the "For Pet Parents" section.
          <br/><br/>Any time you access our website or mobile application, or log into your account, you authorize us to collect information in accordance with this policy. You are granting your consent to its use.
          <br/><br/>We collect personal information, including your name, email address, IP address, password, and any other information you provide, when you visit and use the Services.
          <br/><br/>We make every effort to safeguard this information and ensure that it is solely utilized to provide you with the Services.
          <br/><br/>We collaborate with third-party service providers, such as Square Payments and Facebook, to simplify the user experience of Rex Vets. By employing a third-party service through Rex Vets, you are also agreeing to the privacy policies of that service. Therefore, it is imperative that you review those policies as well.
          <br/><br/>Cookies are identifiers that we may add to your computing device in order to recognize you when you access the Services. You are not obligated to accept any cookies; however, you may wish to do so in order to optimize your use of the Service.
          <br/><br/>We refrain from selling, renting, or trading any information you provide us or disclosing it to any third parties, with the exception of the following: When legally required; or
          <br/><br/>To ensure the safety of our users.
          <br/><br/>We will take all reasonable measures to safeguard your information; however, it is your responsibility as a user to ensure that your username and password are not compromised.
          <br/><br/>It is crucial to review this policy frequently, as we may modify certain components from time to time. We will make every effort to inform you of any substantial modifications.
        </p>

        <h3>Information We Collect and Why</h3>
        <p>We collect, retain, and process a variety of information when you visit or use Rex Vets in order to verify your identity and provide you with high-quality services.</p>
      
        <h3>As a Visitor</h3>
        <p>Device, browser, and IP address information and server log information (date, time, and pages visited) may be collected anonymously from Services visitors. Our mobile device and internet connection data may include the device's unique device identifier (e.g., Android Advertising Identifier or Advertising Identifier for iOS), MAC address, IP address, operating system, web browser type, mobile network information, and telephone number. Rex Vets uses this data to understand visitors and improve your experience.</p>
      
        <h3>As a User</h3>
        <p>Device, browser, and IP address information and server log information (date, time, and pages visited) may be collected anonymously from Services visitors. Our mobile device and internet connection data may include the device's unique device identifier (e.g., Android Advertising Identifier or Advertising Identifier for iOS), MAC address, IP address, operating system, web browser type, mobile network information, and telephone number. Rex Vets uses this data to understand visitors and improve your experience.</p>

        <h3>Third-Party Services</h3>
        <p>We use third-party services to maintain Rex Vets. You authorize us to acquire your name, email address, and other information from these Third-Party Services if you authorize us to connect with them. The Terms of Service and Privacy Policies of these Third-Party Services govern your use. Only this Privacy Policy shall govern our use and disclosure of such information.</p>
      
        <h3>Cookies and Other Tracking Technologies</h3>
        <p>We may set and access cookies on your computer or mobile device when you use Rex Vets. User ID, user preferences, lists of pages visited, and Services actions are stored in "cookies" on your computer. We also use cookies to track site traffic and improve the online experience. Some cookies may be active while you browse a website (“session cookies”). Some cookies remain on your computer after you stop your browser or turn it off (“persistent cookies”). We may use tracking cookies on other websites to offer relevant adverts.
          <br/><br/>You do not have to accept cookies, but if they are disabled, you may not be able to log in or use some Rex Vets features. You can accept all cookies, reject all cookies, or receive cookie notifications in your browser. See www.allaboutcookies.org to disable browser cookies or opt out of cookies.
          <br/><br/>On the Site, in emails, and on third-party platforms like Facebook, we may employ pixels. A pixel in marketing emails may tell us when you click a link. These tools run and optimize our Site and marketing emails.
          <br/><br/>Third-party cookies are not covered by this privacy policy, and we have no control over their privacy practices or policies. Be aware that even after you leave our site, third-party cookies may still be tracking your online activity.
        </p>

        <h3>Analytics</h3>
        <p>We also collect Site usage data using Firebase and Google Analytics. These programs track how often you visit the Site, what pages you visit, and what other websites you visited before it. We solely utilize these services' data to improve our Site and Services. These analytic services only gather your IP address on the date you visit the Site, not your name or other identifying information. We do not combine Service data with personally identifiable information. Google Analytics leaves a permanent cookie on your computer to identify you as a unique user next time you come, but only Google can use it. The Google Analytics Terms of Use and Google Privacy Policy limit Google's use and sharing of Site usage data. Disabling browser cookies prevents Google Analytics from recognizing you on future visits. Install Google Analytics' Opt-out Browser.</p>

        <h3>How We Use Your Personal Information</h3>
        <p>Your personal information is mostly needed to create a Rex Vets user account and use the Services. Your personal information is also used:
          <br/><br/>Fulfill requests for information and respond to correspondence; - Manage and expand services, perform analysis, and support business growth for Rex Vets and other offerings.
          <br/><br/>Provide information about Rex Vets and our Services; - Contact you when necessary or requested; - Customize your Service experience; - Send marketing or promotional emails based on your preferences; or - Prevent, detect, and investigate security breaches and illegal activities.
          <br/><br/>To perform statistical studies, we may anonymize and aggregate some of your personal information and show it publicly.
        </p>


      </div>
      <Footer />
    </div>
    </>
  );
};
export default PrivacyPolicy;