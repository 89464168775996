import LoadingScreen from '../../../LoadingScreen/index.js';
import NavbarVetsandTechsDashboard from '../../../Navbars/NavbarVetsandTechsDashboard/index.js';
import { LuLayoutDashboard } from "react-icons/lu";
import HeroImage from '../../../1_MediaAssets/VetsandTechs/VetIllustration.png';
import { useEffect, useState } from 'react';
import { collection, getDocs, query, where, getFirestore } from "firebase/firestore";
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from "firebase/auth";

const DashboardVetsandTechs = () => {
    const navigate = useNavigate();
    const [technicianData, setTechnicianData] = useState(null);
    const [veterinarianData, setVeterinarianData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const auth = getAuth();
        const db = getFirestore();

        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (!user) {
                navigate('/LoginDoctors');
                return;
            }

            const userId = user.uid;

            try {
                const technicianQuery = query(collection(db, "Doctors/Technician/Technician"), where("uid", "==", userId));
                const technicianSnap = await getDocs(technicianQuery);

                if (!technicianSnap.empty) {
                    technicianSnap.forEach((doc) => {
                        setTechnicianData(doc.data());
                    });
                } else {
                    const veterinarianQuery = query(collection(db, "Doctors/Veterinarian/Veterinarian"), where("uid", "==", userId));
                    const veterinarianSnap = await getDocs(veterinarianQuery);

                    if (!veterinarianSnap.empty) {
                        veterinarianSnap.forEach((doc) => {
                            setVeterinarianData(doc.data());
                        });
                    } else {
                        console.log('No such document!');
                    }
                }
            } catch (error) {
                console.error("Error fetching user data: ", error);
            } finally {
                setLoading(false);
            }
        });

        return () => unsubscribe();
    }, [navigate]);

    const userData = technicianData || veterinarianData;

    if (loading) {
        return <LoadingScreen />;
    }

    return (
        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100vw', height: '100vh', backgroundColor:'black'}}>
            <div className='dashboardmain'>
                <NavbarVetsandTechsDashboard />

                <div className='dashboardVetsResponsive' style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'column', background: '#fff', borderRadius: '10px', width: '74%', marginTop: '50px', boxShadow: '0 1px 1px 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.19)', marginTop:'160px' }}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column', width: '100%', padding: '15px', borderBottom: '1px solid #dedcd7' }}>
                        <h4 style={{ padding: '0', margin: '0', display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: '900' }}>
                            <LuLayoutDashboard style={{ fontSize: '30px', margin: '0px 10px 0px 5px', color: '#0849c1' }} />
                            Dashboard
                        </h4>
                    </div>

                    <div className='divierwelcome'>
                        <div className='divierwelcometext'>
                            <h4 style={{ fontWeight: '900', marginBottom: '20px' }}>
                                Welcome, Dr. {userData ? userData.FirstName : 'User'}
                            </h4>
                            <p style={{ fontSize: '14px', marginBottom: '10px' }}>
                                Welcome to Rex Vets, the forefront of cutting-edge veterinary telemedicine. By becoming a member, you're not just accessing a service; you're joining a dynamic movement dedicated to democratizing pet care.
                            </p>
                            <p style={{ fontSize: '14px' }}>
                                Our mission is to break down barriers and ensure that every pet receives the attention they deserve, regardless of geographical constraints or time limitations. Together, we're reshaping the landscape of veterinary medicine, making it more accessible and convenient for pet owners everywhere.
                            </p>
                        </div>

                        <div className='divierwelcomeimage'>
                            <img src={HeroImage} alt='HeroImage' style={{ width: '250px' }} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DashboardVetsandTechs;
