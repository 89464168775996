import React, { useEffect } from 'react';

const TawkToScript = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://embed.tawk.to/66b2449e1601a2195ba16d74/1i4k5o34n';
    script.charset = 'UTF-8';
    script.setAttribute('crossorigin', '*');
    script.async = true;
    document.body.appendChild(script);
  }, []);

  return null;
};

export default TawkToScript;
