import { ZegoUIKitPrebuilt } from '@zegocloud/zego-uikit-prebuilt';
import { useParams } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';

const VideoCallVetsandParents = () => {
    const { roomId } = useParams();
    const [isRecording, setIsRecording] = useState(false);
    const zegoContainerRef = useRef(null); // Create a ref for the Zego container

    function randomID(len) {
        let result = '';
        const chars = '12345qwertyuiopasdfgh67890jklmnbvcxzMNBVCZXASDQWERTYHGFUIOLKJP';
        const maxPos = chars.length;
        for (let i = 0; i < len; i++) {
            result += chars.charAt(Math.floor(Math.random() * maxPos));
        }
        return result;
    }

    async function initZego() {
        const appID = 1517600420;
        const serverSecret = 'ac6e3ca9be18c7ce1285eda0bac1908c';
        const kitToken = ZegoUIKitPrebuilt.generateKitTokenForTest(appID, serverSecret, roomId, randomID(5), randomID(5));

        const zp = ZegoUIKitPrebuilt.create(kitToken);

        zp.joinRoom({
            container: zegoContainerRef.current, // Use the ref for the container
            sharedLinks: [
                {
                    name: 'Personal link',
                    url: `${window.location.protocol}//${window.location.host}${window.location.pathname}?roomID=${roomId}`,
                },
            ],
            scenario: {
                mode: ZegoUIKitPrebuilt.VideoConference,
            },
        });

        // Optionally trigger recording
        await startRecording(roomId); // Add this function to trigger recording
    }

    async function startRecording(roomId) {
        console.log('Sending roomId to server:', roomId); // Log roomId before sending
        try {
            const response = await fetch('https://rexvetsvideocallserver.vercel.app/start-recording', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ roomId }),
            });

            if (response.ok) {
                setIsRecording(true);
            } else {
                const errorText = await response.text();
                console.error('Failed to start recording:', errorText);
            }
        } catch (error) {
            console.error('Error starting recording:', error);
        }
    }

    // Use useEffect to ensure initZego runs only once when the component mounts
    useEffect(() => {
        if (zegoContainerRef.current) {
            initZego();
        }
    }, [roomId]);

    return (
        <div className='dashboardvideocall'>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', background: '#fff', borderRadius: '10px', height: '100%', minHeight: '100vh', width: '100%', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)' }}>
                <div ref={zegoContainerRef} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100vw', minHeight: '100vh' }}>
                </div>
                {isRecording && <p style={{background: 'red', color: 'white', fontWeight: '900', width: "100vw", padding: '10px 20px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>Recording in progress...</p>}
            </div>
        </div>
    );
}

export default VideoCallVetsandParents;
