import NavbarVetsandTechsDashboard from '../../../Navbars/NavbarVetsandTechsDashboard/index.js'
import { FaBell } from "react-icons/fa";
import { useEffect, useState } from 'react';
// eslint-disable-next-line
import { doc, getDoc, query, where, collection, getFirestore, addDoc, getDocs, updateDoc } from "firebase/firestore";
// eslint-disable-next-line
import { getAuth } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import PetParentDP from '../../../1_MediaAssets/OtherBasics/petparent.png'; 
import CatDP from '../../../1_MediaAssets/OtherBasics/cat.jpg'; 
// eslint-disable-next-line
import { MdArrowRightAlt } from "react-icons/md";
// eslint-disable-next-line
import DogDP from '../../../1_MediaAssets/OtherBasics/dog.jpg'; 
const NotificationsVetsandTechs = () => { 

    const [parent, setParent] = useState([]);
    const [allParents, setAllParents] = useState([]);
    const db = getFirestore();

    const navigate = useNavigate();
    useEffect(() => {
        async function fetchAllBookingUsers() {
            const allBookingUsers = [];
    
            for (const parentItem of parent) { // Rename the variable in the loop
                const bookingUserDocSnap = await getDoc(parentItem.bookingUser);
                // eslint-disable-next-line
                if (bookingUserDocSnap.exists()) {
                    allBookingUsers.push(bookingUserDocSnap.data());
                } else {
                    console.log("No such booking user document!");
                }
            }
    
            console.log("All booking users:", allBookingUsers);
            setAllParents(allBookingUsers);
        }
    
        fetchAllBookingUsers();
    }, [parent]);

    function navigateParent(parent){
        navigate('/AppointmentsVetsandTechs/AppointmentCode',{state: {parent: parent}})
    }


    useEffect(()=>{

        // const auth = getAuth();
        // const userId = auth.currentUser.uid;
        // console.log(userId);

        const userId = localStorage.getItem('userId');
          console.log(userId);

        //   eslint-disable-next-line
        const q1 = query(collection(db, "Doctors/Technician/Technician/"), where("uid", "==", userId));
        // eslint-disable-next-line
        const q2 = query(collection(db, "Doctors/Veterinarian/Veterinarian"), where("uid", "==", userId));
        // eslint-disable-next-line 

        async function dataSet(){
            const parentCollectionRef = collection(db, 'Doctors', 'Veterinarian', 'Veterinarian');
            const q1 = query(parentCollectionRef, where("uid", "==", userId));
        
            const querySnapshot = await getDocs(q1);
            const parentDoc = querySnapshot.docs[0]; // Get the first document that matches the query
        
            if (parentDoc) {
                const parentBookingsRef = collection(doc(parentCollectionRef, parentDoc.id), 'parentBookings');
                const q2 = query(parentBookingsRef);
                const parentBookingsSnapshot = await getDocs(q2);
        
                const parentBookings = parentBookingsSnapshot.docs.map(doc => doc.data());
        
                console.log('Parent bookings:', parentBookings);
                setParent(parentBookings);
            } else {
                const parentCollectionRef2 = query(collection(db, "Doctors/Veterinarian/Veterinarian"))
                const q3 = query(parentCollectionRef2, where("uid", "==", userId));
                const querySnapshot2 = await getDocs(q3);
                const parentDoc2 = querySnapshot2.docs[0]; // Get the first document that matches the query
                if (parentDoc2) {
                    const parentBookingsRef2 = collection(doc(parentCollectionRef2, parentDoc2.id), 'parentBookings');
                    const q4 = query(parentBookingsRef2);
                    const parentBookingsSnapshot = await getDocs(q4);
            
                    const parentBookings2 = parentBookingsSnapshot.docs.map(doc => doc.data());
            
                    console.log('Parent bookings:', parentBookings2);
                    setParent(parentBookings2);
                } else {

                    console.log('No such document!');

            }

         }
        }  
    
        dataSet();
        // eslint-disable-next-line
    },[])

    return (
            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100vw', height: '100vh' }}>
                <div className='dashboardmain'>
                    <NavbarVetsandTechsDashboard />

                    <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'column', background: '#fff', borderRadius: '10px', width: '90%', marginTop: '50px', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}}>
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column', width: '100%', padding: '15px', borderBottom: '1px solid #dedcd7' }}>
                            <h4 style={{padding: '0', margin: '0', display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: '900'}}>
                                <FaBell style={{fontSize: '30px', margin: '0px 10px 0px 5px', color: '#0849c1'}}/>
                                Notifications
                            </h4>
                        </div>

                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', flexDirection: 'column'}}>
                        <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', width: '100%', background: '#fff'}}>
                            


                           
                            
                            
 
                        </div>

                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', flexDirection: 'column'}}>
                                {allParents.map((parent, index) => (
                                    // <NavLink key={index} to="/AppointmentsVetsandTechs/AppointmentCode" style={{width: '100%', textDecoration: 'none'}}>
                                        <div className='PatientDiv' onClick={()=>{navigateParent(parent)}}>
                                            <div className='SubPatientDiv'>
                                                <img className='DPhider' src={PetParentDP} alt='PetParentDP' style={{height: '40px', width: '40px', borderRadius: '100px', marginLeft: '2vw'}}/>
                                                <p style={{fontSize: '13px'}}>{parent.Name}</p>
                                                <img className='DPhider' src={CatDP} alt='CatDP' style={{height: '40px', width: '40px', borderRadius: '100px', marginLeft: '2vw'}}/>
                                                <p style={{fontSize: '13px'}}>New Booking Request.</p> 
                                            </div>
                                            <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
                                                <p style={{fontSize: '13px', marginLeft: '2vw'}}>Jan 21, 2023<br></br> <span style={{fontSize: '12px', color: 'grey'}}>04:30 PM - 05:30 PM</span></p>
                                            </div>
                                            {/* <MdArrowRightAlt style={{fontSize: '80px', color: '#8CC084', marginRight: '2vw'}} /> */}
                                        </div>
                                    // </NavLink>
                                ))}
                            </div>
                        


                        
                    </div>

                    </div>
                </div>
            </div>
        )
}

export default NotificationsVetsandTechs 