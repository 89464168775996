import React, { useState, useEffect } from 'react';
import { getAuth, sendEmailVerification, onAuthStateChanged } from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { useNavigate, useLocation } from 'react-router-dom';
import Cover from '../../../1_MediaAssets/VetsandTechs/RegistrationVetsandTechs/Cover2.png'; 
import Texture from '../../../1_MediaAssets/OtherBasics/Texture.png';
import CutePets from "../../CutePets.js";

const EmailVerificationPage = () => {
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    // eslint-disable-next-line
    const [isVerified, setIsVerified] = useState(false);
    const [cooldown, setCooldown] = useState(0);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const userId = location.state?.userId;

    useEffect(() => {
        const auth = getAuth();
        const db = getFirestore();

        const fetchEmail = async () => {
            if (!userId) {
                setError('No user ID found.');
                return;
            }

            try {
                const userDoc = doc(db, 'Parents', userId);
                const userSnap = await getDoc(userDoc);

                if (userSnap.exists()) {
                    const userData = userSnap.data();
                    setEmail(userData.EmailAddress);
                } else {
                    setError('User not found.');
                }
            } catch (error) {
                setError('Failed to fetch user data: ' + error.message);
            }
        };

        fetchEmail();

        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setIsVerified(user.emailVerified);
                if (user.emailVerified) {
                    navigate('/DashboardPetParents', { state: { userId } });
                }
            }
        });

        return () => unsubscribe();
    }, [userId, navigate]);

    useEffect(() => {
        let interval;
        if (cooldown > 0) {
            interval = setInterval(() => {
                setCooldown(prevCooldown => {
                    if (prevCooldown <= 1) {
                        clearInterval(interval);
                        setIsButtonDisabled(false);
                        return 0;
                    }
                    return prevCooldown - 1;
                });
            }, 1000);
        }

        return () => clearInterval(interval);
    }, [cooldown]);

    const handleVerify = async () => {
        const auth = getAuth();

        try {
            const actionCodeSettings = {
                url: 'https://rexvets.com/DashboardPetParents',
                handleCodeInApp: true,
            };

            const user = auth.currentUser;
            if (!user) {
                setError('No user is currently logged in.');
                return;
            }

            await sendEmailVerification(user, actionCodeSettings);

            setSuccessMessage('Verification email sent. Please check your inbox.');
            setCooldown(60); // Set cooldown for 60 seconds
            setIsButtonDisabled(true); // Disable button
        } catch (error) {
            setError('Failed to send verification email: ' + error.message);
        }
    };

    return (
        <div style={{minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden', backgroundImage:`url('${Cover}')`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundAttachment: 'fixed'}}>
            <div className='RegistrationWhiteBox' style={{backgroundImage:`url('${Texture}')`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', marginTop: '30px', marginBottom: '30px'}}>

                <div style={{width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', padding: '30px 30px 0px 30px', flexDirection: 'column'}}>
                    <h2 style={{fontWeight: '900', color: '#002366', fontSize: '30px'}}>Verify Your Email</h2>
                    <h5 style={{fontWeight: '900', marginTop: '-8px', color: '#686869'}}>Please verify your email address</h5>
                    <p style={{fontWeight: '900', marginTop: '0px', fontSize: '12px', color: 'red'}}>
                        Note: Check your spam folder in case you do not find the verification email in your inbox. 
                    <br/>
                        Do not hesitate to contact us on support@rexvets.com in case you still face difficulties.</p>
                    <CutePets />
                </div>

                <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column'}}>
                    <div className='optionBox' style={{margin: '0', height: '100%', justifyContent: 'flex-start', alignItems: 'flex-start', flexDirection: 'column', padding: '0px 30px 0px 30px',}}> 
                        <div style={{display: 'flex', flexDirection: 'column', width: '100%', marginTop: '10px'}}>
                            <label style={{fontWeight: '900', fontSize: '13px'}}>Email</label>
                            <input type="text" value={email} readOnly style={{width:"100%", height:'35px', borderRadius: '5px', padding: '8px', border: '1px solid grey', fontSize: '14px'}} />
                        </div>
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '100%', marginTop: '15px', height: '13px'}}>
                            <label style={{fontSize: '10px', color: 'red', textAlign: 'center', width: '100%'}}>{error}</label>
                            <label style={{fontSize: '10px', color: 'green', textAlign: 'center', width: '100%'}}>{successMessage}</label>
                        </div>
                        <div className='ButtonofWhiteBox' style={{margin: '10px 0px 20px 0px', padding:'0'}}>
                            <button id='verifybutton' style={{width: '100%', backgroundColor: isButtonDisabled ? 'grey' : '#002366', color: 'white', cursor: isButtonDisabled ? 'not-allowed' : 'pointer'}} onClick={handleVerify} disabled={isButtonDisabled}>
                                {isButtonDisabled ? `Please wait ${cooldown}s` : 'Verify Email'}
                            </button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default EmailVerificationPage;
