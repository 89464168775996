import React from 'react';
import { useParams } from 'react-router-dom';
import blogs from './BlogsData';

import Background from '../../1_MediaAssets/MainPages/Blogs/BlogsCover.png';
import Footer from '../../Navbars/Footer';
import Navbar from '../../Navbars/NavbarMain/NavbarMain';

const BlogDetails = () => {
  const { slug } = useParams();
  const blog = blogs.find(blog => blog.slug === slug);

  if (!blog) {
    return <div>Blog not found</div>;
  }

  return (
    <>
    <Navbar /> 
    <div className="blog-details-container">
      
      <section className="hero-sectionw" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', backgroundImage: `url(${blog.mainImage})`, backgroundAttachment: 'fixed', backgroundSize: 'cover', backgroundPosition: 'center'}}>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', background: 'rgba(255,255,255,0.5)', padding: '20px 10px', borderRadius: '10px'}}>
          <h1 className="hero-titlew">{blog.title} </h1> 
          <p style={{fontSize: '16px', color: '#232024', marginTop: '-7px'}}>{blog.date} | {blog.by}</p> 
        </div>
      </section>

      <div style={{ display: 'flex', width: '100vw', justifyContent: 'center', alignItems: 'center', backgroundImage: `url(${Background})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', background: '#fff', backgroundAttachment: 'fixed', padding: '40px 0px' }}>
        <div className="content-container">
          {blog.description.map((desc, index) => ( 
            <div key={index} className={`content-section ${desc.image ? (index % 2 === 0 ? 'left' : 'right') : 'full-width'}`}>
              {desc.image && <img src={desc.image} alt={blog.title} className="section-image" />}
              <div className={`text-container ${desc.image ? (index % 2 === 0 ? 'text-left' : 'text-right') : 'text-full-width'}`}>
                {desc.titleBig && <h4 className="section-title-big fontSpecial">{desc.titleBig}</h4>}
                {desc.title && <h1 className="section-title">{desc.title}</h1>}
                {desc.paragraph && (
                  <p
                    className="section-paragraph"
                    dangerouslySetInnerHTML={{ __html: desc.paragraph.replace(/\[BR\]/g, '<br/>') }}
                  ></p>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
    </>
  );
};

export default BlogDetails;
