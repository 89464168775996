import React from "react";
import TC from './Banner.jpg';
import Footer from '../../../Navbars/Footer';
import Navbar from '../../../Navbars/NavbarMain/NavbarMain';

const TermsC = () => {
  return (
    <>
      <Navbar />
      <div className="tc-content-container">
        <section className="tc-hero" style={{ paddingTop: '50px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', backgroundImage: `url(${TC})`, backgroundAttachment: 'fixed', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', minHeight: '70vh', width: '100vw' }}>
          <h1>Terms And Conditions</h1>
        </section>
        <div className="tc-desc-section">
          <p>Welcome to Rex Vets! These terms and conditions outline the rules and regulations for the use of our website, <a href="https://rexvets.com/">www.rexvets.com</a>. By accessing this website, you agree to comply with these terms and conditions. If you do not agree with any part of these terms, please do not continue to use Rex Vets.</p>
          
          <h3>Introduction</h3>
          <p>This website is operated by Rex Vets. Throughout the site, the terms "we," "us," and "our" refer to Rex Vets. By visiting our site and/or purchasing something from us, you engage in our "Service" and agree to be bound by the following terms and conditions, including those additional terms and conditions and policies referenced herein.</p>
          
          <h3>Use of Cookies</h3>
          <p>Our website uses cookies to optimize your browsing experience. When you use Rex Vets, you agree to our use of cookies as outlined in our Privacy Policy. We use cookies to enhance your browsing experience and personalize our website to better suit your needs.</p>
          
          <h3>Intellectual Property Rights</h3>
          <p>All material on our website is owned by Rex Vets and/or its licensors, unless otherwise stated. All rights to the intellectual property are protected. Feel free to access this material for your personal use, but please keep in mind that there are certain restrictions outlined in the terms and conditions.</p>
          
          <p style={{marginTop: '-20px'}}>
            You must not:
            <ul>
              <li>Reuse content from Rex Vets - Offer, lease, or grant permission for the use of material from Rex Vets</li>
              <li>Sell, rent, or sub-license material from Rex Vets</li>
              <li>Reproduce, duplicate, or copy material from Rex Vets</li>
              <li>Redistribute content from Rex Vets</li>
            </ul>
          </p>

          <h3>User Comments and Feedback</h3>
          <p>Certain sections of this website provide users with the opportunity to share and discuss their thoughts and knowledge. Rex Vets allows Comments to be posted on the website without any filtering, editing, publishing, or reviewing beforehand. The views and opinions expressed in the comments are not representative of Rex Vets, its agents, and/or affiliates. Opinions expressed in the comments are solely those of the individuals who post them.
            <br/><br/>We reserve the right to monitor all Comments to ensure they meet our standards and align with our terms and conditions. Any comments that are deemed inappropriate, offensive, or in violation of our terms and conditions will be promptly removed.
          </p>

          <p style={{marginTop: '-20px'}}>
            By posting Comments on our website, you warrant and represent that:
            <ul>
              <li>You have the right to post the Comments and have all necessary licenses and consents.</li>
              <li>The Comments do not infringe any intellectual property right, including without limitation copyright, patent, or trademark of any third party.</li>
              <li>The Comments do not contain any defamatory, libelous, offensive, indecent, or otherwise unlawful material.</li>
              <li>The Comments will not be used to solicit or promote business or unlawful activity.</li>
            </ul>
            By agreeing to these terms, you are giving Rex Vets permission to utilize, modify, and allow others to utilize and modify any comments you provide, across various platforms and mediums.
          </p>

          <h3>Hyperlinking to Our Content</h3>
          <p>
            The following organizations may link to our Website without prior written approval:
            <ul>
              <li>Government agencies</li>
              <li>Search engines</li>
              <li>News organizations</li>
            </ul>
            These organizations are welcome to include links to our home page, publications, or other website information, as long as the link is honest and does not give the false impression that the linking party and its products/services are sponsored, endorsed, or approved by us. The link should also be relevant to the content of the linking party's site.
            <br/><br/>
            We may approve link requests from well-known consumer/business information sources, dot.com community sites, charities, online directory distributors, internet portals, accounting, law, and consulting firms, educational institutions, and trade associations.
          </p>

          <h3>iFrames</h3>
          <p>Please note that altering the visual presentation or appearance of our website by creating frames around our webpages is not permitted without obtaining prior approval and written permission.</p>

          <h3>Content Liability</h3>
          <p>Please note that we cannot assume responsibility for any content that may appear on your website. By agreeing to protect and defend us against any claims that may arise from your website, you demonstrate your commitment to ensuring our safety. It is important to ensure that no links on any website are deemed defamatory, offensive, illegal, or in violation of any third party rights.</p>

          <h3>Reservation of Rights</h3>
          <p>We reserve the right to request that you remove all links or any particular link to our website. You agree to immediately remove all links to our website upon request. Our terms and conditions, as well as our linking policy, may be subject to changes at any time. By consistently connecting to our website, you are expected to adhere to and abide by these terms and conditions for linking.</p>

          <h3>Removal of Links from Our Website</h3>
          <p>If you come across any link on our website that you find offensive for any reason, please feel free to reach out to us at any time. We will take into account requests to remove links, but we are not obliged to comply or provide a direct response.</p>

          <h3>Contact Information</h3>
          <p>For any questions about these Terms and Conditions, please contact us at <a href="mailto:support@rexvets.com">support@rexvets.com</a></p>

        </div>
        <Footer />
      </div>
    </>
  );
};
export default TermsC;
