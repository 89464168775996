import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";
import { getAuth, GoogleAuthProvider } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBPGHO0V9o2uSzt5B2V3TcuIgAgOXViIBs",
  authDomain: "rexvets-database.firebaseapp.com",
  projectId: "rexvets-database",
  storageBucket: "rexvets-database.appspot.com",
  messagingSenderId: "167105268869",
  appId: "1:167105268869:web:203c6d5b0676cc535b6dec",
  measurementId: "G-GH1G2WE7V3"
};

const app = initializeApp(firebaseConfig);

const storage = getStorage(app);
const db = getFirestore(app);
const auth = getAuth(app);

export const provider = new GoogleAuthProvider();

export { app, db, storage, auth };
