import Cover from '../../../1_MediaAssets/VetsandTechs/RegistrationVetsandTechs/Cover2.png';
import Texture from '../../../1_MediaAssets/OtherBasics/Texture.png';
import CutePets from "../../CutePets.js";
import { getAuth, sendPasswordResetEmail  } from "firebase/auth";
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {getFirestore } from "firebase/firestore";

const ForgotPasswordPetParents = () => { 
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const auth = getAuth();
    // eslint-disable-next-line 
    const db = getFirestore();

   async function handleSubmit(){
    var validRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    
    if (!email.match(validRegex)) {
        document.getElementById("message").innerHTML = "Please enter a valid email address";
        return;
    }

    console.log(email);
        try {
            await sendPasswordResetEmail(auth, email);
            navigate('/LoginPetParents', { state: { message: 'Password reset email sent' } });
            console.log('Password reset email sent');
        } catch (error) {
            console.error('Error sending password reset email:', error);
        }
    }

    function Email(event){
        setEmail(event.target.value);
        console.log(email);
    }


    return (
            <div style={{minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden', backgroundImage:`url('${Cover}')`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}}>
                <div className='RegistrationWhiteBox' style={{backgroundImage:`url('${Texture}')`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', marginTop: '30px'}}>
                    <div style={{width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', padding: '30px', flexDirection: 'column'}}>
                        <h2 style={{fontWeight: '900', color: '#002366', fontSize: '30px'}}>Forgot Password</h2>
                        <CutePets />
                    </div>

                    <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column', marginTop: '-15px'}}>
                        <div className='optionBox' style={{marginTop: '0', height: '100%', justifyContent: 'flex-start', alignItems: 'flex-start', marginBottom: '10px'}}> 
                            <div className='OnlyColumnPage2' style={{padding: '0px 30px 0px 30px'}}>
                                <div style={{display: 'flex', flexDirection: 'column', width: '100%', marginTop: '10px'}}>
                                    <label style={{fontWeight: '900', fontSize: '13px'}}>Email</label>
                                    <input onChange={Email} type="text" id='email' style={{width:"100%", height:'35px', borderRadius: '5px', padding: '8px', border: '1px solid grey', fontSize: '14px'}}/>
                                </div>
                                <div id='message' style={{ color: 'red', fontSize: '11px', width: '100%', textAlign: 'center', height: '13px', margin: '20px 0px'}}></div>
                                <button style={{width: '100%'}} onClick={handleSubmit}>Submit</button>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
        )
}

export default ForgotPasswordPetParents