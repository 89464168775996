import React from "react";
import Navbar from '../../Navbars/NavbarMain/NavbarMain.js';
import Footer from '../../Navbars/Footer/index.js';
import { NavLink } from 'react-router-dom';

const NonProfitPage = () => {
  return (
    <>
    <Navbar />
    {/* <div className="nonprofit-background">

      </div> */}
    <div className="page-nonprofit" style={{marginTop:'80px'}}>
    <div className="container nonprofit-section block-padding no-bg-small">
        <div className="row pb-3">
            <div className="col-lg-6">
                <h2 className='titleGoal'>Our Non-Profit Commitment</h2>
                <p className="mt-3 res-margin textGoal">
                At Rex Vets, we have transitioned to a non-profit organization to further our commitment to the animals and communities we serve. By offering telehealth veterinary services, we can reach more pets in need, including those in remote or underserved areas. 
                </p>
            </div>
        </div>
        <p  className="col-lg-8" style={{fontSize:'1.3em', color:'white'}}>As a non-profit, we rely on the generosity of our supporters—people like you who believe in our mission. Your donations directly support our telehealth programs, allowing us to provide affordable consultations, emergency guidance, and ongoing pet health support from the comfort of your home. We aim to make a meaningful impact, one virtual visit at a time, and your support is crucial to making this happen.</p>

    </div>
    
</div>
<div className="cta-container" style={{marginBottom:'0px', paddingBottom:'25px'}}>
      <div className="cta-content">
        <h2  style={{marginBottom:'0px', paddingBottom:'0px'}}>Join us in making a difference.</h2>
        <p>
        <br></br>Learn more about how you can support Rex Vets or<br></br>make a donation today to help expand our telehealth
        </p>
        <NavLink to="/Donate" className="buttonsWhat"  style={{fontSize:'25px'}}>Donate Now</NavLink>
      </div>

    </div>

<Footer/>
</>
  );
};

export default NonProfitPage;