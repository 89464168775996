import { FaInstagram, FaFacebookSquare, FaWhatsapp, FaTiktok } from "react-icons/fa";
import { MdAlternateEmail } from "react-icons/md";
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { PiPhoneCallFill } from "react-icons/pi";

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    document.getElementById("SubmitButton").innerHTML = "Loading...";
    e.preventDefault();

    const formData = new FormData(form.current);
    const allFieldsFilled = Array.from(formData.values()).every(value => value.trim() !== "");

    if (!allFieldsFilled) {
      document.getElementById('errorMessage').innerHTML = "Kindly fill in all fields.";
      setTimeout(function() {
          document.getElementById('errorMessage').innerHTML = "";
      }, 4000);
      document.getElementById("SubmitButton").innerHTML = "Submit";
      return;
    }

    emailjs.sendForm('service_cs4xaie', 'template_1vzc1lk', form.current, 'lyc-l8dRR0bHdWw_O')
      .then((result) => {
        console.log(result.text);
        document.getElementById('errorMessage').style.color = "green";
        document.getElementById('errorMessage').innerHTML = "We will get back to you soon!";      
        document.getElementById("SubmitButton").innerHTML = "Submit";
        setTimeout(function() {
            document.getElementById('errorMessage').innerHTML = "";
        }, 4000);
        e.target.reset(); // Reset the form after successful submission
      }, (error) => {
        console.log(error.text);
        document.getElementById('errorMessage').innerHTML = "Error sending message. Please try again later.";
        setTimeout(function() {
            document.getElementById('errorMessage').innerHTML = "";
        }, 4000);
      });
  };

  return ( 
        <div className="mainDivContact">
          <form ref={form} onSubmit={sendEmail} className='ContactForm'>
            <h1 className="hero-title" style={{color: '#002366', fontSize: '30px'}}>Contact Us</h1>
            <input className='inputContact' required placeholder="Full Name" type="text" name="fullName" />
            <input className='inputContact' required placeholder="Email Address" type="email" name="email"  />
            <input className='inputContact' required placeholder="Phone Number" type="text" name="phoneNumber"/>
            <input className='inputContact' required placeholder="Subject" type="text" name="subject"/>
            <textarea className='inputContact' required placeholder="Message" cols="30" rows="3" name="message"></textarea>
            
            <div className='errorMessage' id='errorMessage' style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', color: 'red', fontSize: '10px', height: '12px'}}></div>

            <button id="SubmitButton" style={{fontSize: '14px', width: '100%', background: '#002366', borderRadius: '0px'}}>Submit</button>
          </form>

          <div className="imagediv">
            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-start', width: '100%', gap: '10px', padding: '10px', flexDirection: 'column' }}>
                <div className="cta">
                    <FaInstagram className="iii" style={{ color: '#fff', padding: '0', margin: '0', fontSize: '30px' }} />
                    <a className="button-text" style={{ textDecoration: 'none', color: 'black', fontSize: '10px', padding: '0' }} href="https://www.instagram.com/rexvets/" target="_blank" rel="noopener noreferrer">
                        @rexvets
                    </a>
                </div>

                <div className="cta">
                    <FaFacebookSquare className="iii" style={{ color: '#fff', padding: '0', margin: '0', fontSize: '30px' }} />
                    <a className="button-text" style={{ textDecoration: 'none', color: 'black', fontSize: '10px', padding: '0' }} href="https://www.facebook.com/rexvets" target="_blank" rel="noopener noreferrer">
                        @rexvets
                    </a>
                </div>

                <div className="cta"> 
                    <PiPhoneCallFill className="iii" style={{ color: '#fff', padding: '0', margin: '0', fontSize: '30px' }} />
                    <a className="button-text" style={{ textDecoration: 'none', color: 'black', fontSize: '10px', padding: '0' }} href="tel:+18888080495" target="_blank" rel="noopener noreferrer">
                    1 (888) 808-0495
                    </a>
                </div>

                <div className="cta">
                    <FaTiktok className="iii" style={{ color: '#fff', padding: '0', margin: '0', fontSize: '30px' }} />
                    <a className="button-text" style={{ textDecoration: 'none', color: 'black', fontSize: '10px', padding: '0' }} href="https://www.tiktok.com/@rexvets" target="_blank" rel="noopener noreferrer">
                        @rexvets
                    </a>
                </div>

                <div className="cta">
                    <MdAlternateEmail className="iii" style={{ color: '#fff', padding: '0', margin: '0', fontSize: '30px' }} />
                    <a className="button-text" href="mailto:support@rexvets.com" style={{ textDecoration: 'none', color: 'black', fontSize: '10px', padding: '0' }}>
                        support@rexvets.com
                    </a>
                </div>
            </div>
          </div>
        
        </div>
  );
};

export default Contact;
