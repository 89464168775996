import React, { useState, useEffect } from 'react';
import Navbar from '../../Navbars/NavbarMain/NavbarMain.js';
import Footer from '../../Navbars/Footer/index.js';
import { NavLink } from 'react-router-dom';
import "../../1_MediaAssets/Styles/flaticon.css";
import Fondo1 from '../../1_MediaAssets/MainPages/fondo1.png';
import Fondo2 from '../../1_MediaAssets/MainPages/fondo2.png';
import Fondo3 from '../../1_MediaAssets/MainPages/fondo3.png';
import Fondo4 from '../../1_MediaAssets/MainPages/fondo4.png';
import Fondo5 from '../../1_MediaAssets/MainPages/fondo5.png';
import Fondo6 from '../../1_MediaAssets/MainPages/fondo6.png';
import Fondo7 from '../../1_MediaAssets/MainPages/fondo7.png';
import Fondo8 from '../../1_MediaAssets/MainPages/fondo8.png';
import Icono1 from '../../1_MediaAssets/MainPages/icon1.png';
import Icono2 from '../../1_MediaAssets/MainPages/icon2.png';
import Icono3 from '../../1_MediaAssets/MainPages/icon3.png';
import Icono4 from '../../1_MediaAssets/MainPages/icon4.png';
import Icono5 from '../../1_MediaAssets/MainPages/icon5.png';
import Icono6 from '../../1_MediaAssets/MainPages/icon6.png';
import Icono7 from '../../1_MediaAssets/MainPages/icon7.png';
import Icono8 from '../../1_MediaAssets/MainPages/icon8.png';
import anxiety from '../../1_MediaAssets/MainPages/dog1.png';
import image2 from '../../1_MediaAssets/MainPages/dog2.png';
import image3 from '../../1_MediaAssets/MainPages/dog3.png';
import image4 from '../../1_MediaAssets/MainPages/dog9.png';
import image5 from '../../1_MediaAssets/MainPages/dog10.png';
import image6 from '../../1_MediaAssets/MainPages/cateating.png';
import image7 from '../../1_MediaAssets/MainPages/care.png';
import image8 from '../../1_MediaAssets/MainPages/uri.png';




const WhatWeTreat = () => {

    const services = [
        { title: 'Allergies', description: 'Itching (Most Common Sign): Itching is the primary indicator of dog allergies...', image: Fondo1, icon: Icono1, id: "allergies-section" },
        { title: 'Anxiety', description: 'Trembling or shaking: Rex Vets provides holistic treatment combining medication and behavior modification...', image: Fondo2, icon: Icono2, id: "anxiety-section" },
        { title: 'Arthritis & Joint Pain', description: "Pets can't verbally communicate their discomfort, but they show it through behavior and movement...", image: Fondo3, icon: Icono3, id: "arthritis-section" },
        { title: 'Coughing', description: 'Coughing in dogs can be caused by a variety of factors, ranging from minor irritations to serious health conditions...', image: Fondo4, icon: Icono4, id: "coughing-section" },
        { title: 'Diarrhea', description: 'Both dogs and cats can experience diarrhea, which may result from various factors...', image: Fondo5, icon: Icono5, id: "diarrhea-section" },
        { title: 'Diet & Nutrition', description: 'Pet obesity is a growing concern, with over 50% of US pets being overweight...', image: Fondo6, icon: Icono6, id: "diet-section" },
        { title: 'Preventative Care', description: "At Rex Vets, we believe in the importance of preventive care for your pet's long-term health and happiness.", image: Fondo7, icon: Icono7, id: "preventative-care-section" },
        { title: 'Urinary Health', description: 'Promoting urinary health is crucial for the well-being of your dogs and cats...', image: Fondo8, icon: Icono8, id: "urinary-health-section" },
    ];

    return (
        <>
            <Navbar />
            <div className='allServices'>
                <section id="counter-section" className="container-fluid counter-calltoaction bg-fixed overlay">
                    <div id="counter" className="container">
                        <div className="row col-lg-10 offset-lg-1">
                            <div className="col-xl-3 col-md-6">
                                <div className="counter">
                                    <i className="counter-icon flaticon-dog-in-front-of-a-man"></i>
                                    <div className="counter-value" data-count="14">35</div>
                                    <h3 className="title">Professionals</h3>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-6">
                                <div className="counter">
                                    <i className="counter-icon flaticon-dog-18"></i>
                                    <div className="counter-value" data-count="1200">1K</div>
                                    <h3 className="title">Clients</h3>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-6">
                                <div className="counter">
                                    <i className="counter-icon flaticon-dog-2"></i>
                                    <div className="counter-value" data-count="100">341</div>
                                    <h3 className="title">Patients</h3>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-6">
                                <div className="counter">
                                    <i className="counter-icon flaticon-prize-badge-with-paw-print"></i>
                                    <div className="counter-value" data-count="12">8</div>
                                    <h3 className="title">Awards</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="page">
                    <div className="container about-section block-padding no-bg-small">
                        <div className="row pb-3">
                            <div className="col-lg-6">
                                <h2 className='titleGoal'>Our Goal</h2>
                                <p className="mt-3 res-margin textGoal">
                                    At Rex Vets we recognize that access to veterinary care can sometimes be difficult for a number of reasons. Our goal is to close the gap: to offer cutting-edge telemedicine for pets, based on scientific principles, with consistent monthly treatments and unwavering support from reputable veterinarians. Because every pet deserves the opportunity to lead a joyful and healthy life.
                                </p>
                                <div className='buttonsWhatSection'>
                                    <NavLink to="/Contact" className="buttonsWhat">Contact</NavLink>
                                    <NavLink to="/FindAVet" className="buttonsWhat">Talk to a vet</NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='allMedical'>
                    <div className="medical-services-container">
                        {services.map((service, index) => (
                            <div key={index} className="medical-service-card">
                                <img src={service.image} alt={service.title} className="medical-service-image" />
                                <div className="medical-service-icon-container">
                                    <img src={service.icon} alt={`${service.title} icon`} className="medical-service-icon" />
                                </div>
                                <h3 className="medical-service-title">{service.title}</h3>
                                <p className="medical-service-description">{service.description}</p>
                                <a href={`#${service.id}`} className="medical-service-link">Read More</a>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div id="allergies-section" className="coughing-container">
    <a href="#" className="return-link">&lt; Return</a>
    <div className="coughing-content">
        <div className="coughing-text">
            <h2>Allergies</h2>
            <p>
                Signs that point to Dog Allergies:<br />
                <strong>Itching (Most Common Sign):</strong> Itching is the primary indicator of dog allergies. Our tailored plans provide immediate relief and focus on long-term skin repair to prevent future allergies.<br />
                <strong>Skin and Ear Infections:</strong> Allergies often lead to skin or ear infections. Our comprehensive approach combines medication and over-the-counter therapeutics to address both symptoms and root causes.<br />
                <strong>Excessive Grooming or Licking:</strong> Excessive licking, especially of the paws, can signal allergies, which we can help treat and prevent.<br />
                <strong>Scooting:</strong> Frequent scooting may indicate an itchy behind due to allergies, which our veterinarians can address.<br />
                <strong>Digestive Issues:</strong> Unexplained vomiting and diarrhea could be allergy-related. We can assist in identifying potential food allergies.<br />
                Discover the latest in allergy research and ensure your dog lives their best life with the help of our expert veterinarians.
            </p>
            <NavLink to="/FindAVet" className="buttonsWhat">Talk to a vet</NavLink>
        </div>
        <div className="coughing-image">
            <img src={image2} alt="Dog with Allergies" />
        </div>
    </div>
    <div className="considerations">
        <h3>Top Tips for Managing Pets with Allergies</h3>
        <div className="coughing-content">
            <div className="coughing-image">
                <img src={image4} alt="Dog with allergies" />
            </div>
            <ol>
                <li><strong>Consult a vet:</strong> for diagnosis and guidance.</li>
                <li><strong>Identify and remove allergen sources.</strong></li>
                <li><strong>Use hypoallergenic diets:</strong> if food allergies are suspected.</li>
                <li><strong>Regular grooming:</strong> to reduce allergens in their coat.</li>
                <li><strong>Keep a clean living environment.</strong></li>
                <li><strong>Administer prescribed allergy medications.</strong></li>
                <li><strong>Consider immunotherapy if recommended.</strong></li>
                <li><strong>Control fleas:</strong> and watch for infections.</li>
                <li><strong>Avoid allergenic foods and read labels.</strong></li>
                <li><strong>Schedule regular vet checkups.</strong></li>
                <li><strong>Implement environmental allergen control.</strong></li>
                <li><strong>Provide exercise and mental stimulation.</strong></li>
                <li><strong>Be consistent with treatments and routines.</strong></li>
            </ol>
        </div>
    </div>
</div>
            <div  id="anxiety-section" className="coughing-container">
                <a href="#" className="return-link">&lt; Return</a>
                <div className="coughing-content">
                    <div className="coughing-text">
                        <h2>Anxiety</h2>
                        <p>
                            Recognizing Pet Anxiety: <br />
                            <strong>For Dogs:</strong><br />
                            1. Trembling or shaking: Rex Vets provides holistic treatment combining medication and behavior modification.<br />
                            2. Fear of new people/places: Our experts address triggers and fears to create lasting solutions.<br />
                            3. Fear of being alone: We work with vet behaviorists to develop medication protocols.<br />
                            4. Excessive barking and destructive behavior: Rex Vets offers medication and training tools for lasting change.<br />
                            <strong>For Cats:</strong><br />
                            1. Hiding: We treat anxiety as a medical issue for lasting relief.<br />
                            2. Fear of new people/places: Our vets assess triggers and fears for a solution.<br />
                            3. Fear of being alone: We collaborate with vet behaviorists for a calmer cat.<br />
                            4. Excessive meowing and destructive behavior: Medication, training, and enrichment techniques lead to lasting change.<br />
                            5. Urinating outside the litter box: Rex Vets helps address this issue linked to anxiety or stress.
                        </p>
                        <NavLink to="/FindAVet" className="buttonsWhat">Talk to a vet</NavLink>
                    </div>
                    <div className="coughing-image">
                        <img src={anxiety} alt="Dog with Anxiety" />
                    </div>
                </div>
                <div className="considerations">
                    <h3>Anxiety Treatments Options</h3>
                    <div className="coughing-content">
                        <ul>
                            <li>Medications</li>
                            <li>Behavior Modification</li>
                            <li>Environmental Enrichment</li>
                            <li>Hypoallergenic Diet</li>
                            <li>Immunotherapy</li>
                            <li>Complementary Therapies</li>
                        </ul>
                    </div>
                    <h3>Benefits of Anxiety Treatments</h3>
                    <div className="coughing-content">
                        <ul>
                            <li>Improved Quality of Life</li>
                            <li>Healthier Behavior</li>
                            <li>Enhanced Bond</li>
                            <li>Reduced Health Risks</li>
                            <li>Peace of Mind</li>
                            <li>Safer Environment</li>
                            <li>Improved Socialization</li>
                            <li>Cost Savings</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div id="arthritis-section"  className="coughing-container">
                <a href="#" className="return-link">&lt; Return</a>
                <div className="coughing-content">
                    <div className="coughing-text">
                        <h2>Arthritis & Joint Pain</h2>
                        <p>
                            Recognizing Joint Pain in our pets<br />
                            Pets can't verbally communicate their discomfort, but they show it through behavior and movement. Signs include increased irritability, slower movement, difficulty getting up, increased sleep, and reluctance to engage in usual activities. If you notice these signs, consult a Rex online vet for guidance.
                        </p>
                        <NavLink to="/FindAVet" className="buttonsWhat">Talk to a vet</NavLink>
                    </div>
                    <div className="coughing-image">
                        <img src={image3} alt="Dog with Joint Pain" />
                    </div>
                </div>
                <div className="considerations">
                    <h3>Subtle Clues: Signs Your Pet Might Be Experiencing Joint Pain</h3>
                    <p>
                        <strong>Early Signs of Arthritis:</strong> Arthritis symptoms start with trouble getting up or down, walking stiffness, reluctance with stairs, and swollen or stiff joints. These symptoms develop gradually, so vigilant observation is crucial to detect them early.<br />
                        <strong>Addressing Joint Pain:</strong> Treatment options vary based on the cause and severity, including surgery, medication, and weight management. You can also explore home remedies like massage therapy and natural supplements, but always consult a vet first.
                    </p>
                    <p>
                        Dealing with your pet's joint pain can be less stressful with Rexvets.com. We offer convenient telemedicine for pets, ensuring you can focus on your dog's well-being without the hassle of securing local vet appointments.
                    </p>
                </div>
            </div>

            <div  id="diarrhea-section" className="coughing-container">
                <a href="#" className="return-link">&lt; Return</a>
                <div className="coughing-content">
                    <div className="coughing-text">
                        <h2>Diarrhea</h2>
                        <p>
                            Both dogs and cats can experience diarrhea, which may result from various factors. Common causes include dietary indiscretion, abrupt diet changes, food allergies, intestinal parasites, viral or bacterial infections, and stress. In addition, both species can suffer from chronic conditions such as Inflammatory Bowel Disease. When persistent or severe, diarrhea in dogs and cats should prompt a visit to the veterinarian for a thorough evaluation and proper treatment, as dehydration and other health complications can arise from prolonged bouts of diarrhea.
                        </p>
                        <NavLink to="/FindAVet" className="buttonsWhat">Talk to a vet</NavLink>
                    </div>
                    <div className="coughing-image">
                        <img src={image5} alt="Dog with Diarrhea" />
                    </div>
                </div>
                <div className="considerations">
                    <h3>How should I manage a pet with diarrhea?</h3>
                    <ol>
                        <li>Hydration: Keep your pet hydrated with water and, if needed, ice chips.</li>
                        <li>Fasting: Fast your pet for 12-24 hours, then reintroduce a bland diet.</li>
                        <li>Probiotics: Consider probiotics to restore gut health, with your vet's guidance.</li>
                        <li>Dietary Changes: Identify and eliminate dietary triggers, and switch to a sensitive stomach formula if necessary.</li>
                        <li>Parasite Control: Maintain regular deworming and flea control.</li>
                        <li>Stress Reduction: Minimize stressors in your pet's environment.</li>
                        <li>Vet Consultation: If diarrhea persists or worsens, consult your vet, especially for young, elderly, or ill pets. Follow their recommendations for tests and treatment.</li>
                    </ol>
                </div>
            </div>

            <div id="diet-section" className="coughing-container">
                <a href="#" className="return-link">&lt; Return</a>
                <div className="coughing-content">
                    <div className="coughing-text">
                        <h2>Diet & Nutrition</h2>
                        <p>
                            Pet obesity is a growing concern, with over 50% of US pets being overweight. This can lead to various health issues like diabetes, high blood pressure, heart disease, respiratory problems, joint pain, arthritis, cancer, and a shortened lifespan. Proper nutrition and exercise are crucial for maintaining a healthy weight. At-home pet nutrition consulting with our veterinarians can help you develop a personalized plan to keep your pet healthy and happy.
                        </p>
                        <NavLink to="/FindAVet" className="buttonsWhat">Talk to a vet</NavLink>
                    </div>
                    <div className="coughing-image">
                        <img src={image6} alt="Diet & Nutrition" />
                    </div>
                </div>
                <div className="considerations">
                    <h3>How does diet affect my pet's health long term?</h3>
                    <p>
                        In a virtual telehealth appointments with Rex Vets, we adopt a holistic approach to evaluate your pet's nutritional requirements. Our veterinarians will engage in a thorough discussion with you about your pet's dietary needs, considering factors such as age, breed, activity level, lifestyle, and any specific health issues. We recognize the individuality of each pet and strive to provide personalized guidance to support their overall health and wellness.
                    </p>
                    <p>
                        It's essential to communicate any past incidents of unfavorable food reactions or aversions your pet has encountered. This information aids us in customizing our recommendations and preventing potential adverse reactions during the telehealth consultation.
                    </p>
                </div>
            </div>

            <div  id="preventative-care-section" className="coughing-container">
                <a href="#" className="return-link">&lt; Return</a>
                <div className="coughing-content">
                    <div className="coughing-text">
                        <h2>Preventative Care</h2>
                        <p>
                            At Rex Vets, we believe in the importance of preventive care for your pet's long-term health and happiness. Our Pet Wellness Exams include a comprehensive discussion of your pet's lifestyle and home environment. Our compassionate veterinarians will also address any concerns you may have regarding your pet's nutrition and behavior. To ensure your pet's optimal care, our veterinarians may suggest additional supplements or treatments, as needed. With same-day or next-day availability, we make prioritizing your pet's health and well-being convenient.
                        </p>
                        <NavLink to="/FindAVet" className="buttonsWhat">Talk to a vet</NavLink>
                    </div>
                    <div className="coughing-image">
                        <img src={image7} alt="Preventative Care" />
                    </div>
                </div>
                <div className="considerations">
                    <h3>Why does preventative care matter?</h3>
                    <p>
                        Ensuring your pet's health and happiness is our top priority at Rex Vets. One of the most important ways to achieve this is by keeping your furry companion up-to-date with regular veterinary check-ups.
                    </p>
                    <p>
                        Depending on your pet's individual needs, a wellness checkup may be required more frequently, especially if you have any health concerns.
                    </p>
                    <p>
                        For puppies and kittens, we recommend more frequent visits to ensure they get the best start in life.
                    </p>
                </div>
            </div>

            <div id="urinary-health-section" className="coughing-container">
                <a href="#" className="return-link">&lt; Return</a>
                <div className="coughing-content">
                    <div className="coughing-text">
                        <h2>Urinary Health</h2>
                        <p>
                            Promoting urinary health is crucial for the well-being of your dogs and cats. From ensuring proper hydration to addressing potential issues like urinary tract infections or bladder stones, it's essential to stay vigilant. At Rex Vets, we provide expert guidance and advice on maintaining your pet's urinary health. Our veterinarians can offer tips on hydration, dietary choices, and early detection of urinary issues, helping your furry friends enjoy a healthy and comfortable life. Prioritize urinary health with Rex Vets, and keep your pets happy and thriving.
                        </p>
                        <NavLink to="/FindAVet" className="buttonsWhat">Talk to a vet</NavLink>
                    </div>
                    <div className="coughing-image">
                        <img src={image8} alt="Urinary Health" />
                    </div>
                </div>
                <div className="considerations">
                    <h3>Considerations for Urinary Health in Dogs & Cats</h3>
                    <ol>
                        <li>Hydration: Ensure they have access to fresh water.</li>
                        <li>Diet: Provide a balanced diet suitable for urinary health.</li>
                        <li>Monitor Water Intake: Watch for changes in drinking habits.</li>
                        <li>Vet Checkups: Schedule regular vet visits.</li>
                        <li>Litter Box: Keep it clean and accessible for cats.</li>
                        <li>Weight: Maintain a healthy weight.</li>
                        <li>Stress Reduction: Minimize stressors.</li>
                        <li>Exercise: Encourage physical activity.</li>
                        <li>Early Detection: Watch for unusual urination signs.</li>
                        <li>Consult Vet: Seek vet advice for any concerns.</li>
                    </ol>
                </div>
            </div>

            <Footer />
        </>
    );
}

export default WhatWeTreat;