import LoadingScreen from '../../../LoadingScreen/index.js';
import React, { useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { collection, getDocs, getFirestore, query, where, updateDoc } from "firebase/firestore";
import NavbarVetsandTechsDashboard from '../../../Navbars/NavbarVetsandTechsDashboard';

const SpeciesSelection = () => {
    const [species, setSpecies] = useState([]);
    const [vetData, setVetData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [saveLoading, setSaveLoading] = useState(false);
    const [message, setMessage] = useState('');
    const db = getFirestore();
    const auth = getAuth();

    const options = [
        "Dogs (Canine)", "Cats (Feline)", "Birds (Avian)", "Farm Animals", "Horses (Equine)",
        "Reptiles & Amphibians", "Small Mammals", "Fish", "Other Exotics"
    ];

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                try {
                    const userId = user.uid;
                    const q1 = query(collection(db, "Doctors/Technician/Technician"), where("uid", "==", userId));
                    const q2 = query(collection(db, "Doctors/Veterinarian/Veterinarian"), where("uid", "==", userId));

                    let querySnapshot = await getDocs(q1);
                    if (querySnapshot.empty) {
                        querySnapshot = await getDocs(q2);
                    }

                    if (!querySnapshot.empty) {
                        const vetDoc = querySnapshot.docs[0];
                        setVetData(vetDoc);
                        setSpecies(vetDoc.data().SpeciesTreated || []);
                        setLoading(false);
                    } else {
                        console.error('No such document!');
                        setLoading(false);
                    }
                } catch (error) {
                    console.error('Error fetching vet data:', error);
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        });

        return () => unsubscribe();
    }, [auth, db]);

    const handleOptionClick = (option) => {
        setSpecies(prevSpecies => {
            const isSelected = prevSpecies.some(specie => specie.type === option);
            if (isSelected) {
                return prevSpecies.filter(specie => specie.type !== option);
            } else {
                return [...prevSpecies, { type: option, checked: true }];
            }
        });
    };

    const handleSave = async () => {
        setSaveLoading(true);
        setMessage('');
        if (vetData) {
            try {
                const docRef = vetData.ref;
                await updateDoc(docRef, {
                    SpeciesTreated: species
                });
                setMessage('Species treated updated successfully!');
            } catch (error) {
                console.error('Error updating species treated:', error);
                setMessage('Species treated not updated, try again later or contact support@rexvets.com');
            } finally {
                setSaveLoading(false);
                setTimeout(() => setMessage(''), 4000);
            }
        }
    };

    const handleClearSelection = () => {
        setSpecies([]);
    };

    if (loading) {
        return <LoadingScreen />;
    }

    return (
        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100vw', height: '100%', background: '#f5f5f5' }}>
            <div className='dashboardmain' style={{ paddingBottom: '50px' }}>
                <NavbarVetsandTechsDashboard />
                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'column', background: '#fff', borderRadius: '10px', width: '90%', marginTop: '50px', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', padding: '15px', borderBottom: '1px solid #dedcd7' }}>
                        <h4 style={{ padding: '0', margin: '0', fontWeight: '900' }}>
                            Species Treated
                        </h4>
                        <button onClick={handleClearSelection} style={{ padding: '5px 10px', borderRadius: '5px', backgroundColor: '#FF6347', color: '#fff', cursor: 'pointer', fontSize: '12px' }}>
                            Clear Selection
                        </button>
                    </div>
                    <div className='YourPetsDiv' style={{ justifyContent: 'flex-start' }}>
                        <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', width: '100%', margin: '0', padding: '0' }}>
                            <p style={{ fontSize: '14px' }}>Choose all that apply:</p>
                        </div>
                        {options.map((option, index) => (
                            <div
                                key={index}
                                className="ConcernDiv"
                                style={{
                                    background: species.some(specie => specie.type === option && specie.checked) ? "#FFE699" : "#edeef0"
                                }}
                                onClick={() => handleOptionClick(option)}
                            >
                                <p style={{ color: 'black', margin: '0', padding: '0', fontSize: '14px' }}>{option}</p>
                            </div>
                        ))} 
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '-10px', marginBottom: '20px', width: '100%', padding: '15px', flexDirection: 'column' }}>
                        <div style={{ height: '20px', marginBottom: '10px' }}>
                            {message && (
                                <div style={{ color: message.includes('successfully') ? 'green' : 'red', fontSize: '10px', textAlign: 'center' }}>
                                    {message}
                                </div>
                            )}
                        </div>
                        <button type="button" onClick={handleSave} style={{ padding: '10px 20px', borderRadius: '5px', cursor: 'pointer', width: '100%' }}> {saveLoading ? 'Loading...' : 'Update'} </button>
                    </div>
                    
                </div>
            </div>
        </div>
    );
};

export default SpeciesSelection;
