import Navbar from '../../Navbars/NavbarMain/NavbarMain.js';
import bg1 from '../../1_MediaAssets/Homepage/bg1.jpg';
import doctorImg from '../../1_MediaAssets/Homepage/doctor.png';
import benefitsImage from '../../1_MediaAssets/Homepage/benefitsImage.png';
// import ptImg1 from '../../1_MediaAssets/Homepage/circle-dots.png';
import ptImg2 from '../../1_MediaAssets/Homepage//square-blue.png';
import ptImg3 from '../../1_MediaAssets/Homepage/chicle-blue-2.png';
import ptImg4 from '../../1_MediaAssets/Homepage/plus-blue.png';
import ptImg5 from '../../1_MediaAssets/Homepage/wave-blue.png';
import profile1 from  '../../1_MediaAssets/Homepage/profile1.png';
import profile2 from  '../../1_MediaAssets/Homepage/profile2.png';
import profile3 from '../../1_MediaAssets/Homepage/profile3.png';
import profile4 from  '../../1_MediaAssets/Homepage/profile4.png';
import profile5 from  '../../1_MediaAssets/Homepage/profile5.png';
import profile6 from '../../1_MediaAssets/Homepage/profile6.png';
import mobile from '../../1_MediaAssets/Homepage/mobilerex.png';
import donateImage from '../../1_MediaAssets/Homepage/donatenow.png'
import "../../1_MediaAssets/Styles/flaticon.css";
// import prImg1 from '../../1_MediaAssets/Homepage/product1.png';
// import prImg2 from '../../1_MediaAssets/Homepage/product2.png';
// import prImg3 from '../../1_MediaAssets/Homepage/product3.png';
// import prImg4 from '../../1_MediaAssets/Homepage/product4.png';
// import prImg5 from '../../1_MediaAssets/Homepage/product5.png';
// import prImg6 from '../../1_MediaAssets/Homepage/product6.png';
// import DogImage from '../../1_MediaAssets/Homepage/PetStickers/dog3.png';
import marquee1 from '../../1_MediaAssets/Homepage/marquee1.png'
import marquee2 from '../../1_MediaAssets/Homepage/marquee2.png'
import marquee3 from '../../1_MediaAssets/Homepage/marquee3.png'
import marquee4 from '../../1_MediaAssets/Homepage/marquee4.png'
import Footer from '../../Navbars/Footer/index.js';
// import Texture from '../../1_MediaAssets/OtherBasics/Texture.png'
import React, { useState, useEffect } from 'react';
import BlogsWidget from '../../MainPages/Blogs/BlogsWidget.js'
import { NavLink } from 'react-router-dom';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
// import { Padding } from '@mui/icons-material';


const Home = () => {
   
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
  };

  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    speed: 7000,
    autoplaySpeed: 100,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,  // Centra los slides si es necesario
          centerPadding: '10px'  // Reduce el espacio de los lados si usas centerMode
        }
      }
    ]


  };
  const brands = [
    { id: 2, name: 'HUFFPOST', imageUrl: marquee4 },
    { id: 3, name: 'TechCrunch', imageUrl: marquee3 },
    { id: 4, name: 'yahoo!news', imageUrl: marquee1 },
    { id: 5, name: 'THE GLOBE AND MAIL', imageUrl: marquee2 }
  ];
  const doubledBrands = [...brands, ...brands];
    return (

        <> 
            <Navbar />

          <section >
            <div className="main-banner" style={{backgroundImage: "url("+bg1+")"}}>
                <div className="container inner-content">
                    <div className="row align-items-center">
                        <div className="col-lg-7 col-md-6 col-sm-7">
                            <h6 className="title-ext text-primary">As a non-profit organization, Rex Vets provides free telehealth consultations to ensure every pet gets the care they deserve from the comfort of home.</h6>
                            <h1>Free Veterinary Care. Anytime, Anywhere.</h1>
                            <div className='makecolHomeHero'>
                              <NavLink to="/FindAVet" className="btn-theme" style={{width: '100%', maxWidth: '400px'}}>Schedule a Free Visit</NavLink>
                              <NavLink to="/Donate" className="btn-theme" style={{width: '100%', maxWidth: '400px'}}>Support Our Mission</NavLink>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-6 col-sm-5">
                            <div className="banner-img">
                                <img src={doctorImg} alt="" className="img-fluid"/>
                            </div>
                        </div>
                    </div>
                </div>
                <img className="pt-img1 animate1" src={ptImg4} alt=""/>
                <img className="pt-img2 animate2" src={ptImg2} alt=""/>
                <img className="pt-img3 animate3" src={ptImg3} alt=""/>
                <img className="pt-img4 animate4" src={ptImg4} alt=""/>
                <img className="pt-img5 animate-wave" src={ptImg5} alt=""/>
            </div>
            <div className="marquee-container">
  <div className="static-text">Award winning &<br></br> featured in...</div>
  <div className="marquee">
    <div className="track">
      {doubledBrands.map((brand, index) => (
        <div key={index} className="brand">
          <img src={brand.imageUrl} alt={brand.name} />
        </div>
      ))}
    </div>
  </div>
</div>

            <section id="feature-section " className=" pattern1 mainServices">
         <div class="container ">
 
            <div class="section-heading text-center">
               
               <h2 className='titlebenefits'>Why Choose Rex Vets?</h2>
               <p className='pbenefits'>Rex Vets is a 501(c)(3) non-profit organization committed to providing convenient, affordable, and compassionate veterinary care through telehealth. Our experienced veterinarians are here to help you and your pet—no travel required.</p>
            </div>

            <div class="row text-lg-end">
               <div class="col-md-6 col-lg-3 my-auto p-3">

                  <div class="feature-with-icon" >
                     <div class="icon-features">

                        <i class="flaticon-pet-lotion"></i>
                     </div>
                     <h5 className='titlesbenefits'><strong>Affordable Care</strong></h5>
                     <p className='textbenefits'>Accessible virtual consultations with licensed veterinarians.
</p>
                  </div>

                  <div class="feature-with-icon" >
                     <div class="icon-features">

                        <i class="flaticon-veterinarian-hospital"></i>
                     </div>
                     <h5 className='titlesbenefits'><strong>Convenience</strong></h5>
                     <p className='textbenefits'>Get expert advice from the comfort of your home.

</p>
                  </div>


                  <div class="feature-with-icon">
                     <div class="icon-features">

                        <i class="flaticon-dog-training-1"></i>
                     </div>
                     <h5 className='titlesbenefits'><strong>Impact </strong></h5>
                     <p className='textbenefits'>Every visit and donation helps us serve more pets in need, especially in underserved communities.
</p>
                  </div>

               </div>
               <div class="col-md-6 col-lg-3 text-start p-3 my-auto pt-sm-0 order-3">

                  <div class="feature-with-icon">
                     <div class="icon-features">

                        <i class="flaticon-pet-shelter"></i>
                     </div>
                     <h5 className='titlesbenefits'><strong>Supporting a Cause</strong></h5>
                     <p className='textbenefits'>Donations fund emergency treatments, giving pets a chance at a healthy life.</p>
                  </div>

                  <div class="feature-with-icon">
                     <div class="icon-features">

                        <i class="flaticon-animal-1"></i>
                     </div>
                     <h5 className='titlesbenefits'><strong>24/7 Availability</strong></h5>
                     <p className='textbenefits'>Round-the-clock access to consultations, ensuring support at any time.</p>
                  </div>

                  <div class="feature-with-icon">
                     <div class="icon-features">

                        <i class="flaticon-pet"></i>
                     </div>
                     <h5 className='titlesbenefits'><strong>Education</strong></h5>
                     <p className='textbenefits' >Empowering pet owners with knowledge to make informed decisions about their pets' health.</p>
                  </div>

               </div>
 
               <div class="text-center col-lg-6 mx-auto mt-3">
   
                  <img alt="" class="img-fluid rounded benefitsImage" src={benefitsImage}/>
               </div>

            </div>

         </div>

      </section>
      <div className="PricingSection-container">
  <div className="PricingSection-content">
  <div className="PricingSection-plans">
  <div className="PricingSection-plan PricingSection-recommended">
    <div className="PricingSection-recommendedLabel">Most Popular</div>
    <h3>Community Champion</h3>
    <p>Support critical care for pets in underserved communities, ensuring access to necessary help.</p>
    <p style={{marginBottom:'20px'}}><strong>Suggested Contribution:</strong>  $100, $250, $500, or customize your amount.</p>
    <NavLink to="/DonatePage1" className="PricingSection-selectPlan">More Info</NavLink>
  </div>
  <div className="PricingSection-plan PricingSection-recommended">
    
    <h3>Friend of Rex Vets</h3>
    <p>Provide free telehealth consultations to pets in need with a monthly contribution.</p>
    <p style={{marginBottom:'20px'}}><strong>Suggested Contribution:</strong> Choose from $10, $25, $50, or customize your amount.</p>
    <NavLink to="/DonatePage1" className="PricingSection-selectPlan">More Info</NavLink>
  </div>
  <div className="PricingSection-plan">
    <h3>Pet Care Hero</h3>
    <p>Support comprehensive care services and help expand our reach to more pets in need.</p>
    <p style={{marginBottom:'20px'}}><strong>Suggested Contribution:</strong> $1,000 or more.</p>
    <NavLink to="/DonatePage1" className="PricingSection-talkToVet">More Info</NavLink>
  </div>
</div>
    
    <div className="PricingSection-details">
    <img src={donateImage} alt="Vet consultation" style={{width:'90%'}}/>
      <div className="PricingSection-header">
        <h2 className='text-center'>Here are some of the benefits <br />you receive when you make a donation</h2>
      
      <ul className="PricingSection-features">
        <li><i class="fa-sharp fa-solid fa-circle-check"></i>Access to exclusive online events</li>
        <li><i class="fa-sharp fa-solid fa-circle-check"></i>Regular updates on our impact</li>
        <li><i class="fa-sharp fa-solid fa-circle-check"></i>Recognition as a supporter</li>
        <li><i class="fa-sharp fa-solid fa-circle-check"></i>Personalized impact stories</li>

      </ul>
      <NavLink to="/PetParents" className="PricingSection-joinNow">Join Now</NavLink>
    </div>
    </div>
  </div>
</div>

          <div className="Pasos-wrapper">
            <div className="Pasos-container" style={{background: 'transparent'}}>
              <div className="Pasos-content">
                <h1 className="Pasos-title">Making a Difference, One Virtual Visit at a Time </h1>
                <p className="Pasos-subtitle" style={{marginTop: '20px'}}>Since becoming a non-profit, Rex Vets has expanded our reach, providing telehealth services to pets and families who need them the most. With your support, we can help even more pets live healthier, happier lives.</p>
                <div className="Pasos-steps">
                  <div className="Pasos-step">
                    <span className="Pasos-step-number">1</span>
                    <div className="Pasos-step-text">
                      <h2 className="Pasos-step-title">Schedule a Free Consultation:</h2>
                      <p className="Pasos-step-description">Find top-rated vets near you and pick the best one for your needs.</p>
                    </div>
                  </div>
                  <div className="Pasos-step">
                    <span className="Pasos-step-number">2</span>
                    <div className="Pasos-step-text">
                      <h2 className="Pasos-step-title">Connect Online:</h2>
                      <p className="Pasos-step-description">Choose a time that works for you and your pet for a consultation.</p>
                    </div>
                  </div>
                  <div className="Pasos-step">
                    <span className="Pasos-step-number">3</span>
                    <div className="Pasos-step-text">
                      <h2 className="Pasos-step-title">Order Medications Online</h2>
                      <p className="Pasos-step-description">Get specific guidance for your pet's health, including advice, triage, and second opinions.</p>
                      <p className="Pasos-step-description" style={{color: '#a3a3a3', fontWeight: '900'}}>Note: Prescriptions are not free and must be purchased separately.</p>
                    </div>
                  </div>


                  <NavLink to="/FindAVet" className="Pasos-talk-button text-center" style={{fontSize: '18px'}}>Find a vet</NavLink>
                </div>
              </div>
              <div className="Pasos-image-container" style={{background: 'transparent'}}>
                <img src={mobile} alt="Vet consultation" className="Pasos-image" style={{maxWidth: '500px'}}/>
              </div>
            </div>
          </div>

    
    <BlogsWidget />
        
            <section className='testimonialCase pattern1'>
      <div className="testimonials_section_2 sc_gradient_testimonials"  >
      <div className="testimonial-banner" >
        <h3 className="titlePatients text-center">What our patients say</h3>
        <Slider {...settings}>
    {[
        { name: "Joaquin Paiva", text: "Fantastic online vet service! Easy to use, and the vet was very knowledgeable and caring.", image: profile1, date: "June 2024" },
        { name: "Mario Bratford", text: "Good service with fast scheduling. The consultation felt a bit rushed, though.", image: profile2, date: "May 2024" },
        { name: "Luciana Aguilar", text: "Great online vet experience. The consultation was quick, and the vet was attentive. Highly recommend!", image: profile3, date: "April 2024" },
        { name: "Martin Espinoza", text: "Top-notch veterinary guidance online, easy booking and fast medication delivery.", image: profile4, date: "March 2024" },
        { name: "Lucas Sanabria", text: "Efficient and caring virtual vet consultations at a reasonable price.", image: profile5, date: "July 2024" },
        { name: "Laura Gomensoro", text: "Top-notch veterinary guidance online, easy booking and fast medication delivery.", image: profile6, date: "April 2024" }
    ].map((testimonial, index) => (
        <div key={index} className="sc_testimonials sc_testimonials_style_2">
            <div className="sc_avatar">
                <div className='patient-and-starspic'>
                    <img className="sc_image" src={testimonial.image} alt={`Testimonial ${index + 1}`}/>
                    <div className='patient-and-stars'>
                        <p className="patient-label">{testimonial.name}</p>
                        <div className="stars">
                            {[...Array(5)].map((_, starIndex) => (
                                <i key={starIndex} className="fa fa-star"></i>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div className="sc_testimonials_item_content enhanced-card">
                <div className="sc_testimonials_item_quote">
                    <div className="sc_testimonials_item_text">
                        {testimonial.text}
                    </div>
                    <div className="sc_testimonials_item_date">{testimonial.date}</div>
                    <div className="sc_testimonials_item_name">Client</div>
                </div>

            </div>
        </div>
    ))}
</Slider>

      </div>
    </div>
    
    </section>
    {/* <BlogsWidget /> */}
    


      </section>


            <Footer />


        </>
    )
}

export default Home;