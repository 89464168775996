  import { useState, useEffect } from 'react';
  import { db, auth } from '../../../../firebase';
  import { getDoc, setDoc, query, collection, where, getDocs } from "firebase/firestore";
  import NavbarVetsandTechsDashboard from '../../../Navbars/NavbarVetsandTechsDashboard/index.js';
  import { SlCalender } from "react-icons/sl";
  import { FaPlus, FaTrash } from "react-icons/fa";
  import React from 'react';

  const TimeOptions = () => {
    const options = [];
    for (let i = 0; i < 24; i++) {
      for (let j = 0; j < 60; j += 30) {
        let hour = i % 12 === 0 ? 12 : i % 12;
        let minute = j === 0 ? '00' : j;
        let period = i < 12 ? 'AM' : 'PM';
        const time = `${hour}:${minute} ${period}`;
        options.push(<option key={time} value={time}>{time}</option>);
      }
    }
    return options;
  };

  const RatesandAvailabilityVetsandTechs = () => {
    const [rows, setRows] = useState([
      { day: 'Monday', checked: true, timings: [{ from: '', until: '' }] },
      { day: 'Tuesday', checked: true, timings: [{ from: '', until: '' }] },
      { day: 'Wednesday', checked: true, timings: [{ from: '', until: '' }] },
      { day: 'Thursday', checked: true, timings: [{ from: '', until: '' }] },
      { day: 'Friday', checked: true, timings: [{ from: '', until: '' }] },
      { day: 'Saturday', checked: true, timings: [{ from: '', until: '' }] },
      { day: 'Sunday', checked: true, timings: [{ from: '', until: '' }] }
    ]);

    const [firstName, setFirstName] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
      const fetchData = async () => {
        const user = auth.currentUser;

        if (user) {
          const userId = user.uid;

          const q1 = query(collection(db, "Doctors/Technician/Technician"), where("uid", "==", userId));
          const q2 = query(collection(db, "Doctors/Veterinarian/Veterinarian"), where("uid", "==", userId));

          try {
            let docRef;
            let querySnapshot;

            querySnapshot = await getDocs(q1);
            if (!querySnapshot.empty) {
              docRef = querySnapshot.docs[0].ref;
            } else {
              querySnapshot = await getDocs(q2);
              if (!querySnapshot.empty) {
                docRef = querySnapshot.docs[0].ref;
              }
            }

            if (docRef) {
              const docSnap = await getDoc(docRef);

              if (docSnap.exists()) {
                const data = docSnap.data();
                setFirstName(data.FirstName);
                const schedule = data.Schedule || [];

                const updatedRows = rows.map((row) => {
                  const daySchedule = schedule.find(d => d.day === row.day) || { checked: false, timings: [{ from: '', until: '' }] };
                  return {
                    ...row,
                    checked: daySchedule.checked,
                    timings: daySchedule.timings
                  };
                });

                setRows(updatedRows);
              } else {
                console.log("No such document!");
              }
            } else {
              console.log("User document not found in either collection.");
            }
          } catch (error) {
            console.error("Error fetching document: ", error);
          }
        } else {
          console.log("User not authenticated.");
        }
      };

      fetchData();
      // eslint-disable-next-line
    }, []);

    const handleAddTimeSlot = (index) => {
      const newRows = [...rows];
      newRows[index].timings.push({ from: '', until: '', added: true }); // Set added property to true
      setRows(newRows);
    };
    
    const handleCheckboxChange = (index) => {
      const newRows = [...rows];
      newRows[index].checked = !newRows[index].checked;
      setRows(newRows);
    };

    const handleDeleteTimeSlot = (dayIndex, timingIndex) => {
      const newRows = [...rows];
      newRows[dayIndex].timings.splice(timingIndex, 1); // Remove the selected timing
      setRows(newRows);
    };

    const handleInputChange = (dayIndex, timingIndex, e, field) => {
      const newRows = [...rows];
      newRows[dayIndex].timings[timingIndex][field] = e.target.value;

      if (field === 'from') {
        if (newRows[dayIndex].timings[timingIndex].until !== '' && newRows[dayIndex].timings[timingIndex].from >= newRows[dayIndex].timings[timingIndex].until) {
          setError('The "From" time must be before the "Until" time.');
          newRows[dayIndex].timings[timingIndex].from = '';
        } else {
          setError('');
        }
      } else if (field === 'until') {
        if (newRows[dayIndex].timings[timingIndex].from !== '' && newRows[dayIndex].timings[timingIndex].until <= newRows[dayIndex].timings[timingIndex].from) {
          setError('The "Until" time must be after the "From" time.');
          newRows[dayIndex].timings[timingIndex].until = '';
        } else {
          setError('');
        }
      }

      setRows(newRows);
    };

    const handleUpdate = async () => {

      if (error) {
        setError('');
      }

      try {
        const user = auth.currentUser;
        if (user) {
          const userId = user.uid;

          const q1 = query(collection(db, "Doctors/Technician/Technician"), where("uid", "==", userId));
          const q2 = query(collection(db, "Doctors/Veterinarian/Veterinarian"), where("uid", "==", userId));

          let docRef;
          let querySnapshot;

          // Check in Technician collection
          querySnapshot = await getDocs(q1);
          if (!querySnapshot.empty) {
            docRef = querySnapshot.docs[0].ref;
          } else {
            // Check in Veterinarian collection
            querySnapshot = await getDocs(q2);
            if (!querySnapshot.empty) {
              docRef = querySnapshot.docs[0].ref;
            }
          }

          if (docRef) {
            const scheduleData = rows.map(row => ({
              checked: row.checked,
              day: row.day,
              timings: row.timings
            }));
            await setDoc(docRef, { Schedule: scheduleData }, { merge: true });
            setError("Updated successfully!");
          } else {
            console.log("User document not found in either collection.");
          }
        } else {
          console.log("User not authenticated.");
        }
      } catch (error) {
        console.error("Error updating document: ", error);
        setError("Error updating schedule.");
      }
    };

    return (
      <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100vw', height: '100%' }}>
        <div className='dashboardmain' style={{ paddingBottom: '200px' }}>
          <NavbarVetsandTechsDashboard />
          <div className='responsiveRates' style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'column', background: '#fff', borderRadius: '10px', width: '74%', marginTop: '50px', boxShadow: '0 0 #0000, 0 0 #0000, 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)' }}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column', width: '100%', padding: '15px', borderBottom: '1px solid #dedcd7' }}>
              <h4 style={{ padding: '0', margin: '0', display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: '900' }}>
                <SlCalender style={{ fontSize: '30px', marginRight: '10px', color: '#002366' }} />
                Rates & Availability - {firstName}
              </h4>
              <p style={{ marginTop: '5px' }}>Set your availability to accept appointment requests.</p>
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'column', background: '#fff', borderRadius: '10px', width: '90%', marginTop: '20px' }}>
              <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                <thead style={{ background: 'linear-gradient(90deg, #002366, #0056b3)', color: 'white', borderRadius: '10px' }}>
                  <tr>
                    <th className='headerratespage' style={{ width: '20%', borderRadius: '10px 0px 0px 10px' }}>Day</th>
                    <th className='headerratespage' style={{ width: '25%' }}>From</th>
                    <th className='headerratespage' style={{ width: '25%' }}>Until</th>
                    <th className='headerratespage' style={{ width: '10%', borderRadius: '0px 10px 10px 0px' }}>Add/Delete slot</th>
                  </tr>
                </thead>
                <tbody>
  {rows.map((row, dayIndex) => (
    <React.Fragment key={dayIndex}>
      <tr>
        <td style={{ borderBottom: '1px solid #ddd', padding: '10px', textAlign: 'left', fontSize: '13px' }}>
          <input type="checkbox" checked={row.checked} onChange={() => handleCheckboxChange(dayIndex)} style={{ marginRight: '10px' }} />
          {row.day}
        </td>
        <td style={{ borderBottom: '1px solid #ddd', padding: '10px' }}>
          {row.timings.map((timing, timingIndex) => (
            <select
              key={`from-${dayIndex}-${timingIndex}`}
              value={timing.from}
              onChange={(e) => handleInputChange(dayIndex, timingIndex, e, 'from')}
              style={{height:'33px',   width: '100%', padding: "5px", fontSize: '13px', borderRadius: '5px', border: '1px solid #bfbebd', marginBottom:'5px', }}
              disabled={!row.checked}
            >
              <option value="">Select Time</option>
              <TimeOptions />
            </select>
          ))}
        </td>
        <td style={{ borderBottom: '1px solid #ddd', padding: '10px' }}>
          {row.timings.map((timing, timingIndex) => (
            <select
              key={`until-${dayIndex}-${timingIndex}`}
              value={timing.until}
              onChange={(e) => handleInputChange(dayIndex, timingIndex, e, 'until')}
              style={{ height:'33px', width: '100%', padding: "5px", fontSize: '13px', borderRadius: '5px', border: '1px solid #bfbebd', marginBottom:'5px', }}
              disabled={!row.checked}
            >
              <option value="">Select Time</option>
              <TimeOptions />
            </select>
          ))}
        </td>
        <td style={{ borderBottom: '1px solid #ddd', padding: '10px', textAlign: 'center' }}>
          {row.timings.map((timing, timingIndex) => (
            <div key={timingIndex} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              {timingIndex === row.timings.length - 1 ? (
                
                <button
                  onClick={() => handleAddTimeSlot(dayIndex)}
                  style={{
                    display: 'flex',
                    
                    justifyContent: 'center',
                    alignItems: 'center',
                    margin: '0',
                    padding: '0',
                    backgroundColor: 'transparent',
                    border: '1px solid #002366',
                    color: '#002366',
                    height: '30px',
                    width: '45%',
                    borderRadius: '5px',
                    cursor: 'pointer',
                    height:'33px',
                    
                  }}
                >
                  <FaPlus />
                </button>
              ) : (
                
                <button
                  onClick={() => handleDeleteTimeSlot(dayIndex, timingIndex)}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    margin: '0',
                    padding: '0',
                    backgroundColor: 'transparent',
                    border: '1px solid red',
                    color: 'red',
                    height: '30px',
                    width: '45%',
                    borderRadius: '5px',
                    cursor: 'pointer',
                    height:'33px',
                    marginBottom:'5px',
                  }}
                >
                  <FaTrash />
                </button>
              )}
            </div>
          ))}
        </td>
      </tr>
    </React.Fragment>
  ))}
</tbody>
              </table>
    
              <div style={{ display: 'flex', justifyContent: 'center', width: '100%', marginBottom: '20px', flexDirection: 'column', alignItems: 'center' }}>
                <div style={{ textAlign: 'center', fontSize: '13px', color: 'red', margin: '10px 0px', height: '16px', width: '100%' }}>{error}</div>
                <button
                  onClick={handleUpdate}
                  style={{
                    background: 'linear-gradient(90deg, #002366, #0056b3)',
                    color: '#fff',
                    padding: '10px 20px',
                    border: 'none',
                    borderRadius: '5px',
                    cursor: 'pointer',
                    width: '30%',
                    height: '50px'
                  }}
                >
                  Update Schedule
                </button>
              </div>
            </div>
            </div>
            </div>
        </div>
    );
    
    
    
    
    
  };

  export default RatesandAvailabilityVetsandTechs;