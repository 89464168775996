import React from 'react';
import Background from '../../1_MediaAssets/MainPages/Blogs/BlogsBG.jpg';
import ContactSection from "./ContactSection.js";
import Navbar from '../../Navbars/NavbarMain/NavbarMain.js'

const Contact = () => {
  return (
    <> 
      <Navbar />
      <div style={{paddingTop: '50px',  backgroundImage: `url(${Background})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'bottom', backgroundSize: 'cover', backgroundAttachment: 'fixed', width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <ContactSection />
      </div>
    </>
  );
};

export default Contact;
