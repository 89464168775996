import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { db, auth } from '../../../firebase/index.js';
import { doc, getDoc, collection, addDoc, serverTimestamp, updateDoc, arrayUnion } from 'firebase/firestore';
import Cover from '../../1_MediaAssets/Homepage/PawTexture.png';
import CutePets from '../../Registrations&Login/CutePets.js';
import { NavLink } from 'react-router-dom';

const AppointmentDetails = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { DoctorID, DocType, Date, Time, PetConcerns, selectedPetId, moreDetails, State } = location.state || {};

    const [doctorDetails, setDoctorDetails] = useState(null);
    const [parentDetails, setParentDetails] = useState(null);
    const [petDetails, setPetDetails] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchDoctorDetails = async () => {
            const doctorRef = doc(db, 'Doctors', 'Veterinarian', 'Veterinarian', DoctorID);
            const doctorSnap = await getDoc(doctorRef);
            if (doctorSnap.exists()) {
                setDoctorDetails(doctorSnap.data());
            } else {
                console.error("Doctor document not found");
            }
        };

        const fetchParentAndPetDetails = async () => {
            const user = auth.currentUser;
            if (user) {
                const parentRef = doc(db, 'Parents', user.uid);
                const parentSnap = await getDoc(parentRef);
                if (parentSnap.exists()) {
                    const parentData = parentSnap.data();
                    setParentDetails(parentData);

                    const petRef = doc(collection(parentRef, 'pets'), selectedPetId);
                    const petSnap = await getDoc(petRef);
                    if (petSnap.exists()) {
                        setPetDetails(petSnap.data());
                    } else {
                        console.error("Pet document not found");
                    }
                } else {
                    console.error("Parent document not found");
                }
            }
        };

        const fetchData = async () => {
            setLoading(true);
            await fetchDoctorDetails();
            await fetchParentAndPetDetails();
            setLoading(false);
        };

        fetchData();
    }, [DoctorID, selectedPetId]);

    const handleConfirmBooking = async () => {
        if (doctorDetails && parentDetails && petDetails) {
            const user = auth.currentUser;
            const meetingLink = `https://rexvets.com/VideoCall/${user.uid.slice(0, 5)}${DoctorID.slice(0, 5)}${Date}${Time.replace(/\s/g, '').replace(/:/g, '')}`;
            const appointmentDetails = {
                AppointmentDate: Date,
                AppointmentTime: Time,
                AppointmentFee: '$0',
                DoctorEmail: doctorDetails.EmailAddress,
                DoctorName: `Dr. ${doctorDetails.FirstName} ${doctorDetails.LastName} ${doctorDetails.PostNominalLetters}`,
                DoctorRefID: DoctorID,
                MeetingLink: meetingLink,
                ParentEmail: parentDetails.EmailAddress,
                ParentName: parentDetails.Name,
                ParentRefID: user.uid,
                PetConcerns: PetConcerns,
                PetRefID: selectedPetId,
                MoreDetails: moreDetails,
                TimeStamp: serverTimestamp(),
                status: 'Upcoming',
                past: false,
                State: State
            };
            try {
                // Add the appointment to the Appointments collection
                const appointmentRef = await addDoc(collection(db, 'Appointments'), appointmentDetails);
                const appointmentId = appointmentRef.id;

                // Update the doctor's document to add the appointment ID to the Appointments array
                const doctorRef = doc(db, 'Doctors', 'Veterinarian', 'Veterinarian', DoctorID);
                await updateDoc(doctorRef, {
                    Appointments: arrayUnion(appointmentId)
                });

                // Update the parent's document to add the appointment ID to the Appointments array
                const parentRef = doc(db, 'Parents', user.uid);
                await updateDoc(parentRef, {
                    Appointments: arrayUnion(appointmentId)
                }).then(async () => {
                    await fetch('https://rexvetsemailserver.vercel.app/sendBookingConfirmation', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            doctorEmail: doctorDetails.EmailAddress,
                            doctorName: `Dr. ${doctorDetails.FirstName} ${doctorDetails.LastName}`,
                            parentEmail: parentDetails.EmailAddress,
                            parentName: parentDetails.Name,
                            petName: petDetails.Name,
                            appointmentDate: Date,
                            appointmentTime: Time,
                            meetingLink: meetingLink
                        })
                    }).then(response => { response.json() });
                }).then(() => {
                    // Navigate to the success page with the appointment details
                    navigate('/AppointmentsPetParents', { state: { ...appointmentDetails, appointmentId } });
                });
            } catch (error) {
                console.error("Error confirming booking:", error);
            }
        }
    };

    return (
        <div className="appointment-details-container" style={{ backgroundImage: `url('${Cover}')`, padding: '40px', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }}>
            <CutePets />
            {loading ? (
                <p style={{ textAlign: 'center', fontSize: '18px', fontWeight: 'bold', color: '#555' }}>Loading appointment details...</p>
            ) : (
                <div className="appointment-details-card" style={{ background: '#fff', padding: '20px', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }}>
                    <h3 className="appointment-title" style={{ textAlign: 'center', fontSize: '24px', fontWeight: 'bold', marginBottom: '20px' }}>Appointment Details</h3>
                    {parentDetails && (
                        <>
                            <p className="appointment-info"><strong>Parent Name:</strong> {parentDetails.Name}</p>
                            <p className="appointment-info"><strong>Parent Email:</strong> {parentDetails.EmailAddress}</p>
                        </>
                    )}

                    {doctorDetails && (
                        <>
                            <p className="appointment-info"><strong>Doctor Name:</strong> Dr. {doctorDetails.FirstName} {doctorDetails.LastName} {doctorDetails.PostNominalLetters}</p>
                            <p className="appointment-info"><strong>Doctor Type:</strong> {DocType}</p>
                        </>
                    )}

                    {petDetails && (
                        <>
                            <p className="appointment-info"><strong>Pet Name:</strong> {petDetails.Name}</p>
                            <p className="appointment-info"><strong>Species:</strong> {petDetails.Species}</p>
                            <p className="appointment-info"><strong>Gender:</strong> {petDetails.Gender}</p>
                        </>
                    )}

                    <p className="appointment-info"><strong>Appointment Date:</strong> {Date}</p>
                    <p className="appointment-info"><strong>Appointment Time:</strong> {Time}</p>

                    <p className="appointment-info"><strong>Pet Concerns:</strong>   {PetConcerns.map((concern, index) => (
                            <p key={index} className="pet-concern-item" style={{ marginBottom: '5px' }}>{concern}</p>
                        ))}</p>
                    
                      
                    

                    {moreDetails && (
                        <p className="appointment-info"><strong>More Details:</strong> {moreDetails}</p>
                    )}
                        <p className="appointment-info"><strong>State:</strong> {State}</p>
                    <button className="confirm-button" onClick={handleConfirmBooking} style={{ width: '100%', padding: '10px', borderRadius: '5px', background: '#0849c1', color: 'white', fontWeight: 'bold', marginBottom: '15px' }}>
                        Confirm Booking
                    </button>

                    <NavLink to='/DashboardPetParents' style={{ width: '100%' }}>
                        <button className="return-button" style={{ width: '100%', padding: '10px', borderRadius: '5px', background: '#ccc', color: '#333' }}>
                            Return
                        </button>
                    </NavLink>
                </div>
            )}
        </div>
    );
};

export default AppointmentDetails;
