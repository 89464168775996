import React, { useEffect, useState } from 'react';
import Navbar from '../../Navbars/NavbarMain/NavbarMain.js';
import VetCard from "./VetCard.js";
import { db } from '../../../firebase/index.js';
import { collection, getDocs, doc, getDoc } from 'firebase/firestore';
import DocPlaceholder from '../../1_MediaAssets/VetsandTechs/RegistrationVetsandTechs/DocIcon.png';
import Footer from '../../Navbars/Footer/index.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBone } from '@fortawesome/free-solid-svg-icons';
import LoaderLogo from '../../1_MediaAssets/BrandAssets/RexLoaderLogo2.png'

const FindAVet = () => {
  const [vets, setVets] = useState([]);
  const [loading, setLoading] = useState(true);

  const getCurrentDateTime = () => {
    const now = new Date();
    const currentDay = now.toLocaleDateString('en-US', { weekday: 'long' });
    const currentTime = now.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
    return { currentDay, currentTime };
  };

  const isLaterTime = (time1, time2) => {
    const [hours1, minutes1, period1] = time1.split(/[:\s]/);
    const [hours2, minutes2, period2] = time2.split(/[:\s]/);
    let totalMinutes1 = (parseInt(hours1) % 12 + (period1 === 'PM' ? 12 : 0)) * 60 + parseInt(minutes1);
    let totalMinutes2 = (parseInt(hours2) % 12 + (period2 === 'PM' ? 12 : 0)) * 60 + parseInt(minutes2);
    return totalMinutes1 > totalMinutes2;
  };

  const formatDate = (date) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(date).toLocaleDateString('en-US', options);
  };

  const findNextTwoTimings = (timingArray) => {
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    let slots = [];
    let todayIndex = daysOfWeek.indexOf(getCurrentDateTime().currentDay);
    const currentTime = getCurrentDateTime().currentTime;

    for (let i = 0; i < daysOfWeek.length && slots.length < 2; i++) {
      const dayIndex = (todayIndex + i) % 7;
      const dayName = daysOfWeek[dayIndex];
      const dayTimings = timingArray.find(day => day.day === dayName);

      if (dayTimings) {
        const validTimings = dayTimings.timings.filter(timing =>
          i > 0 || isLaterTime(timing.time, currentTime) // Skip current day
        );
        const date = new Date();
        date.setDate(date.getDate() + i);

        const dateFormatted = i === 0 ? '' : ` on ${formatDate(date)}`;
        const timingsWithDate = validTimings.map(timing => ({
          ...timing,
          displayTime: i === 0 ? `Today at ${timing.time}` : `${dateFormatted} at ${timing.time}`
        }));

        if (i === 0 && timingsWithDate.length > 0) {
          timingsWithDate.shift(); // Remove the first slot of today
        }

        slots = [...slots, ...timingsWithDate.slice(0, 2 - slots.length)];
      }
    }
    return slots;
  };

  useEffect(() => {
    const fetchTimeSlots = async (docId) => {
      const docRef = doc(db, 'Doctors', 'Veterinarian', 'Veterinarian', docId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const doctorData = docSnap.data();
        const timingArray = [];

        doctorData.Schedule.forEach(day => {
          const dayTimings = day.timings.flatMap(({ from, until }) => {
            const fromParts = from.split(' ');
            const toParts = until.split(' ');

            const fromTime = fromParts[0].split(':');
            const toTime = toParts[0].split(':');

            let fromHour = parseInt(fromTime[0]);
            let fromMinute = parseInt(fromTime[1]);
            let toHour = parseInt(toTime[0]);
            let toMinute = parseInt(toTime[1]);

            if (fromParts[1] === 'PM' && fromHour !== 12) {
              fromHour += 12;
            }
            if (toParts[1] === 'PM' && toHour !== 12) {
              toHour += 12;
            }

            const startTime = new Date(2022, 0, 1, fromHour, fromMinute);
            const endTime = new Date(2022, 0, 1, toHour, toMinute);

            const timings = [];

            while (startTime < endTime) {
              timings.push({
                date: day.day,
                time: startTime.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' }),
                price: '0'
              });
              startTime.setMinutes(startTime.getMinutes() + 30);
            }
            return timings;
          });

          timingArray.push({ day: day.day, timings: dayTimings });
        });

        const nextTwoSlots = findNextTwoTimings(timingArray);
        return nextTwoSlots;
      } else {
        console.log("No such document!");
        return [];
      }
    };

    const fetchVets = async () => {
      try {
        const veterinariansRef = collection(db, 'Doctors', 'Veterinarian', 'Veterinarian');
        const veterinariansSnapshot = await getDocs(veterinariansRef);

        const vetsData = [];

        const fetchAllVets = async (snapshot) => {
          const vetData = [];
          for (const doc of snapshot.docs) {
            const data = doc.data();
            if (data.Approved === 'yes') {
              const slots = await fetchTimeSlots(doc.id);
              vetData.push({
                id: doc.id,
                name: `${data.FirstName} ${data.LastName}`,
                title: data.DocType,
                image: data.profilePictureURL || DocPlaceholder,
                pnl: data.PostNominalLetters,
                rating: 'N/A',
                reviews: 'N/A',
                timeslots: slots
              });
            }
          }
          return vetData;
        };

        const veterinariansData = await fetchAllVets(veterinariansSnapshot);

        setVets(veterinariansData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data from Firestore:', error);
        setLoading(false);
      }
    };

    fetchVets();
  }, []);

  return (
    <div>
      {/* Navbar se carga primero */}
      <div className='navFindAVet'>
        <Navbar />
      </div>

      {/* El contenido restante se carga después con el spinner */}
      <div className="content-area">
        {loading ? (
          <div className="loading-overlay">
          <img src={LoaderLogo} alt="Loader Logo" className="rotate" style={{ height: '50px', width: '50px' }} />
        </div>
        ) : (
          <div className="vet-selection-container">
            <div className="vet-selection-content">
              <h2 className="vet-selection-title">Select a vet</h2>
              <p className="vet-selection-subtitle">For your online appointment</p>
            </div>

            <section className='FindAVet'>
              <div className="vet-grid">
                {vets.map(vet => (
                  <VetCard key={vet.id} vet={vet} />
                ))}
              </div>
            </section>
          </div>
        )}
      </div>

      <Footer />
    </div>
  );
}

export default FindAVet;
