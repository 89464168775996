import { provider } from '../../../firebase/index';
import { getAuth, createUserWithEmailAndPassword, signInWithPopup, signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";
import { doc, setDoc, serverTimestamp, getFirestore, getDoc } from 'firebase/firestore';
import { FaGoogle, FaUserAlt } from "react-icons/fa";
import { RxCross1 } from "react-icons/rx";
import { BsIncognito } from "react-icons/bs";
import CutePets from "../../Registrations&Login/CutePets";
import Texture from '../../1_MediaAssets/OtherBasics/Texture.png';
import Cover from '../../1_MediaAssets/VetsandTechs/RegistrationVetsandTechs/Cover2.png';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from '../../Navbars/NavbarMain/NavbarMain.js';

const DonatePage1 = () => { 
    // eslint-disable-next-line
    const [option, setOption] = useState('');
    const navigate = useNavigate();
    const auth = getAuth();
    const db = getFirestore();
    const [showPopUp, setShowPopUp] = useState(false);
    const [activeTab, setActiveTab] = useState('login');
    const [name, setName] = useState('');
    const [emailSignup, setEmailSignup] = useState('');
    const [phone, setPhone] = useState('');
    const [state, setState] = useState('');
    const [passwordSignup, setPasswordSignup] = useState('');
    const [confirmPasswordSignup, setConfirmPasswordSignup] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    useEffect(() => {
        const auth = getAuth();
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                navigate('/DonatePage2');
            } 
        });
        return () => unsubscribe();
    }, [navigate]);

    const handleClosePopUp = () => { setShowPopUp(false); };

    const handleShowPopUp = () => { setShowPopUp(true); };

    const handleTabSwitch = (tab) => { setActiveTab(tab); };

    const handleGoogleSignIn = async () => {
        try {
            const result = await signInWithPopup(auth, provider);
            const user = result.user;
            localStorage.setItem('userId', user.uid);
            const docRef = doc(db, "Parents", user.uid);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                setShowPopUp(false);
                navigate('/DonatePage2');
            } else {
                document.getElementById("messageLogin").innerHTML = "No such user! Contact admin support@rexvets.com";
            }
        } catch (error) {
            console.error("Error during Google sign-in:", error);
            document.getElementById("messageLogin").innerHTML = "Error during Google sign-in.";
        }
    };

    const handleGoogleSignUp = async () => {
        try {
            const result = await signInWithPopup(auth, provider);
            const user = result.user;
            const userData = { 
                Name: user.displayName || 'Anonymous',
                EmailAddress: user.email,
                PhoneNo: '',
                State: '',
                RegistrationTime: serverTimestamp(),
            };
            await setDoc(doc(db, `Parents`, user.uid), userData);
            sendWelcomeEmail(user.email, user.displayName);
            setShowPopUp(false);
            navigate('/DonatePage2');
        } catch (error) {
            document.getElementById("messageSignup").innerHTML = "Google Sign-In failed: " + error.message;
        }
    };

    function sendWelcomeEmail(email,name) {
        console.log("Sending welcome email for Google sign-up..."); // Log before sending email
        fetch("https://rexvetsemailserver.vercel.app/sendWelcomeEmailParent", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email: email,
                name: name || 'Anonymous',
            }),
        })
        .then(response => response.json())
        .then(data => {
            console.log("Email response:", data); // Log email response
        })
        .catch(emailError => {
            console.log("Error sending welcome email:", emailError); // Log email sending error
        });
    }

    const handleLoginSubmit = async (e) => {
        e.preventDefault();
        document.getElementById("buttoonLogin").innerHTML = "Loading...";
    
        if (!email || !password) {
            document.getElementById("messageLogin").innerHTML = "Fill in all fields!";
            document.getElementById("buttoonLogin").innerHTML = "Sign in";
            return;
        }
    
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;
    
            let userDocRef = doc(db, 'Parents', user.uid);
            let userDoc = await getDoc(userDocRef);
    
            if (!userDoc.exists()) {
                userDocRef = doc(db, "Doctors", "Veterinarian", "Veterinarian", user.uid);
                userDoc = await getDoc(userDocRef);
            }
    
            if (!userDoc.exists()) {
                userDocRef = doc(db, "Doctors", "Technician", "Technician", user.uid);
                userDoc = await getDoc(userDocRef);
            }
    
            if (userDoc.exists()) {
                setShowPopUp(false);
                navigate('/DonatePage2');
            } else {
                document.getElementById("messageLogin").innerHTML = 'No matching document found!';
            }
        } catch (err) {
            document.getElementById("messageLogin").innerHTML = 'Failed to login. Please check your email and password.';
            console.error('Login error:', err);
        } finally {
            document.getElementById("buttoonLogin").innerHTML = "Sign in";
        }
    };

    const handleSignupSubmit = async (e) => {
        e.preventDefault();

        if (!name || !emailSignup || !phone || !state || !passwordSignup || !confirmPasswordSignup) {
            document.getElementById("messageSignup").innerHTML = 'Please fill all fields.';
            return;
        }
        if (passwordSignup.length < 8) {
            document.getElementById("messageSignup").innerHTML = 'Password must be at least 8 characters long.';
            return;
        }
        if (passwordSignup !== confirmPasswordSignup) {
            document.getElementById("messageSignup").innerHTML = 'Passwords do not match.';
            return;
        }
        try {
            const userCredential = await createUserWithEmailAndPassword(auth, emailSignup, passwordSignup);
            const user = userCredential.user;

            await setDoc(doc(db, 'Parents', user.uid), {
                Name: name,
                EmailAddress: emailSignup,
                PhoneNo: phone,
                State: state,
                RegistrationTime: serverTimestamp(),
            });

            handleClosePopUp();
            navigate('/DonatePage2');
        } catch (err) {
            console.error('Error signing up:', err);
            document.getElementById("messageSignup").innerHTML = 'Error';
        }
    };

    return (
        <>
            <Navbar />
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundImage: `url('${Cover}')`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundAttachment: 'fixed', minHeight: '100vh'}}>
                <div className='RegistrationWhiteBox' style={{ backgroundImage: `url('${Texture}')`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', margin: '120px 0px'}}>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', padding: '30px', flexDirection: 'column' }}>
                        <h2 style={{ fontWeight: '900', color: '#002366', fontSize: '30px', textAlign: 'center', width: '100%' }}>Donate </h2>
                        <p style={{ fontWeight: '900', color: '#686869', textAlign: 'center', width: '100%' }}>Your generous contribution helps us provide essential veterinary care to pets in need. Every donation ensures that more animals receive the treatment and love they deserve.</p>
                        <p style={{ fontWeight: '900', color: '#686869', textAlign: 'center', width: '100%' }}>Together, we can make a difference in their lives.</p>
                        <CutePets />
                    </div>
    
                    <div style={{ width: '80%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column' }}>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                            <p style={{ margin: '0', padding: '0', fontWeight: '900' }}>Choose donation method</p>
                        </div>
    
                        <div className='optionBox'>
                            <div style={{ width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-end', padding: '0', margin: '0' }}>
                                    <div id='RegisteredDonation' className="content" onClick={() => { setOption('RegisteredDonation'); handleShowPopUp(); }}>
                                        <FaUserAlt style={{ fontSize: '40px', marginBottom: '0', color: '#002366' }} />
                                        <h5 style={{ color: 'black', marginTop: '15px', fontSize: '14px', fontWeight: '900' }}>Registered <br /> donation</h5>
                                    </div>
                                </div>
                            </div>
    
                            <div style={{ width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px'}}>
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-end', padding: '0', margin: '0' }}>
                                    <div id='AnonymousDonation' className="content" onClick={() => { setOption('AnonymousDonation'); navigate('/DonatePage2'); }}>
                                        <BsIncognito style={{ fontSize: '40px', marginBottom: '0', color: '#002366' }} />
                                        <h5 style={{ color: 'black', marginTop: '15px', fontSize: '14px', fontWeight: '900' }}>Anonymous <br /> donation</h5>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
    
                </div>
    
                {showPopUp && (
                    <div style={overlayStyle}>
                        <RxCross1 style={closeIconStyle} onClick={handleClosePopUp} />
                        <div style={formStyle}>
                            <div style={tabContainerStyle}>
                                <button
                                    style={activeTab === 'login' ? activeTabStyle : tabStyle}
                                    onClick={() => handleTabSwitch('login')}
                                >
                                    Sign in
                                </button>
                                <button
                                    style={activeTab === 'register' ? activeTabStyle : tabStyle}
                                    onClick={() => handleTabSwitch('register')}
                                >
                                    Register
                                </button>
                            </div>
                            {activeTab === 'login' && (
                                <div>
                                    <button onClick={handleGoogleSignIn} style={googleButtonStyle}>
                                        <FaGoogle style={googleIconStyle} /> Sign in with Google
                                    </button>
                                    <form onSubmit={handleLoginSubmit}>
                                        <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} style={inputStyle} />
                                        <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} style={inputStyle} />
                                        <p style={forgotPasswordStyle}>Forgot Password</p>
                                        <div id='messageLogin' style={errorStyle}></div>
                                        <button id='buttoonLogin' type="submit" style={buttonStyle}>Sign in</button>
                                    </form>
                                </div>
                            )}
                            {activeTab === 'register' && (
                                <div>
                                    <button onClick={handleGoogleSignUp} style={googleButtonStyle}>
                                        <FaGoogle style={googleIconStyle} /> Sign up with Google
                                    </button>
                                    <form onSubmit={handleSignupSubmit}>
                                        <input type="text" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} style={inputStyle} />
                                        <input type="email" placeholder="Email" value={emailSignup} onChange={(e) => setEmailSignup(e.target.value)} style={inputStyle} />
                                        <input type="number" placeholder="Phone Number" value={phone} onChange={(e) => setPhone(e.target.value)} style={inputStyle} />
                                        <input type="text" placeholder="State" value={state} onChange={(e) => setState(e.target.value)} style={inputStyle} />
                                        <input type="password" placeholder="Password" value={passwordSignup} onChange={(e) => setPasswordSignup(e.target.value)} style={inputStyle} />
                                        <input type="password" placeholder="Confirm Password" value={confirmPasswordSignup} onChange={(e) => setConfirmPasswordSignup(e.target.value)} style={inputStyle} />
                                        <div id='messageSignup' style={errorStyle}></div>
                                        <button id='buttonRegister' type="submit" style={buttonStyle}>Sign up</button>
                                    </form>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </>
    );
    
}



const overlayStyle = {
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.9)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: '1000000',
};

const closeIconStyle = {
    cursor: 'pointer',
    marginBottom: '10px',
    position: 'absolute',
    top: '10px',
    right: '10px',
    fontSize: '25px',
    fontWeight: '900',
    color: 'white',
};

const googleButtonStyle = {
    width: '100%',
    margin: '10px 0px',
    backgroundColor: '#4285F4',
    color: 'white',
};

const googleIconStyle = {
    margin: '0',
    padding: '0',
    fontSize: '20px',
    marginRight: '10px',
};

const forgotPasswordStyle = {
    marginTop: '0px',
    textDecoration: 'underline',
    color: 'gray',
    cursor: 'pointer',
    fontSize: '10px',
    marginBottom: '5px',
};


const tabContainerStyle = {
    display: 'flex',
    justifyContent: 'space-around',
    marginBottom: '20px',
};

const tabStyle = {
    flex: 1,
    padding: '10px',
    backgroundColor: '#f1f1f1',
    border: 'none',
    cursor: 'pointer',
    borderRadius: '8px 8px 0 0',
    textAlign: 'center',
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#002366',
};

const activeTabStyle = {
    ...tabStyle,
    color: '#fff',
    backgroundColor: '#002366',
    borderBottom: '2px solid #4285F4',
};

const formStyle = {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    width: '100%',
    maxWidth: '400px'
};

const inputStyle = {
    width: '100%',
    height: '35px',
    fontSize: '13px',
    padding: '10px',
    margin: '5px 0',
    borderRadius: '4px',
    border: '1px solid #ddd'
};

const buttonStyle = {
    width: '100%',
    padding: '10px',
    borderRadius: '4px',
    border: 'none',
    color: '#fff',
    fontSize: '13px',
    cursor: 'pointer',
    marginTop: '10px',
    height: '40px'
};
// eslint-disable-next-line
const errorStyle = {
    color: 'red',
    height: '13px',
    fontSize: '11px',
    width: '100%',
    textAlign: 'center',
    margin: '5px 0px'
};

export default DonatePage1