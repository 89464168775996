import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import TawkToScript from './TawkTo/TawkToScript.js'
// MAIN PAGES 
import Home from './components/MainPages/Home';
import PetParents from './components/MainPages/PetParents';
import VetsandTechs from './components/MainPages/VetsandTechs';
import About from './components/MainPages/About';
import Error from './components/MainPages/Error';
import Works from './components/MainPages/Works';
import WhatWeTreat from './components/MainPages/WhatWeTreat';
import Contact from './components/MainPages/Contact/index.js';
import Blog from './components/MainPages/Blogs/BlogDetails.js';
import BlogsData from './components/MainPages/Blogs/BlogsData.js';
import BlogsPage from './components/MainPages/Blogs/index.js';
import PrivacyPolicy from './components/MainPages/PolicyPages/PrivacyPolicy';
import TermsC from './components/MainPages/PolicyPages/TermsandConditions';
import FindAVet from './components/MainPages/FindAVet/1_index.js';
import DoctorProfilePublic from './components/MainPages/FindAVet/2_DoctorProfilePublic.js';
import AppointmentConfirmationPage from './components/MainPages/FindAVet/3_AppointmentConfirmationPage.js';
import AppointmentDetailsPage from './components/MainPages/FindAVet/4_AppointmentDetailsPage.js';
import GetAPrescription from './components/MainPages/GetAPrescription';
import Faq from './components/MainPages/Faq/index.js';
import Support from './components/MainPages/Support';
import Donate from './components/MainPages/Donate';

import DonatePage1 from './components/MainPages/Donate/Page1.js';
import DonatePage2 from './components/MainPages/Donate/Page2.js';
import DonationBox from './components/MainPages/Donate/DonationBox.js'
import DonationSuccess from './components/MainPages/Donate/DonationSuccess.js';
import OurMission from './components/MainPages/OurMission/index.js';
import FinancialTransparency from './components/MainPages/FinancialTransparency/index.js';
import SupportUs from './components/MainPages/SupportUs/index.js';
// PAYMENT PAGES 
import StripeContext from './stripe/StripeContext.js';
// REGISTRATION PET PARENTS 
import RegistrationPetParentsPage1 from './components/Registrations&Login/Registrations/RegistrationPetParents/Page1.js';
import RegistrationPetParentsPage2 from './components/Registrations&Login/Registrations/RegistrationPetParents/Page2.js';
// REGISTRATION VETS AND TECHS 
import RegistrationVetsandTechsPage1 from './components/Registrations&Login/Registrations/RegistrationVetsandTechs/Page1.js';
import RegistrationVetsandTechsPage2 from './components/Registrations&Login/Registrations/RegistrationVetsandTechs/Page2.js';
import RegistrationVetsandTechsPage3 from './components/Registrations&Login/Registrations/RegistrationVetsandTechs/Page3.js';
import RegistrationVetsandTechsPage4 from './components/Registrations&Login/Registrations/RegistrationVetsandTechs/Page4.js';
// LOGINS 
import LoginDoctors from './components/Registrations&Login/Logins/LoginDoctors/index.js';
import ForgotPasswordDoctors from './components/Registrations&Login/Logins/LoginDoctors/ForgotPasswordDoctors.js';
import LoginPetParents from './components/Registrations&Login/Logins/LoginPetParents/index.js';
import ForgotPasswordPetParents from './components/Registrations&Login/Logins/LoginPetParents/ForgotPasswordPetParents.js';
// PET PARENTS DASHBOARD 
import DashboardPetParents from './components/Dashboards/DashboardPetParents/Dashboard';
import AppointmentsPetParents from './components/Dashboards/DashboardPetParents/Appointments';
import NotificationsPetParents from './components/Dashboards/DashboardPetParents/Notifications';
import PersonalInformationPetParents from './components/Dashboards/DashboardPetParents/Account/PersonalInformation.js';
import PetsPetParents from './components/Dashboards/DashboardPetParents/Pets';
import HelpPetParents from './components/Dashboards/DashboardPetParents/Help';
import AppointmentRoomPetParent from './components/Dashboards/DashboardPetParents/Appointments/AppointmentRoomPetParent.js';  
import AccountMenuPetParents from './components/Dashboards/DashboardPetParents/Account/AccountMenu.js';   
import LoginandSecurityP from './components/Dashboards/DashboardPetParents/Account/LoginandSecurity.js';   
import PaymentsReportingPetParents from './components/Dashboards/DashboardPetParents/Account/PaymentsReporting.js';                                
// VETS AND TECHS DASHBOARD 
import DashboardVetsandTechs from './components/Dashboards/DashboardVetsandTechs/Dashboard';
import AppointmentsVetsandTechs from './components/Dashboards/DashboardVetsandTechs/Appointments';
import AppointmentRoomVetsandTechs from './components/Dashboards/DashboardVetsandTechs/Appointments/AppointmentRoomVetsandTechs.js';
import RatesandAvailabilityVetsandTechs from './components/Dashboards/DashboardVetsandTechs/RatesandAvailability';
import NotificationsVetsandTechs from './components/Dashboards/DashboardVetsandTechs/Notifications';
import PersonalInformationVetsandTechs from './components/Dashboards/DashboardVetsandTechs/Account/PersonalInformation.js'; 
import AccountMenuVetsandTechs from './components/Dashboards/DashboardVetsandTechs/Account/AccountMenu.js'; 
import AreasofInterest from './components/Dashboards/DashboardVetsandTechs/Account/AreasofInterest.js';  
import LoginandSecurity from './components/Dashboards/DashboardVetsandTechs/Account/LoginandSecurity.js';
import SpeciesTreated from './components/Dashboards/DashboardVetsandTechs/Account/SpeciesTreated.js'; 
import PaymentsReportingVetsandTechs from './components/Dashboards/DashboardVetsandTechs/Account/PaymentsReporting.js'; 
import HelpVetsandTechs from './components/Dashboards/DashboardVetsandTechs/Help'; 
import VideoCall from './components/VideoCall/index.js'
import ScrollToTop from './components/1_MediaAssets/Styles/ScrollToTop.jsx';
import NonProfitPage from './components/MainPages/NonProfitStatus/index.js';

const DonationCheckout = () => (
  <StripeContext>
    <DonationBox />
  </StripeContext>
);

function App() {

  const location = useLocation();


  return (
    <>
      <ScrollToTop />
      {/* Conditionally render TawkToScript only if the path is not the VideoCall page */}
      {!location.pathname.startsWith('/VideoCall') && <TawkToScript />}
      <Routes>
        {/* MAIN PAGES */}
        <Route exact path="/" element={<Home />} />
        <Route exact path="/PetParents" element={<PetParents />} />
        <Route exact path="/VetsandTechs" element={<VetsandTechs />} />
        <Route exact path="/About" element={<About />} />
        <Route exact path="/Works" element={<Works />} />
        <Route exact path="/Contact" element={<Contact />} />
        <Route exact path="/Faq" element={<Faq />} />
        <Route exact path="/FindAVet" element={<FindAVet />} />
        <Route exact path="/DoctorProfilePublic" element={<DoctorProfilePublic />} />
        <Route exact path="/AppointmentConfirmationPage" element={<AppointmentConfirmationPage />} />
        <Route exact path="/AppointmentDetailsPage" element={<AppointmentDetailsPage />} />
        <Route path="/Blogs" element={<BlogsPage blogs={BlogsData} />} /><Route path="/blog/:slug" element={<Blog />} />
        <Route exact path="/WhatWeTreat" element={<WhatWeTreat />} />
        <Route exact path="/PrivacyPolicy" element={<PrivacyPolicy />} />
        <Route exact path="/NonProfitPage" element={<NonProfitPage />} />
        <Route exact path="/TermsandConditions" element={<TermsC />} />
        <Route exact path="*" element={<Error />} />
        <Route exact path="/Support" element={<Support />} /> 
        <Route exact path="/Donate" element={<Donate />} /> <Route exact path="/DonatePage1" element={<DonatePage1 />} /><Route exact path="/DonatePage2" element={<DonatePage2 />} /> <Route exact path="/DonationSuccess" element={<DonationSuccess />} /> 
        <Route exact path="/GetAPrescription" element={<GetAPrescription />} /> 
        <Route exact path="/OurMission" element={<OurMission />} /> 
        <Route exact path="/FinancialTransparency" element={<FinancialTransparency />} />
        <Route exact path="/SupportUs" element={<SupportUs />} /> 
        {/* REGISTRATION PET PARENTS */}
        <Route exact path="/RegistrationPetParentsPage1" element={<RegistrationPetParentsPage1 />} />
        <Route exact path="/RegistrationPetParentsPage2" element={<RegistrationPetParentsPage2 />} />
        {/* REGISTRATION VETS AND TECHS */}
        <Route exact path="/RegistrationVetsandTechsPage1" element={<RegistrationVetsandTechsPage1 />} />
        <Route exact path="/RegistrationVetsandTechsPage2" element={<RegistrationVetsandTechsPage2 />} />
        <Route exact path="/RegistrationVetsandTechsPage3" element={<RegistrationVetsandTechsPage3 />} />
        <Route exact path="/RegistrationVetsandTechsPage4" element={<RegistrationVetsandTechsPage4 />} />
        {/* LOGINS */}
        <Route exact path="/LoginDoctors" element={<LoginDoctors />} />
        <Route exact path="/ForgotPasswordDoctors" element={<ForgotPasswordDoctors />} />  
        <Route exact path="/LoginPetParents" element={<LoginPetParents />} />
        <Route exact path="/ForgotPasswordPetParents" element={<ForgotPasswordPetParents />} />
        {/* PET PARENTS DASHBOARD */}
        <Route exact path="/DashboardPetParents" element={<DashboardPetParents />} />
        <Route exact path="/AppointmentsPetParents" element={<AppointmentsPetParents />} />
        <Route exact path="/AppointmentRoomPetParent/:id" element={<AppointmentRoomPetParent />} />
        <Route exact path="/NotificationsPetParents" element={<NotificationsPetParents />} />
        <Route exact path="/HelpPetParents" element={<HelpPetParents />} />
        <Route exact path="/VideoCall/:roomId" element={<VideoCall />} />
        <Route exact path="/AccountMenuPetParents" element={<AccountMenuPetParents />} />
        <Route exact path="/AccountMenuPetParents/PersonalInformationPetParents" element={<PersonalInformationPetParents />} />
        <Route exact path="/PetsPetParents" element={<PetsPetParents />} />
        <Route exact path="/AccountMenuPetParents/LoginandSecurity" element={<LoginandSecurityP />} />
        <Route exact path="/AccountMenuPetParents/PaymentsReportingPetParents" element={<PaymentsReportingPetParents />} />
        {/* VETS AND TECHS DASHBOARD */}
        <Route exact path="/DashboardVetsandTechs" element={<DashboardVetsandTechs />} />
        <Route exact path="/AppointmentsVetsandTechs" element={<AppointmentsVetsandTechs />} />
        <Route exact path="/AppointmentRoomVetsandTechs/:id" element={<AppointmentRoomVetsandTechs />} />
        <Route exact path="/RatesandAvailabilityVetsandTechs" element={<RatesandAvailabilityVetsandTechs />} />
        <Route exact path="/NotificationsVetsandTechs" element={<NotificationsVetsandTechs />} />
        <Route exact path="/HelpVetsandTechs" element={<HelpVetsandTechs />} />
        <Route exact path="/AccountMenuVetsandTechs" element={<AccountMenuVetsandTechs />} />
        <Route exact path="/AccountMenuVetsandTechs/PersonalInformationVetsandTechs" element={<PersonalInformationVetsandTechs />} />
        <Route exact path="/AccountMenuVetsandTechs/AreasofInterest" element={<AreasofInterest />} />
        <Route exact path="/AccountMenuVetsandTechs/SpeciesTreated" element={<SpeciesTreated />} />
        <Route exact path="/AccountMenuVetsandTechs/LoginandSecurity" element={<LoginandSecurity />} />
        <Route exact path="/AccountMenuVetsandTechs/PaymentsReportingVetsandTechs" element={<PaymentsReportingVetsandTechs />} />
      </Routes>
    </>
  );
} 

export default App;
