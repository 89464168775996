
import LoadingScreen from '../../../LoadingScreen/index.js';
import React, { useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged } from "firebase/auth";
// eslint-disable-next-line
import { doc, getDocs, getFirestore, query, collection, where, updateDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "../../../../firebase";
import NavbarVetsandTechsDashboard from '../../../Navbars/NavbarVetsandTechsDashboard/index';
import { MdAccountCircle } from "react-icons/md";

const AccountVetsandTechs = () => {
    const [userData, setUserData] = useState({
        FirstName: '',
        LastName: '',
        EmailAddress: '',
        PhoneNo: '',
        City: '',
        State: '',
        Bio: '',
        PostNominalLetters: '',
        profilePictureURL: ''
    });
    const [isEditing, setIsEditing] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [previewURL, setPreviewURL] = useState('');
    const [loading, setLoading] = useState(true);
    // eslint-disable-next-line
    const [errorMessage, setErrorMessage] = useState('');
    const db = getFirestore();
    const auth = getAuth();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                try {
                    const userId = user.uid;
                    console.log(userId, "userId");
                    const q1 = query(collection(db, "Doctors/Technician/Technician"), where("uid", "==", userId));
                    const q2 = query(collection(db, "Doctors/Veterinarian/Veterinarian"), where("uid", "==", userId));
    
                    let querySnapshot = await getDocs(q1);
                    if (querySnapshot.empty) {
                        querySnapshot = await getDocs(q2);
                    }
    
                    if (!querySnapshot.empty) {
                        const userDoc = querySnapshot.docs[0];
                        // eslint-disable-next-line
                        const docId = userDoc.id;
                        setUserData(userDoc.data());
                        setPreviewURL(userDoc.data().profilePictureURL || '');
                        setLoading(false);
                    } else {
                        console.error('No such document!');
                        setErrorMessage('No such document!');
                        setLoading(false);
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                    setErrorMessage('Error fetching user data');
                    setLoading(false);
                }
            } else {
                setErrorMessage('User not authenticated');
                setLoading(false);
            }
        });
    
        return () => unsubscribe();
    }, [auth, db]);
    

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUserData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file && file.size <= 200000) {
            setSelectedFile(file);
            setPreviewURL(URL.createObjectURL(file));
        } else {
            alert("Image size should be less than 200kB");
        }
    };

    const handleSave = async () => {
        setErrorMessage('');
        document.getElementById("Buttony").innerHTML = "Loading...";
        const user = auth.currentUser;
    
        if (user) {
            try {
                const userId = user.uid;
                const q1 = query(collection(db, "Doctors/Technician/Technician"), where("uid", "==", userId));
                const q2 = query(collection(db, "Doctors/Veterinarian/Veterinarian"), where("uid", "==", userId));
    
                let querySnapshot = await getDocs(q1);
                if (querySnapshot.empty) {
                    querySnapshot = await getDocs(q2);
                }
    
                if (!querySnapshot.empty) {
                    const userDoc = querySnapshot.docs[0];
                    const docRef = userDoc.ref;
                    const docId = userDoc.id;
    
                    if (selectedFile) {
                        const fileExtension = selectedFile.name.split('.').pop();
                        const profilePictureRef = ref(storage, `Doctors/${userDoc.data().DocType}/${docId}/profilePicture.${fileExtension}`);
                        await uploadBytes(profilePictureRef, selectedFile);
                        const profilePictureURL = await getDownloadURL(profilePictureRef);
    
                        await updateDoc(docRef, {
                            ...userData,
                            profilePictureURL
                        });
                    } else {
                        await updateDoc(docRef, {
                            ...userData
                        });
                    }
    
                    setErrorMessage("Changes saved successfully!");
                    setIsEditing(false);
                }
            } catch (error) {
                console.error('Error updating profile:', error);
                setErrorMessage('Error updating profile');
                document.getElementById("Buttony").innerHTML = "Save";
            }
        }
    };
    
    if (loading) {
        return <LoadingScreen />;
    }

    return (
        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100vw', height: '100%', background: '#f5f5f5' }}>
            <div className='dashboardmain' style={{ paddingBottom: '50px' }}>
                <NavbarVetsandTechsDashboard />
                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'column', background: '#fff', borderRadius: '10px', width: '90%', marginTop: '50px', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)' }}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column', width: '100%', padding: '15px', borderBottom: '1px solid #dedcd7' }}>
                        <h4 style={{ padding: '0', margin: '0', display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: '900' }}>
                            <MdAccountCircle style={{ fontSize: '30px', margin: '0px 10px 0px 5px', color: '#0849c1' }} />
                            Personal Information
                        </h4>
                    </div>
                    {previewURL && (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
                            <img src={previewURL} alt="Profile" style={{ width: '200px', height: '200px', borderRadius: '50%', objectFit: 'cover' }} />
                        </div>
                    )}
                    <div style={{ width: '90%', marginTop: '20px' }}>
                        <form style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                            <label style={{ marginTop: '10px' }}>
                                Profile Picture:
                                <input type="file" accept="image/*" onChange={handleImageChange} disabled={!isEditing} style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ddd' }} />
                            </label>
                            <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
                                <label style={{ flex: '1 1 45%' }}>
                                    First Name:
                                    <input type="text" name="FirstName" value={userData.FirstName} onChange={handleInputChange} disabled={!isEditing} style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ddd' }} />
                                </label>
                                <label style={{ flex: '1 1 45%' }}>
                                    Last Name:
                                    <input type="text" name="LastName" value={userData.LastName} onChange={handleInputChange} disabled={!isEditing} style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ddd' }} />
                                </label>
                            </div>
                            <label>
                                Email Address:
                                <input type="email" name="EmailAddress" value={userData.EmailAddress} disabled style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ddd', backgroundColor: '#f0f0f0' }} />
                            </label>
                            <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
                                <label style={{ flex: '1 1 45%' }}>
                                    Phone Number:
                                    <input type="tel" name="PhoneNo" value={userData.PhoneNo} onChange={handleInputChange} disabled={!isEditing} style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ddd' }} />
                                </label>
                                <label style={{ flex: '1 1 45%' }}>
                                    City:
                                    <input type="text" name="City" value={userData.City} onChange={handleInputChange} disabled={!isEditing} style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ddd' }} />
                                </label>
                            </div>
                            <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
                                <label style={{ flex: '1 1 45%' }}>
                                    State:
                                    <input type="text" name="State" value={userData.State} onChange={handleInputChange} disabled={!isEditing} style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ddd' }} />
                                </label>
                                <label style={{ flex: '1 1 45%' }}>
                                    Post Nominal Letters:
                                    <input type="text" name="PostNominalLetters" value={userData.PostNominalLetters} onChange={handleInputChange} disabled={!isEditing} style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ddd' }} />
                                </label>
                            </div>
                            <label>
                                Bio:
                                <textarea name="Bio" value={userData.Bio} onChange={handleInputChange} disabled={!isEditing} style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ddd', resize: 'vertical', minHeight: '100px' }} />
                            </label>


                            <div id="error" style={{ color: 'red', textAlign: 'center', marginTop: '0px', fontSize: '13px', height: '15px'}}></div>

                            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '5px', marginBottom: '40px' }}>
                                <button type="button" onClick={() => setIsEditing(!isEditing)} style={{ padding: '10px 15px', borderRadius: '5px', backgroundColor: isEditing ? '#f00' : '#002366', color: '#fff', cursor: 'pointer', width: '100%' }}>
                                    {isEditing ? 'Cancel' : 'Edit Profile'}
                                </button>
                                {isEditing && (
                                    <button id="Buttony" type="button" onClick={handleSave} style={{ padding: '10px 15px', borderRadius: '5px', border: '1px solid #0849c1', cursor: 'pointer', marginLeft: '10px', width: '100%' }}>
                                        Save
                                    </button>
                                )}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
    
}

export default AccountVetsandTechs;
