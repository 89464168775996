import LoadingScreen from '../../../LoadingScreen/index.js';
import React, { useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { collection, getDocs, getFirestore, query, where, updateDoc } from "firebase/firestore";
import NavbarVetsandTechsDashboard from '../../../Navbars/NavbarVetsandTechsDashboard';

const InterestsSelection = () => {
    const [interests, setInterests] = useState([]);
    const [vetData, setVetData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [saveLoading, setSaveLoading] = useState(false);
    const [message, setMessage] = useState('');
    const db = getFirestore();
    const auth = getAuth();

    const options = [
        "Anesthesia", "Animal Welfare", "Behavior", "Immunology (Infectious Diseases)", "Dentistry",
        "Dermatology", "Emergency Medicine", "Endocrinology", "Cardiology", "Neurology", "Oncology",
        "Parasitology", "Laboratory Animal Medicine", "Microbiology", "Radiology", "Nutrition",
        "Ophthalmology", "Pathology", "Pharmacology", "Preventive Medicine", "Sports Medicine",
        "Surgery - Soft Tissue", "Surgery - Orthopedics", "Surgery - Ophthalmology", "Surgery - Neurology",
        "Theriogenology (Obstetrics & Reproduction)", "Toxicology", "Alternative Medicine", "Forensic Medicine (Judicial)",
        "End of life/Hospice care"
    ];

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                try {
                    const userId = user.uid;
                    const q1 = query(collection(db, "Doctors/Technician/Technician"), where("uid", "==", userId));
                    const q2 = query(collection(db, "Doctors/Veterinarian/Veterinarian"), where("uid", "==", userId));

                    let querySnapshot = await getDocs(q1);
                    if (querySnapshot.empty) {
                        querySnapshot = await getDocs(q2);
                    }

                    if (!querySnapshot.empty) {
                        const vetDoc = querySnapshot.docs[0];
                        setVetData(vetDoc); 
                        setInterests(vetDoc.data().Interests || []);
                        setLoading(false);
                    } else {
                        console.error('No such document!');
                        setLoading(false);
                    }
                } catch (error) {
                    console.error('Error fetching vet data:', error);
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        });

        return () => unsubscribe();
    }, [auth, db]);

    const handleOptionClick = (option) => {
        setInterests(prevInterests => {
            const isSelected = prevInterests.some(interest => interest.type === option);
            if (isSelected) {
                return prevInterests.filter(interest => interest.type !== option);
            } else {
                return [...prevInterests, { type: option, checked: true }];
            }
        });
    };

    const handleSave = async () => {
        setSaveLoading(true);
        setMessage('');
        if (vetData) {
            try {
                const docRef = vetData.ref;
                await updateDoc(docRef, {
                    Interests: interests
                });
                setMessage('Interests updated successfully!');
            } catch (error) {
                console.error('Error updating interests:', error);
                setMessage('Interests not updated, try again later or contact support@rexvets.com');
            } finally {
                setSaveLoading(false);
                setTimeout(() => setMessage(''), 4000);
            }
        }
    };

    const handleClearSelection = () => {
        setInterests([]);
    };

    if (loading) {
        return <LoadingScreen />;
    }

    return (
        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100vw', height: '100%', background: '#f5f5f5' }}>
            <div className='dashboardmain' style={{ paddingBottom: '50px' }}>
                <NavbarVetsandTechsDashboard />
                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'column', background: '#fff', borderRadius: '10px', width: '90%', marginTop: '50px', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', padding: '15px', borderBottom: '1px solid #dedcd7' }}>
                        <h4 style={{ padding: '0', margin: '0', fontWeight: '900' }}>
                            Choose Your Areas of Interest
                        </h4>
                        <button onClick={handleClearSelection} style={{ padding: '5px 10px', borderRadius: '5px', backgroundColor: '#FF6347', color: '#fff', cursor: 'pointer', fontSize: '12px' }}>
                            Clear Selection
                        </button>
                    </div>
                    <div className='YourPetsDiv' style={{ justifyContent: 'flex-start' }}>
                        <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', width: '100%', margin: '0', padding: '0' }}>
                            <p style={{ fontSize: '14px' }}>Choose all that apply:</p>
                        </div>
                        {options.map((option, index) => (
                            <div
                                key={index}
                                className="ConcernDiv"
                                style={{
                                    background: interests.some(interest => interest.type === option && interest.checked) ? "#FFE699" : "#edeef0"
                                }}
                                onClick={() => handleOptionClick(option)}
                            >
                                <p style={{ color: 'black', margin: '0', padding: '0', fontSize: '14px' }}>{option}</p>
                            </div>
                        ))}
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '0px', marginBottom: '20px', width: '100%', padding: '15px', flexDirection: 'column' }}>
                        <div style={{ height: '20px', marginBottom: '10px' }}>
                            {message && (
                                <div style={{ color: message.includes('successfully') ? 'green' : 'red', fontSize: '10px', textAlign: 'center' }}>
                                    {message}
                                </div>
                            )}
                        </div>
                        <button 
                            type="button" 
                            onClick={handleSave} 
                            style={{ padding: '10px 20px', borderRadius: '5px', cursor: 'pointer', width: '100%' }}>
                            {saveLoading ? 'Loading...' : 'Update'}
                        </button>
                    </div>
                    
                </div>
            </div>
        </div>
    );
};

export default InterestsSelection;
