import { useEffect } from 'react';
import NavbarPetParentsDashboard from '../../../Navbars/NavbarPetParentsDashboard/index.js'
import { MdAccountCircle } from "react-icons/md";
// eslint-disable-next-line
import { doc, getDoc, query, where, collection, getFirestore, addDoc, getDocs } from "firebase/firestore";
import { useState } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import CatDP from '../../../1_MediaAssets/OtherBasics/cat.jpg'; 
import { MdArrowRightAlt } from "react-icons/md";
const NotificationsPetParents = () => { 

    const [notificationData, setNotificationData] = useState([]);
    const [allNotifications, setAllNotifications] = useState([]);


    useEffect(() => {

        const fetchNotificationData = async () => {
        const db = getFirestore();
        const auth = getAuth();
        onAuthStateChanged(auth, (user) => {
        if (!user) {
            window.location.href = '/';
        }
        });
        const userId = localStorage.getItem('userId');
        console.log(userId);

        const docRef = doc(db, "Parents", userId);
        const notifiactionCollection = collection(docRef, "Notifications");
        const querySnapshot = await getDocs(notifiactionCollection);
        const notificationArray = querySnapshot.docs.map(doc => ({
            ...doc.data(),
            id: doc.id
        }));
        setNotificationData(notificationArray);
    
    
        //   const q = query(collection(db, "Parents"));

            // eslint-disable-next-line 

        };
    
        fetchNotificationData();
        // eslint-disable-next-line
        }, []);

        useEffect(() => {
            async function fetchAllNotifications() {
                const allNotifications = await Promise.all(
                    notificationData.map(async (notification) => {
                        const bookingDocRef = notification.docRef;
                        if (bookingDocRef) {
                            const bookingDocSnap = await getDoc(bookingDocRef);
                            if (bookingDocSnap.exists()) {
                                const bookingDocData = bookingDocSnap.data();
                                // Assuming there's a field named 'bookingDoc' in the booking document
                                const nestedBookingDocRef = bookingDocData.bookingDoc;
                                if (nestedBookingDocRef) {
                                    const nestedDocSnap = await getDoc(nestedBookingDocRef);
                                    if (nestedDocSnap.exists()) {
                                        return {...nestedDocSnap.data(),notificationType:notification.notificationType,notificationID:notification.id,notificationMessage:notification.notificationMessage};
                                    } else {
                                        console.log("Nested document does not exist!");
                                        return null;
                                    }
                                } else {
                                    console.log("Field 'bookingDoc' not found in nested document!");
                                    return null;
                                }
                            } else {
                                console.log("Booking document does not exist!");
                                return null;
                            }
                        } else {
                            console.log("Booking document reference is undefined!");
                            return null;
                        }
                    })
                );
        
                setAllNotifications(allNotifications.filter(Boolean)); // Remove null values
                console.log(allNotifications, 'lll');
            }
        
            fetchAllNotifications();
        }, [notificationData]);
        


    return (
        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100vw', height: '100vh' }}>
            <div className='dashboardmain'>
                <NavbarPetParentsDashboard />

                <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'column', background: '#fff', borderRadius: '10px', width: '90%', marginTop: '50px', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}}>
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column', width: '100%', padding: '15px', borderBottom: '1px solid #dedcd7' }}>
                        <h4 style={{padding: '0', margin: '0', display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: '900'}}>
                            <MdAccountCircle style={{fontSize: '30px', margin: '0px 10px 0px 5px', color: '#0849c1'}}/>
                            Account Settings
                        </h4>
                    </div>

                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', flexDirection: 'column'}}>
                        {allNotifications.map((notification, index) => (
                            // <NavLink key={index} to="/AppointmentsVetsandTechs/AppointmentCode" style={{width: '100%', textDecoration: 'none'}}>
                                <div className='PatientDiv'>
                                    <div className='SubPatientDiv'>
                                        <img className='DPhider' src={notification.profilePictureURL} alt='PetParentDP' style={{height: '40px', width: '40px', borderRadius: '100px', marginLeft: '2vw'}}/>
                                        <p style={{fontSize: '13px'}}>{notification.FirstName}</p>
                                        <img className='DPhider' src={CatDP} alt='CatDP' style={{height: '40px', width: '40px', borderRadius: '100px', marginLeft: '2vw'}}/>
                                        <p style={{fontSize: '13px'}}>{notification.notificationMessage}</p> 
                                    </div>
                                    <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
                                        <p style={{fontSize: '13px', marginLeft: '2vw'}}>Jan 21, 2023<br></br> <span style={{fontSize: '12px', color: 'grey'}}>04:30 PM - 05:30 PM</span></p>
                                    </div>
                                    <MdArrowRightAlt style={{fontSize: '80px', color: '#8CC084', marginRight: '2vw'}} />
                                </div>
                            // </NavLink>
                        ))}
                    </div>

                </div>
            </div>
        </div>
    )
}

export default NotificationsPetParents 