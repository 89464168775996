import { NavLink } from "react-router-dom";
import TextAnim from '../../1_MediaAssets/Styles/FadeAnimation.jsx';
import Cover from '../../1_MediaAssets/MainPages/VetsandTechsCover.jpg';
import bgVets from '../../1_MediaAssets/VetsandTechs/image2vets.png';
import bgVets1 from '../../1_MediaAssets/VetsandTechs/image1vets.png';
import bg1 from '../../1_MediaAssets/VetsandTechs/image1.png';
import bg2 from '../../1_MediaAssets/VetsandTechs/image2.png';
import bg3 from '../../1_MediaAssets/VetsandTechs/image3.png';
import Navbar from '../../Navbars/NavbarMain/NavbarMain.js';
import Footer from '../../Navbars/Footer/index.js';
import mobile from '../../1_MediaAssets/Homepage/mobilerex.png';
import React, { useState, useEffect } from 'react';

import LogoWhite from '../../1_MediaAssets/Homepage/LogoR.png';
const VetsandTechs = () => {  

    const [currentSlide, setCurrentSlide] = useState(0);

    const slides = [
        "Slide 1: Your Veterinary Telehealth Solution. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent venenatis metus at tortor pulvinar varius.",
        "Slide 2: Efficient and Reliable Service. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent venenatis metus at tortor pulvinar varius.",
        "Slide 3: Connecting Vets and Pets. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent venenatis metus at tortor pulvinar varius."
    ];

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
        }, 3000); // Cambia de slide cada 3 segundos

        return () => clearInterval(interval);
    }, [slides.length]);
    const benefits = [
      {
        feature: "Dedicated Service Page",
        description: "Unique profile and URL to promote your virtual practice",
        rexVets: true,
        others: false
      },
      {
        feature: "Promotion on RexVets.com",
        description: "Promotion to new clients seeking help in RexVets's marketplace",
        rexVets: true,
        others: false
      },
      {
        feature: "Online Booking",
        description: "Clients can easily find you, and can book one or multiple visits",
        rexVets: true,
        others: false
      },
      {
        feature: "Integrated Schedule",
        description: "Integrate with your iCal or Gcal, and pause your availability whenever you need to with one button",
        rexVets: true,
        others: false
      },
      {
        feature: "Helpful Reminders",
        description: "Never miss a call, with pre-appointment SMS & email alerts",
        rexVets: true,
        others: false
      },

      {
        feature: "Video Calls",
        description: "Secure, private video calls with in-app features – unlike typical video platforms such as Google Meet, Zoom, FaceTime & Skype",
        rexVets: true,
        others: false
      },
      {
        feature: "In-app messaging",
        description: "1:1 messaging with clients 24 hours post appointment",
        rexVets: true,
        others: false
      },
      {
        feature: "RexVets Prescribing",
        description: "Freeform and pharmacy-integrated options",
        rexVets: true,
        others: false
      },
      {
        feature: "Client Feedback & Reviews",
        description: "Capture regular feedback from clients",
        rexVets: true,
        others: false
      }
    ];

    return (
        <>
            <Navbar />
            <div className="ForVets-app">
        <div className="ForVets-hero">
          <div className="ForVets-content">
          <TextAnim Body={
            <h1>Manage your schedule with <span className="ForVets-highlight">Rex Vets</span> online platform.</h1> }/>
            <TextAnim Body={<p>Rex Vets provides a comprehensive solution for your veterinary telehealth and telemedicine needs.</p> }/>
       
            <div className="ForVets-buttons">
            <NavLink  to="/RegistrationVetsandTechsPage1" className="ForVets-create-account">Create an account</NavLink>
            <NavLink  to="/LoginDoctors" className="ForVets-login">Login</NavLink>
            </div>
          </div>
        </div>
      </div>


<div className="fatherVP">
      <div className="vp-container">
        <div className="vp-header">
          <h1 className="vp-title">Expand your virtual practice.</h1>
          <p className="vp-subtitle">Rex Vets provides tools to enhance your practice.</p>
          <NavLink  to="/RegistrationVetsandTechsPage1"  className="vp-create-account-button">Join Now</NavLink>
        </div>

        <div className="vp-main-content">
          <div className="vp-info-section">
            <div className="vp-info-block">
              <h2 className="vp-info-title" >Set up your virtual clinic</h2>
              <p className="vp-info-text" >By creating your online profile, you'll help pet owners find you quickly and get to know you better.</p>
            </div>

            <div className="vp-info-block">
              <h2 className="vp-info-title" >Join Rex Vets' Community of Experts – where veterinarians come together to improve pet health. </h2>
              <p className="vp-info-text" >Rex Vets empowers veterinarians to provide accessible care to pets in need, fostering strong relationships with pet owners and enhancing animal well-being.</p>
            </div>
          </div>

          <div className="vp-image-container">
            <img  src={mobile} alt="Rex Vets" className="vp-image" />
          </div>
        </div>
      </div>
      </div>


      <div className="vw-container">
        <div className="vw-header">
          <h1 className="vw-title">Hello Veterinarians, achieve work/life balance.</h1>
          <p className="vw-subtitle">Rex Vets allows you to...</p>
        </div>
        
        <div className="vw-content" >
          <div className="vw-image-container">
            <img src={bgVets} alt="Work life balance" className="vw-image" />
          </div>

          <div className="vw-info-section">
            <div className="vw-info-block">
              <div className="vw-icon vw-icon-healthy"></div>
              <div>
                <h2 className="vw-info-title">Enjoy a healthy, balanced life</h2>
                <p className="vw-info-text">Rex Vets lets you work remotely, so you can spend more time with your loved ones. Work at your convenience!</p>
              </div>
            </div>
            
            
            <div className="vw-info-block">
              <div className="vw-icon vw-icon-practice"></div>
              <div>
                <h2 className="vw-info-title">Your clinic, your way</h2>
                <p className="vw-info-text">Set your own schedule, choose your rates (including holiday rates and discounts), and practice from anywhere.</p>
              </div>
            </div>
            
            <div className="vw-info-block">
              <div className="vw-icon vw-icon-time"></div>
              <div>
                <h2 className="vw-info-title">Maximize your efficiency</h2>
                <p className="vw-info-text">Rex Vets helps you optimize time and appointment efficiency. Seamlessly create and adjust your schedule for a productive work-life balance.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="rexVetsbav-container">
      <div className="rexVetsbav-header">
        <h1>RexVets simplifies the management of your<br></br> online veterinary services</h1>
        <p>RexVets is accessible via WEB and MOBILE platforms</p>
      </div>

      <div className="rexVetsbav-content">
        <div className="rexVetsbav-communication">
          <h2>Secure Client Interaction</h2>
          <ul>
            <li><span className="rexVetsbav-check">✔</span> Video consultations and direct messaging</li>
            <li><span className="rexVetsbav-check">✔</span> RexVets offers tools that enhance virtual care delivery</li>
            <li><span className="rexVetsbav-check">✔</span> Features include record management, file sharing, direct chats, notes & more</li>
          </ul>
        </div>
        <div className="rexVetsbav-image-section">
          <img src={bg1} alt="Video consultation example" className="rexVetsbav-image"/>
        </div>
      </div>

      <div className="rexVetsbav-schedule">
        <div className="rexVetsbav-image-section">
          <img  src={bg2} alt="Schedule and rates management" className="rexVetsbav-image"/>
        </div>
        <div className="rexVetsbav-schedule-info">
          <h2>Control Your Availability & Pricing</h2>
          <ul>
            <li><span className="rexVetsbav-check">✔</span> Customize your schedule based on your personal needs</li>
            
          </ul>
        </div>
      </div>

      {/* Nueva sección añadida */}
      <div className="rexVetsbav-records">
        <div className="rexVetsbav-records-info">
          <h2>Create & Manage Medical Records</h2>
          <ul>
            <li><span className="rexVetsbav-check">✔</span> View appointment history and treatments</li>
            <li><span className="rexVetsbav-check">✔</span> Add, edit, and review notes</li>
            <li><span className="rexVetsbav-check">✔</span> Collaborate with pet owners to upload files, videos, and images</li>
          </ul>
        </div>
        <div className="rexVetsbav-image-section">
          <img src={bg3} alt="Medical records management" className="rexVetsbav-image"/>
        </div>
      </div>
    </div>
    <div className="rexVetsbav-benefits-container">
      <h2 class="titleBenefitsTech">More exclusive RexVets benefits:</h2>
      <div className="rexVetsbav-benefits-table-wrapper">
        <table className="rexVetsbav-benefits-table">
          <thead>
            <tr>
              <th>Feature</th>
              <th>
                <div className="rexVetsbav-logo-container">
                  <img src={LogoWhite} alt="RexVets Logo" className="rexVetsbav-logo" />
                  RexVets
                </div>
              </th>
              <th class="other-services-tab">Other services</th>
            </tr>
          </thead>
          <tbody>
            {benefits.map((benefit, index) => (
              <tr key={index}>
                <td>
                 <p  class="text-left"> <strong>{benefit.feature}</strong><br /></p>
                  <span className="rexVetsbav-description">{benefit.description}</span>
                </td>
                <td>{benefit.rexVets ? '✔' : '✖'}</td>
                <td>{benefit.others ? '✔' : '✖'}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
    <div className="cta-container">
      <div className="cta-content">
        <h2>A timely message: Your expertise is needed!</h2>
        <p>
          Online consultations can bridge the gap for many pet owners struggling to find care. 
        </p>
        <NavLink to="/RegistrationVetsandTechsPage1">
        <button className="cta-button">Sign up now</button>
        </NavLink>
      </div>
      <div className="cta-stats">
        <div className="cta-stat">
          <h3>Extended Hours</h3>
          <p><strong>40%</strong><br />of online consultations happen outside of regular hours</p>
        </div>
        <div className="cta-stat">
          <h3>Remote Pets</h3>
          <p><strong>30%</strong><br />of appointments cater to pets in remote areas</p>
        </div>
        <div className="cta-stat">
          <h3>Wide Range of Species</h3>
          <p><strong>25+</strong><br />Species supported in online care</p>
        </div>
        <div className="cta-stat">
          <h3>Distance Clients</h3>
          <p><strong>70%</strong><br />of consultations serve clients over 100 miles away</p>
        </div>
      </div>
    </div>
            <Footer />
        </>
        )
}

export default VetsandTechs