import React from 'react';
import Footer from '../../Navbars/Footer/index.js';
import Navbar from '../../Navbars/NavbarMain/NavbarMain.js';
import imgShop1 from '../../1_MediaAssets/Homepage/imgShop.png';
import imgShop2 from '../../1_MediaAssets/Homepage/imgShop2.png';
import { NavLink } from 'react-router-dom';
const GetAPrescription = () => {
  return (
    <> 
            <Navbar />
            <div className="GetAPrescription-container">
      <div className="GetAPrescription-background">
        <div className="GetAPrescription-content">
          <h1 className="GetAPrescription-title">Pet medication delivered to your door, effortlessly</h1>
          <p className="GetAPrescription-description">Rex Vets can prescribe online. Order medication online with RexRx or pick up from your local pharmacy.</p>
          <div className='btn-marginPr'><NavLink to="/FindAVet" className="GetAPrescription-button"> Speak to a licensed vet</NavLink></div>
        </div>
      </div>
      <div className="GetAPrescription-features">
        <div className="GetAPrescription-feature">
          <div className="GetAPrescription-feature-icon">
            <i className="fas fa-shipping-fast"></i>
          </div>
          <p>Free standard shipping on most orders over $49</p>
        </div>
        <div className="GetAPrescription-feature">
          <div className="GetAPrescription-feature-icon">
            <i className="fas fa-prescription-bottle-alt"></i>
          </div>
          <p>Prescriptions available in select states</p>
        </div>
        <div className="GetAPrescription-feature">
          <div className="GetAPrescription-feature-icon">
            <i className="fas fa-mobile-alt"></i>
          </div>
          <p>24/7 on-demand, online vet appointments</p>
        </div>
        <div className="GetAPrescription-feature">
          <div className="GetAPrescription-feature-icon">
            <i className="fas fa-user-md"></i>
          </div>
          <p>Choose from thousands of licensed vets</p>
        </div>
      </div>
    </div>
    <div className="RexVetsShop-container">
        <div className="RexVetsShop-content">
          <div className="RexVetsShop-text">
            <h2 className="RexVetsShop-title">Rex Vets Shop</h2>
            <p className="RexVetsShop-description">At Rex Vets Shop, you can find everything you need for your beloved pets. From food and toys to health and wellness products, we have it all. Shop with us for the best quality products and services.</p>
            <button className="RexVetsShop-button" onClick={() => window.location.href = 'https://rexvets.securevetsource.com/site/view/230921_Home.pml'}>Go Shopping</button>
          </div>
          <div className="RexVetsShop-image-container">
            <img src={imgShop2} alt="Pet Products" className="RexVetsShop-image" />
          </div>
        </div>
      </div>
      <div className="RexVetsPhar-info-container">
      <div className="RexVetsPhar-info-content">
        <div className="RexVetsPhar-info-image-container">
          <img src={imgShop1} alt="Pet Products" className="RexVetsPhar-info-image" />
        </div>
        <div className="RexVetsPhar-info-text">
          <h2 className="RexVetsPhar-info-title">Getting everything for your pet is easy</h2>
          <ul className="RexVetsPhar-info-list">
            <li className="RexVetsPhar-info-item">
              <div className="RexVetsPhar-info-icon">1</div>
              <p className="RexVetsPhar-info-description">Browse through our extensive collection of pet products.</p>
            </li>
            <li className="RexVetsPhar-info-item">
              <div className="RexVetsPhar-info-icon">2</div>
              <p className="RexVetsPhar-info-description">Add your desired items to the cart and proceed to checkout.</p>
            </li>
            <li className="RexVetsPhar-info-item">
              <div className="RexVetsPhar-info-icon">3</div>
              <p className="RexVetsPhar-info-description">Enjoy fast and reliable delivery right to your doorstep.</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
<Footer />


</>
  );
};

export default GetAPrescription;