import DogDP from '../../../1_MediaAssets/OtherBasics/dog.png'; 
import PetDP from '../../../1_MediaAssets/OtherBasics/pet.png'; 
import CatDP from '../../../1_MediaAssets/OtherBasics/cat.png'; 
import Doc1 from '../../../1_MediaAssets/Homepage/doc1.png'; 
import Doc2 from '../../../1_MediaAssets/Homepage/doc2.png'; 
import NavbarPetParentsDashboard from '../../../Navbars/NavbarPetParentsDashboard/index.js';
import { LuLayoutDashboard } from "react-icons/lu";
import { MdOutlinePets } from "react-icons/md";
import { useEffect, useState } from 'react';
import { doc, getDoc, collection, getFirestore, getDocs } from "firebase/firestore";
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import '@fontsource/inter';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVideo, faComments, faPaw } from '@fortawesome/free-solid-svg-icons';
import { faCircle } from '@fortawesome/free-solid-svg-icons';

const DashboardPetParents = () => {
    const [parentData, setParentData] = useState({});
    const [pets, setPets] = useState([]);
    // eslint-disable-next-line
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const db = getFirestore();
    // eslint-disable-next-line
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const auth = getAuth();

        // Check if the user is authenticated
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                setIsLoggedIn(true);

                try {
                    // Fetch parent data
                    const docRef = doc(db, "Parents", user.uid);
                    const docSnap = await getDoc(docRef);
                    if (docSnap.exists()) {
                        setParentData(docSnap.data());
                    } else {
                        console.error('No such document!');
                    }

                    // Fetch pets data
                    const petsCollection = collection(docRef, 'pets');
                    const petsSnapshot = await getDocs(petsCollection);
                    const petsArray = petsSnapshot.docs.map(doc => doc.data());
                    setPets(petsArray);
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            } else {
                setIsLoggedIn(false);
                navigate('/LoginPetParents');
            }
        });
        return () => unsubscribe();
    }, [db, navigate]);

    

    return (
        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100vw', minHeight: '100%'}}>
            <div className='dashboardmain'>
                <NavbarPetParentsDashboard />
                {/* <nav className="DashboardGab-navbar">
                    <div className="DashboardGab-navbar__icons">
                        <div className="DashboardGab-navbar__language">
                            <i className="DashboardGab-icon-globe"></i>
                            <i className="DashboardGab-icon-caret-down"></i>
                        </div>
                        <div className="DashboardGab-navbar__profile">
                            <img src="https://via.placeholder.com/40" alt="Profile" />
                            <div className="DashboardGab-navbar__profile-dropdown">
                                <span>Nombre Apellido</span>
                                <small>nombreapellido@gmail.com</small>
                                <a href="/account">Account</a>
                                <a href="/signout">Sign out</a>
                            </div>
                        </div>
                    </div>
                </nav> */}
               <div className="dashboard-container">
    <div className="dashboard-header">
        <h4 className="dashboard-title" style={{fontFamily:'Inter, sans-serif'}}>
            <LuLayoutDashboard className="dashboard-icon" />
            Dashboard
        </h4>
    </div>

    <div className='divierwelcome'>
        <div className='divierwelcometext'>
            <h4 className="welcome-text" style={{fontFamily:'Inter, sans-serif'}}>Welcome {parentData.Name || 'Guest'}!</h4>
        </div>
    </div>  

    <div className="dashboard-content">
        <div className="dashboard-card">
            <div className="card-header">
                <img src={Doc1} alt="Doctor" className="card-image" />
                <div className="card-details">
                    <div className="card-status">
                        <FontAwesomeIcon icon={faVideo} className="icon-video" />
                        <span className="status-available">
                            <FontAwesomeIcon icon={faCircle} className="status-icon" /> 
                            <p className="status-text" style={{fontFamily:'Inter, sans-serif'}}>Available 24/7</p>
                        </span>
                    </div>
                    <h3 className="card-title" style={{fontFamily:'Inter, sans-serif'}}>Skip the waiting room, book a video appointment with a licensed vet.</h3>
                </div>
            </div>
            <div className="card-body">
                <p className="card-description" style={{fontFamily:'Inter, sans-serif'}}>Looking for a personal one-on-one appointment with a vet? We've got you covered.</p>
                <NavLink to='/FindAVet'>
                    <button className="book-appointment-btn">Book appointment</button>
                </NavLink>
            </div>
        </div>

        <div className="dashboard-card">
            <div className="card-header">
                <img src={Doc2} alt="Technician" className="card-image" />
                <div className="card-details">
                    <div className="card-status">
                        <FontAwesomeIcon icon={faComments} className="icon-comments" />
                        <span className="status-online">
                            <FontAwesomeIcon icon={faCircle} className="status-icon" />
                            <p className="status-text" style={{fontFamily:'Inter, sans-serif'}}>Online</p>
                        </span>
                    </div>
                    <h3 className="card-title" style={{fontFamily:'Inter, sans-serif'}}>Chat with a licensed veterinary technician in real time, at no cost.</h3>
                </div>
            </div>
            <div className="card-body">
                <p className="card-description" style={{fontFamily:'Inter, sans-serif'}}>Have a quick question or don’t know where to start? Chat with one of our licensed experts right now.</p>
                <p className="common-questions-title" style={{fontFamily:'Inter, sans-serif'}}>Common questions</p>
                <ul className="common-questions-list">
                    <li style={{fontFamily:'Inter, sans-serif'}}><FontAwesomeIcon icon={faPaw} className="icon-paw" /> How much should I be feeding my pet?</li>
                    <li style={{fontFamily:'Inter, sans-serif'}}><FontAwesomeIcon icon={faPaw} className="icon-paw" /> My pet started having diarrhea. Should I be concerned?</li>
                    <li style={{fontFamily:'Inter, sans-serif'}}><FontAwesomeIcon icon={faPaw} className="icon-paw" /> My pet just received vaccinations. Are there side effects I should look out for?</li>
                </ul>
            </div>
        </div>
    </div>
</div>
                    
                <div className='petsResponsive' style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', flexDirection: 'column', background: '#fff', borderRadius: '10px', width: '75%', marginTop: '20px', marginBottom: '200px',  boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1)'}}>
                    <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'row', width: '100%', padding: '15px', borderBottom: '1px solid #dedcd7' }}>
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column', width: '80%', padding: '0', margin: '0'}}>
                            <h4 style={{padding: '0', margin: '0', display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: '900', fontFamily: 'Inter, sans-serif',}}>
                                <MdOutlinePets style={{fontSize: '30px', margin: '0px 10px 0px 5px', color: '#0849c1'}}/>
                                Your pets
                            </h4>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'flex-end', flexDirection: 'column', width: '20%', padding: '0', margin: '0'}}>
                            <NavLink to="/PetsPetParents">
                            <button style={{padding: '10px', width: '120px', border: '2px solid #002366', background: 'white', color: '#002366', fontWeight: '900'}}>Manage</button>
                            </NavLink>
                        </div>
                    </div>
                    <div className='YourPetsDiv'>
                        {pets.map(pet => (
                            <div className='PetDetailDiv' >
                                <img src={
                                    pet.Species === 'Dog' ? DogDP : pet.Species === 'Cat' ? CatDP : PetDP} 
                                    alt={pet.Name} 
                                    style={{ height: '120px', width: '120px', border: '1px solid rgba(156, 163, 175, 0.5)',  borderRadius: '100px', margin: '10px' }} 
                                />
                            <p style={{fontFamily: 'Inter, sans-serif',  margin: '0', padding: '0', marginTop: '5px', marginBottom: '10px', fontWeight: '300', color: 'black'}}>{pet.Name}</p>
                            <p style={{fontFamily: 'Inter, sans-serif',  marginTop: '-5px', padding: '0', fontSize: '12px' }}>{pet.Species} · {pet.AgeYears} years old</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DashboardPetParents;
