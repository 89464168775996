import Logo from '../1_MediaAssets/Homepage/Logo.png';

const LoadingScreen = () => {
    return (
        <div style={{height: "100vh", width: '100vw', background: '#002366', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <img src={Logo} style={{width: '150px'}} alt='RexVets Logo' />
        </div>
    );
};

export default LoadingScreen;
