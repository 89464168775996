import React from 'react'; 
import { Link } from 'react-router-dom';
import Background from '../../1_MediaAssets/MainPages/Blogs/BlogsBG.jpg';
import Cover from '../../1_MediaAssets/MainPages/Blogs/BlogsCover.jpg';
import BlogsData from './BlogsData';
import Footer from '../../Navbars/Footer';
import Navbar from '../../Navbars/NavbarMain/NavbarMain';

const BlogsPage = ({ blogs = BlogsData }) => {
  return (<>
    <Navbar />
    <div style={{ paddingTop: '70px', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100vw', backgroundColor: 'black', flexDirection: 'column', backgroundImage: `url(${Background})`, backgroundAttachment: 'fixed', backgroundPosition: 'bottom' }}>
      
      <div className="HeroMainDiv" style={{height: '450px', backgroundImage:`url('${Cover}')`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundAttachment: 'fixed', backgroundColor: '#002366'}}>
          <div className="hero-content">
            <h1 className="hero-title outlined-text" style={{ color: 'transparent', letterSpacing: '3px' }}>Blogs</h1>
          </div>
      </div>

      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100vw', flexWrap: 'wrap', padding: '20px 0px' }}>
        {blogs.map((blog) => (
          <Link to={`/blog/${blog.slug}`} key={blog.slug} style={{ textDecoration: 'none' }}>
            <div className="blogcard">
              <img src={blog.mainImage} className="blogcard-img-top" alt={blog.title} />
              <div>
                <p className="blogcard-date">{blog.date} | {blog.by}</p>
                <h1 className="blogcard-title">{blog.title}</h1>
              </div>
            </div>
          </Link>
        ))}
      </div>
      <Footer />
    </div>
    </>
  );
};

export default BlogsPage;
