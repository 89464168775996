
import { LiaStethoscopeSolid } from 'react-icons/lia';
import { FaUserDoctor } from 'react-icons/fa6';
import { FaCreditCard } from 'react-icons/fa';
import { FaLock } from "react-icons/fa6";
import { MdOutlinePets } from "react-icons/md";
import { MdAccountCircle } from "react-icons/md";
import React from 'react';
import { Link } from 'react-router-dom';
import NavbarVetsandTechsDashboard from '../../../Navbars/NavbarVetsandTechsDashboard';
import '@fontsource/inter/100.css';  
import '@fontsource/inter/200.css'; 
import '@fontsource/inter/300.css';  
import '@fontsource/inter/400.css';  
import '@fontsource/inter/500.css'; 
import '@fontsource/inter/600.css'; 
import '@fontsource/inter/700.css';  
import '@fontsource/inter/800.css'; 
import '@fontsource/inter/900.css';
const AccountSettings = () => {
    const settingsOptions = [
        {
            icon: <FaUserDoctor size={30} />,
            title: "Personal Information",
            subtext: "Update your details such as your name, photo, bio etc.",
            link: "/AccountMenuVetsandTechs/PersonalInformationVetsandTechs"
        },
        {
            icon: <LiaStethoscopeSolid size={30} />,
            title: "Areas of interest",
            subtext: "Choose the areas of interest you practice to adjust how you appear in searches",
            link: "/AccountMenuVetsandTechs/AreasofInterest"
        },
        {
            icon: <MdOutlinePets size={30} />,
            title: "Species treated",
            subtext: "Choose the species you treat to adjust how you appear in search results",
            link: "/AccountMenuVetsandTechs/SpeciesTreated"
        },
        {
            icon: <FaLock size={30} />,
            title: "Login and security",
            subtext: "Manage your login info and change your password",
            link: "/AccountMenuVetsandTechs/LoginandSecurity"
        },
        {
            icon: <FaCreditCard size={30} />,
            title: "Payments & reporting",
            subtext: "Manage your payout methods, check your history",
            link: "/AccountMenuVetsandTechs/PaymentsReportingVetsandTechs"
        }
    ];

    return (
        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100vw', height: '100%', background: '#f5f5f5' }}>
            <div className='dashboardmain' style={{ paddingBottom: '50px' }}>
                <NavbarVetsandTechsDashboard />
                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'column', background: '#fff', borderRadius: '10px', width: '85%', marginTop: '50px', boxShadow: '0 0 #0000, 0 0 #0000, 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)', padding:'30px' }}>
                    <div style={{ display: 'flex', flexDirection:'column', justifyContent: 'flex-start', width: '100%', padding: '15px', borderBottom: '1px solid #dedcd7' }}>
                        <h4 style={{ padding: '0', margin: '0', alignItems: 'center', fontWeight: '900', marginBottom:'5px'}}>
                            <MdAccountCircle style={{ fontFamily:'Inter, sans-serif', fontSize: '30px', margin: '0px 10px 0px 5px', color: '#0849c1' }} />
                            Account Settings
                        </h4>
                        <p style={{ fontFamily:'Inter, sans-serif', fontWeight:'300' }}>Control all aspects of your Rex Vets account from this dashboard.</p>
                    </div>
                    <div className="vetAccountMenuHolder">
                        {settingsOptions.map((option, index) => (
                            <Link to={option.link} key={index} style={{ textDecoration: 'none', color: 'inherit', flex: '1 1 calc(33.333% - 20px)', maxWidth: 'calc(33.333% - 20px)', minWidth: '300px' }}>
                                <div
                                    style={{ 
                                        background: 'rgba(250, 250, 250, 1)', 
                                        borderRadius: '10px', 
                                        padding: '20px', 
                                        display: 'flex', 
                                        flexDirection: 'column', 
                                        justifyContent: 'flex-start', 
                                        alignItems: 'flex-start', 
                                        textAlign: 'center', 
                                        boxShadow: '0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)', 
                                        transition: 'box-shadow 0.3s ease',
                                        border:'1px solid #dcdcdc ',
                                        minHeight: '180px'

                                    }}
                                    onMouseEnter={(e) => e.currentTarget.style.backgroundColor = 'rgba(243, 243, 243, 1)'}
                                    onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'rgba(250, 250, 250, 1)'}
                                >
                                    <div style={{ fontSize: '30px', color: '#002366', marginBottom: '0' }}>
                                        {option.icon}
                                    </div>
                                    <h5 class='inter-font' style={{ margin: '10px 0', fontSize: '20px', fontWeight:'500' }}>{option.title}</h5>
                                    <p  class='inter-font' style={{ margin: '0', fontSize: '16px', color: 'rgb(66 66 66)', textAlign: 'left' , fontWeight:'300' }}>{option.subtext}</p>
                                </div>
                            </Link>
                        ))}
                    </div>
                </div>
            </div>
        </div>

    );
};

export default AccountSettings;
