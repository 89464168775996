import React, { useState } from "react";
import profile1 from '../../1_MediaAssets/MainPages/ProfilePic1.png'
import profile2 from '../../1_MediaAssets/MainPages/profilepic2.png'
import profile3 from '../../1_MediaAssets/MainPages/profilepic3.png'
const TestimonialCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const testimonials = [
    {
      name: "Sarah Williams",
      quote: "Thanks to the free telehealth services, my dog Max received the care he needed, and I am forever grateful for the community's support!",
      imgSrc: profile3,
    },
    {
      name: "Emily Johnson",
      quote: "The telehealth consultation helped my cat heal quickly. I appreciate how easy it was to access professional help from home.",
      imgSrc: profile2,
    },
    {
      name: "Michael Carter",
      quote: "This service saved my dog's life. Without the free consultation, I wouldn't have been able to afford the emergency care she needed.",
      imgSrc: profile1,
    },
  ];

  const nextTestimonial = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
  };

  const prevTestimonial = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + testimonials.length) % testimonials.length);
  };

  return (
    <div className="carrouselDonations">
      <h2 className="testimonial-title" style={{marginBottom:'30px', color:'#001337'}}>What People Are Saying</h2>
      <div className="testimonial-card">
        <img
          src={testimonials[currentIndex].imgSrc}
          alt={testimonials[currentIndex].name}
          className="testimonial-img"
        />
        <p className="testimonial-quote">"{testimonials[currentIndex].quote}"</p>
        <p className="testimonial-name">- {testimonials[currentIndex].name}</p>
      </div>

      <div className="carousel-controls">
        <button onClick={prevTestimonial} className="carousel-button">
          &lt;
        </button>
        <button onClick={nextTestimonial} className="carousel-button">
          &gt;
        </button>
      </div>
    </div>
  );
};

export default TestimonialCarousel;
