import { NavLink } from "react-router-dom";
import TextAnim from '../../1_MediaAssets/Styles/FadeAnimation.jsx';
import Cover from '../../1_MediaAssets/MainPages/VetsandTechsCover.jpg';
import bgVets from '../../1_MediaAssets/VetsandTechs/image2vets.png';
import bgVets1 from '../../1_MediaAssets/VetsandTechs/image1vets.png';
import Navbar from '../../Navbars/NavbarMain/NavbarMain.js';
import Footer from '../../Navbars/Footer/index.js';
import mobile from '../../1_MediaAssets/Homepage/mobilerex.png';
import React, { useState, useEffect } from 'react';

const Support = () => {  



    return (
        <>
            <Navbar />
            <div className="optionsbg"><div className="help-support-component">
            <div className="help-support-content">
            <TextAnim Body={<h1 className="help-support-title">Help & Support</h1>}/>
        <p className="help-support-text">If you’re looking to connect with a veterinary professional online, please <a href="" className="help-support-link">book an appointment</a>.</p>
        <p className="help-support-text">Otherwise, our support team is available on Live Chat 7 days a week, <strong>7AM - 11PM EST (Tue, Wed, Thur)</strong> and <strong>7AM - 1AM EST (Fri, Sat, Sun, Mon)</strong> to answer any non-medical questions.</p>
        <p className="help-support-text">If you have questions about prescriptions, chat with us here first.</p>
      </div>
    </div>
    <div className="support-options">
      <div className="support-option">
      <div className="support-option-height"><i className="fas fa-question-circle support-icon"></i>
        <h3 className="support-title">Help center</h3>
        <p className="support-description">Our self-help center is available 24/7 and is jam packed with frequently asked questions, how-to's and articles about the Rex Vets platform.</p></div>
        <NavLink to="/faq">
  <button className="support-button">Find answers</button>
</NavLink>
      </div>
      <div className="support-option">
      <div className="support-option-height"><i className="fas fa-comments support-icon"></i>
        <h3 className="support-title">Chat with us</h3>
        <p className="support-description">Start a conversation with our virtual assistant or chat with a real person!</p></div>
        <button className="support-button">Chat with us</button>
      </div>
      <div className="support-option">
        <div className="support-option-height"><i className="fas fa-envelope support-icon"></i>
        <h3 className="support-title">Email support</h3>
        <p className="support-description">We’re here to help you with any platform related questions that you might have.</p></div>
        <a href="mailto:support@rexvets.com?subject=Support Request&body=Describe your issue here" className="support-button">Email us</a>
      </div>
    </div></div>
    <div className="connect-options">
      <h3 className="connect-title">More ways to connect</h3>
      <div className="connect-buttons">
        <a href="https://www.facebook.com/rexvets" className="connect-button" target="_blank" rel="noopener noreferrer">
          <i className="fab fa-facebook-f"></i> Facebook
        </a>
        <a href="https://www.instagram.com/rexvets/" className="connect-button" target="_blank" rel="noopener noreferrer">
          <i className="fab fa-instagram"></i> Instagram
        </a>
      </div>
    </div>
            <Footer />
        </>
        )
}

export default Support