import React from "react";
import Navbar from '../../Navbars/NavbarMain/NavbarMain.js';
import Footer from '../../Navbars/Footer/index.js';
import { NavLink } from 'react-router-dom';

const FinancialTransparency = () => {
  return (
    <>
    <Navbar />
    <div className="GetAPrescription-container">
      <div className="financial-background">
        <div className="financial-content1">
          <h1 className="">Your Donations at Work</h1>
          <p className="OurMission-description">At Rex Vets, transparency is key to building trust with our community. We want you to know exactly how your donations are being used to support pets in need through telehealth services. </p>
          <div className='btn-marginPr'><NavLink to="/Donate" className="GetAPrescription-button"> Donate Now</NavLink></div>
        </div>
      </div>


    </div>
    <div className="financial-container">

      <div className="cta-stats">
        <div className="cta-stat">
          <h3>Veterinary care</h3>
          <p><strong>70%</strong><br />of funds are allocated to providing telehealth veterinary care, including subsidized virtual consultations, emergency telehealth services, and follow-up care.
</p>
        </div>
        <div className="cta-stat">
          <h3>Programs</h3>
          <p><strong>20%</strong><br />of funds support community outreach programs, such as online pet owner education, virtual wellness clinics, and partnerships with local shelters.
</p>
        </div>
        <div className="cta-stat">
          <h3>Administrative Costs</h3>
          <p><strong>10%</strong><br />of funds cover administrative costs, ensuring that our team can continue to offer high-quality, accessible telehealth care.
</p>
        </div>
      </div>
      <div className="financial-content" style={{marginTop:'20px'}}>
        <p style={{marginTop:'20px', maxWidth:'900px', fontSize:'20px'}}>
        Together, we can create a world where no pet is left without care due to distance or financial hardship. Thank you for your trust and support. 
        </p>

      </div>
      <NavLink to="/Donate">
        <button className="cta-button" style={{fontSize: '20px', marginTop:'20px'}}>Donate Now</button>
        </NavLink>
    </div>
<Footer/>
</>
  );
};

export default FinancialTransparency;