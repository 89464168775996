import ProfilePicture from './vetty.png'; 
import Texture from '../../../1_MediaAssets/OtherBasics/Texture.png';
import Cover from '../../../1_MediaAssets/VetsandTechs/RegistrationVetsandTechs/Cover2.png';
import { useState } from 'react'; 
// eslint-disable-next-line  
import { NavLink, useLocation, useNavigate } from "react-router-dom";
// eslint-disable-next-line 
import { app, db, storage } from '../../../../firebase/index.js';
import CutePets from "../../CutePets.js";
import { getFirestore, collection, doc, addDoc, setDoc, serverTimestamp, updateDoc } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";



const RegistrationVetsandTechsPage4 = () => { 
    const auth = getAuth();


    const navigate = useNavigate();
    const location = useLocation();
    // const id = location.state.id;
    const DocType = location.state.DocType;
    const FirstName = location.state.FirstName
    const LastName = location.state.LastName
    const EmailAddress = location.state.EmailAddress
    const Gender = location.state.Gender;
    const CountryCode = location.state.CountryCode;
    const PhoneNo = location.state.PhoneNo
    const PostNominalLetters = location.state.PostNominalLetters
    const City = location.state.City
    const State = location.state.State
    const Schedule = location.state.Schedule
    const db = getFirestore(app);
    const Password = location.state.Password;


    // console.log("Document ID from page 2:", id);
    console.log("Document DocType from page 2:", DocType);

    const [imageUrl, setImageUrl] = useState(ProfilePicture);
    const [image, setImage] = useState(ProfilePicture);


    const [rows, setRows] = useState([
        {
            profilePicture: null,
            cv: null,
            licenseNumber: '',
            stateOfLicense: '',
            licenseFile: [] 
        },
    ]);
    
    const handleInputChange = (index, event) => {
        const { name, value } = event.target;
        const newRows = [...rows];
        newRows[index][name] = value;
        setRows(newRows);
    };
    
    const handleFileChange = (index, event) => {
        const { files } = event.target;
        if (files.length > 0) {
            const file = files[0];

            // Check file size and type
            if (index === 0) {
                if (file.size > 3000 * 1024) { // 3MB
                    alert('CV file size exceeds 3MB');
                    return;
                }
                if (file.type !== 'application/pdf') {
                    alert('Invalid CV file type. Please select a PDF file.');
                    return;
                }
            } else {
                if (file.size > 3000 * 1024) { // 3MB
                    alert('License file size exceeds 3MB');
                    return;
                }
                if (file.type !== 'application/pdf') {
                    alert('Invalid license file type. Please select a PDF file.');
                    return;
                }
            }

            const newRows = [...rows];
            if (index === 0) {
                newRows[index].cv = file;
            } else {
                newRows[index - 1].licenseFile.push(file);
            }
            setRows(newRows);
        }
    };
    
    
    const addRow = () => {
        setRows([...rows, { licenseNumber: '', stateOfLicense: '', licenseFile: [],cv:'' }]);
    };
    
    const readURL = (e) => {
        const file = e.target.files[0];
        if (file) {
            // Check file size (max 500KB)
            if (file.size > 500 * 1024) { // 500KB in bytes
                alert('File size exceeds 500KB');
                return;
            }
    
            // Check file type
            const validTypes = ['image/png', 'image/jpeg', 'image/jpg'];
            if (!validTypes.includes(file.type)) {
                alert('Invalid file type. Please select an image (png, jpg, jpeg).');
                return;
            }
    
            setImage(file);
            const reader = new FileReader();
            reader.onload = () => {
                setImageUrl(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    function sendWelcomeEmail(email,name) {
        console.log("Sending welcome email for Google sign-up..."); // Log before sending email
        fetch("https://rexvetsemailserver.vercel.app/sendWelcomeEmail", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email: email,
                name: name || 'Anonymous',
            }),
        })
        .then(response => response.json())
        .then(data => {
            console.log("Email response:", data); // Log email response
        })
        .catch(emailError => {
            console.log("Error sending welcome email:", emailError); // Log email sending error
        });
    }

    const handleSubmit = async () => {
        document.getElementById("SubmitButton").innerHTML = "Loading...";
    
        if (!image || !rows[0].cv || !rows.some(row => row.licenseFile.length > 0)) {
            document.getElementById("errortext").innerHTML = "Please upload a profile picture, CV, and at least one license PDF.";
            document.getElementById("SubmitButton").innerHTML = "Submit";
            return;
        }
    
        console.log(EmailAddress, Password);
    
        const userData = {
            FirstName: FirstName,
            LastName: LastName,
            EmailAddress: EmailAddress,
            CountryCode: CountryCode,
            PhoneNo: PhoneNo,
            Gender: Gender,
            PostNominalLetters: PostNominalLetters,
            City: City,
            State: State,
            DocType: DocType,
            Schedule: Schedule,
            RegistrationTime: serverTimestamp(),
        };
    
        createUserWithEmailAndPassword(auth, EmailAddress, Password)
            .then((userCredential) => {
                const user = userCredential.user;
                localStorage.setItem('userId', user.uid);
    
                const userDocRef = doc(db, `Doctors/${DocType}/${DocType}/${user.uid}`);
    
                setDoc(userDocRef, userData)
                    .then(async () => {
                        // Upload profile picture
                        const profilePicture = image;
                        const fileExtension = profilePicture.name.split('.').pop();
                        const profilePictureRef = ref(storage, `Doctors/${DocType}/${user.uid}/profilePicture.${fileExtension}`);
    
                        const uploadTask = uploadBytesResumable(profilePictureRef, profilePicture);
    
                        uploadTask.on('state_changed', (snapshot) => {
                            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                            console.log('Upload is ' + progress + '% done');
                        }, 
                        (error) => {
                            console.log('Error uploading file:', error);
                        },
                        () => {
                            getDownloadURL(uploadTask.snapshot.ref).then((profilePictureURL) => {
                                const cvRef = ref(storage, `Doctors/${DocType}/${user.uid}/cv.pdf`);
                                const cvUploadTask = uploadBytesResumable(cvRef, rows[0].cv);
    
                                cvUploadTask.on('state_changed', (cvQuery) => {
                                    // Handle state changes for CV upload
                                },
                                (error) => {
                                    console.log('Error uploading file:', error);
                                }, 
                                () => {
                                    let urls = [];
                                    let licenseStates = [];
    
                                    getDownloadURL(cvUploadTask.snapshot.ref).then((cvURL) => {
                                        let uploadPromises = rows.map((row, index) => {
                                            const licenseRef = ref(storage, `Doctors/${DocType}/${user.uid}/license_${index}.pdf`);
                                            const licenseUploadTask = uploadBytesResumable(licenseRef, row.licenseFile[0]);
    
                                            return new Promise((resolve, reject) => {
                                                licenseUploadTask.on('state_changed', (licenseQuery) => {
                                                    console.log('Upload state:', licenseQuery.state);
                                                },
                                                (error) => {
                                                    console.log('Error uploading file:', error);
                                                    reject(error);
                                                }, 
                                                () => {
                                                    getDownloadURL(licenseUploadTask.snapshot.ref).then((licenseURL) => {
                                                        const licenseData = {
                                                            licenseURL: licenseURL,
                                                            licenseState: row.stateOfLicense,
                                                            licenseNumber: row.licenseNumber
                                                        };
                                                        urls.push(licenseData);
                                                        licenseStates.push(row.stateOfLicense);
                                                        resolve(licenseURL);
                                                    });
                                                });
                                            });
                                        });
    
                                        Promise.all(uploadPromises).then(() => {
                                            updateDoc(userDocRef, {
                                                profilePictureURL: profilePictureURL,
                                                cvURL: cvURL,
                                                urls: urls,
                                                uid: user.uid,
                                                licenseStates: licenseStates
                                            }).then(() => {
                                                console.log('Document successfully updated!');
                                                sendWelcomeEmail(EmailAddress, FirstName);
                                                navigate('/LoginDoctors', { state: { docId: user.uid } });
                                            }).catch((error) => {
                                                console.error('Error updating document: ', error);
                                            });
                                        });
                                    });
                                });
                            });
                        });
                    })
                    .catch(error => {
                        console.error("Error adding document: ", error);
                    }); 
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.error('Error creating user:', errorCode, errorMessage);
            });
    };
    

    return (
        <div style={{minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden', backgroundImage:`url('${Cover}')`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundAttachment: 'fixed'}}>
            <div className='RegistrationWhiteBox' style={{backgroundImage:`url('${Texture}')`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', marginTop: '30px', marginBottom: '30px'}}>
                <div style={{width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', padding: '30px', flexDirection: 'column'}}>
                    <h2 style={{fontWeight: '900', color: '#002366', fontSize: '30px'}}>Welcome </h2>
                    <h5 style={{fontWeight: '900', marginTop: '-8px', color: '#686869'}}>Tell us about yourself</h5>
                    <CutePets />
                </div>

                <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column', marginTop: '-15px'}}>
                    <div className='optionBox' style={{marginTop: '0', height: '100%', justifyContent: 'flex-start', alignItems: 'flex-start'}}> 
                        <div className='OnlyColumnPage2'>
                            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%'}}>
                                <img id="blah" style={{borderRadius: '100px', height: '150px', width: '150px', objectFit: 'cover'}} src={imageUrl} alt="yourge" />
                                <label htmlFor="file-upload" className="custom-file-upload" style={{marginTop: '10px', width: '115px'}}>
                                    <input id="file-upload" type="file" onChange={readURL} style={{display: 'none', width: '100px'}} />
                                    Choose Photo
                                </label>
                            </div>

                            <div style={{display: 'flex', flexDirection: 'column', width: '100%', marginTop: '20px'}}>
                                <label style={{fontWeight: '900', fontSize: '13px'}}>Upload CV</label>
                                <input style={{fontSize: '14px', marginTop: '1px'}} type='file' onChange={(e) => handleFileChange(0, e)} />
                            </div>
                           
                            <div style={{display: 'flex', flexDirection: 'column', width: '100%', padding: '10px', marginTop: '20px', borderRadius: '15px', background: '#e8e9eb', justifyContent: 'center', alignItems: 'center'}}>
                                    <table style={{width: '100%'}}>
                                        <thead>
                                            <tr>
                                                <th style={{fontSize: '13px', width: '33.33%'}}>License number</th>
                                                <th style={{fontSize: '13px', width: '33.33%'}}>State of license</th>
                                                <th style={{fontSize: '13px', width: '33.33%'}}>Upload license</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {rows.map((row, index) => (
                                            <tr key={index}>
                                            <td style={{width: '33.3%'}}>
                                                <input
                                                type="text"
                                                name="licenseNumber"
                                                value={row.licenseNumber}
                                                style={{width:"100%", height:'35px', borderRadius: '5px', padding: '8px', border: '1px solid grey', fontSize: '14px'}}
                                                onChange={(e) => handleInputChange(index, e)}
                                                />
                                            </td>
                                            <td style={{width: '33.3%'}}>
                                            <select
                                                name="stateOfLicense"
                                                value={row.stateOfLicense}
                                                onChange={(e) => handleInputChange(index, e)}
                                                style={{background: "white", border: "1px solid grey", borderRadius: '5px', width: "100%", height: '35px', color: "black", paddingLeft: '5px', paddingRight: '5px', fontSize: '14px', backgroundColor: 'white'}}
                                                >
                                                <option disabled value="">Select a state</option>
                                                <option value="Alabama">Alabama</option>
                                                <option value="Alaska">Alaska</option>
                                                <option value="Arizona">Arizona</option>
                                                <option value="Arkansas">Arkansas</option>
                                                <option value="California">California</option>
                                                <option value="Colorado">Colorado</option>
                                                <option value="Connecticut">Connecticut</option>
                                                <option value="Delaware">Delaware</option>
                                                <option value="District Of Columbia">District Of Columbia</option>
                                                <option value="Florida">Florida</option>
                                                <option value="Georgia">Georgia</option>
                                                <option value="Hawaii">Hawaii</option>
                                                <option value="Idaho">Idaho</option>
                                                <option value="Illinois">Illinois</option>
                                                <option value="Indiana">Indiana</option>
                                                <option value="Iowa">Iowa</option>
                                                <option value="Kansas">Kansas</option>
                                                <option value="Kentucky">Kentucky</option>
                                                <option value="Louisiana">Louisiana</option>
                                                <option value="Maine">Maine</option>
                                                <option value="Maryland">Maryland</option>
                                                <option value="Massachusetts">Massachusetts</option>
                                                <option value="Michigan">Michigan</option>
                                                <option value="Minnesota">Minnesota</option>
                                                <option value="Mississippi">Mississippi</option>
                                                <option value="Missouri">Missouri</option>
                                                <option value="Montana">Montana</option>
                                                <option value="Nebraska">Nebraska</option>
                                                <option value="Nevada">Nevada</option>
                                                <option value="New Hampshire">New Hampshire</option>
                                                <option value="New Jersey">New Jersey</option>
                                                <option value="New Mexico">New Mexico</option>
                                                <option value="New York">New York</option>
                                                <option value="North Carolina">North Carolina</option>
                                                <option value="North Dakota">North Dakota</option>
                                                <option value="Ohio">Ohio</option>
                                                <option value="Oklahoma">Oklahoma</option>
                                                <option value="Oregon">Oregon</option>
                                                <option value="Pennsylvania">Pennsylvania</option>
                                                <option value="Rhode Island">Rhode Island</option>
                                                <option value="South Carolina">South Carolina</option>
                                                <option value="South Dakota">South Dakota</option>
                                                <option value="Tennessee">Tennessee</option>
                                                <option value="Texas">Texas</option>
                                                <option value="Utah">Utah</option>
                                                <option value="Vermont">Vermont</option>
                                                <option value="Virginia">Virginia</option>
                                                <option value="Washington">Washington</option>
                                                <option value="West Virginia">West Virginia</option>
                                                <option value="Wisconsin">Wisconsin</option>
                                                <option value="Wyoming">Wyoming</option>
                                                </select>
                                            </td>
                                            <td style={{width: '33.3%'}}>
                                                <input
                                                style={{ fontSize: '14px', marginTop: '1px', width: '100%' }}
                                                type="file"
                                                onChange={(e) => handleFileChange(index+1, e)}
                                                />
                                            </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                    <div style={{display: 'flex', flexDirection: 'column', width: '100%', marginTop: '10px'}}>
                                        <button style={{fontSize: '13px', borderRadius: '5px', background: 'white', color: 'black', fontWeight: '300'}} onClick={addRow}>Add another license</button>
                                    </div>
                                </div>

                                <div id='errortext' style={{display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'red', fontSize: '12px', height: '20px', marginTop: '10px'}}></div>
                                

                                <div className='ButtonofWhiteBox' style={{marginTop: '0'}}>
                                    <button id='SubmitButton' style={{width: '100%'}} onClick={handleSubmit}>Submit</button>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RegistrationVetsandTechsPage4;
