import Texture from '../../../1_MediaAssets/OtherBasics/Texture.png';
import Cover from '../../../1_MediaAssets/VetsandTechs/RegistrationVetsandTechs/Cover2.png';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import CutePets from "../../CutePets.js";

const RegistrationVetsandTechsPage2 = () => {
    const navigate = useNavigate();
    // eslint-disable-next-line
    const [option, setOption] = useState('');
    const [gender, setGender] = useState('');
    const location = useLocation();

    const selectedOption = new URLSearchParams(location.search).get("selectedOption");
    console.log("Selected Option:", selectedOption);

    const handleSubmit = () => {
        document.getElementById("SubmitButton").innerHTML = "Loading...";
        const firstName = document.getElementById('FirstName').value;
        const lastName = document.getElementById('LastName').value;
        const emailAddress = document.getElementById('EmailAddress').value;
        const countryCode = document.getElementById('CountryCode').value;
        const phoneNo = document.getElementById('PhoneNo').value;
        const postNominalLetters = document.getElementById('PostNominalLetters').value;
        const city = document.getElementById('City').value;
        const state = document.getElementById('State').value;
        const password = document.getElementById('Password').value;
        const confirmPassword = document.getElementById('ConfirmPassword').value;

        const validRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!firstName || !lastName || !phoneNo || !postNominalLetters || !gender || !city || !gender || !state || !password || !confirmPassword) 
        {
            document.getElementById("Error").innerHTML = "Please input all fields corrctly!";
            document.getElementById("SubmitButton").innerHTML = "Next";
            return;
        }
        if (!emailAddress || !validRegex.test(emailAddress)) {
            document.getElementById("Error").innerHTML = "Please enter a valid email address";
            document.getElementById("SubmitButton").innerHTML = "Next";
            return;
        }
        if (password.length < 8) {
            document.getElementById("Error").innerHTML = "Passwords must be 8 characters long!";
            document.getElementById("SubmitButton").innerHTML = "Next";
            return;
        }
        if (password !== confirmPassword) {
            document.getElementById("Error").innerHTML = "Passwords do not match!";
            document.getElementById("SubmitButton").innerHTML = "Next";
            return;
        }

        console.log(gender, "GENDER OF THE VET");

        navigate('/RegistrationVetsandTechsPage3', {
            state: {
                DocType: selectedOption,
                FirstName: firstName,
                LastName: lastName,
                EmailAddress: emailAddress,
                CountryCode: countryCode,
                PhoneNo: phoneNo,
                PostNominalLetters: postNominalLetters,
                Gender: gender,
                City: city,
                State: state,
                Password: password,
            },
        });
    };

    const handleGenderClick = (genderOption) => {
        setGender(genderOption);
    };

    return (
        <div style={{ backgroundImage: `url('${Cover}')`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden', backgroundAttachment: 'fixed' }}>
            <div className='RegistrationWhiteBox' style={{ backgroundImage: `url('${Texture}')`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
                
                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', padding: '30px 30px 5px 30px', flexDirection: 'column' }}>
                    <h2 style={{ fontWeight: '900', color: '#002366', fontSize: '30px' }}>Welcome</h2>
                    <h5 style={{ fontWeight: '900', marginTop: '-8px', color: '#686869' }}>Tell us about yourself</h5>
                    <CutePets />
                </div>

                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column',}}>
                    <div className='optionBoxxy'>
                        <div className='RegistrationFormColumn'>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'right', width: '100%' }}>
                                <label style={{ fontWeight: '900', fontSize: '13px' }}>First name</label>
                                <input type="text" id="FirstName" style={{ width: "100%", height: '35px', borderRadius: '5px', padding: '8px', border: '1px solid grey', fontSize: '14px' }} />
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'right', width: '100%', marginTop: '10px' }}>
                                <label style={{ fontWeight: '900', fontSize: '13px' }}>Last name</label>
                                <input type="text" id="LastName" style={{ width: "100%", height: '35px', borderRadius: '5px', padding: '8px', border: '1px solid grey', fontSize: '14px' }} />
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'right', width: '100%', marginTop: '10px' }}>
                                <label style={{ fontWeight: '900', fontSize: '13px' }}>Email address</label>
                                <input type="text" id="EmailAddress" style={{ width: "100%", height: '35px', borderRadius: '5px', padding: '8px', border: '1px solid grey', fontSize: '14px' }} />
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'right', width: '100%', marginTop: '10px' }}>
                                <label style={{ fontWeight: '900', fontSize: '13px' }}>Phone number</label>
                                <div style={{ width: "100%", display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                    <select id="CountryCode" required style={{ background: "white", border: "1px solid grey", borderRadius: '5px', width: "30%", height: '35px', color: "black", paddingLeft: '5px', paddingRight: '5px', backgroundColor: 'white', maxWidth: '250px', fontSize: '14px' }}>
                                        <option selected value="USA (+1)">USA (+1)</option>
                                        <option value="Algeria (+213)">Algeria (+213)</option>
                                        <option value="Andorra (+376)">Andorra (+376)</option>
                                        <option value="Angola (+244)">Angola (+244)</option>
                                        <option value="Anguilla (+1264)">Anguilla (+1264)</option>
                                        <option value="Antigua & Barbuda (+1268)">Antigua &amp; Barbuda (+1268)</option>
                                        <option value="Argentina (+54)">Argentina (+54)</option>
                                        <option value="Armenia (+374)">Armenia (+374)</option>
                                        <option value="Aruba (+297)">Aruba (+297)</option>
                                        <option value="Australia (+61)">Australia (+61)</option>
                                        <option value="Austria (+43)">Austria (+43)</option>
                                        <option value="Azerbaijan (+994)">Azerbaijan (+994)</option>
                                        <option value="Bahamas (+1242)">Bahamas (+1242)</option>
                                        <option value="Bahrain (+973)">Bahrain (+973)</option>
                                        <option value="Bangladesh (+880)">Bangladesh (+880)</option>
                                        <option value="Barbados (+1246)">Barbados (+1246)</option>
                                        <option value="Belarus (+375)">Belarus (+375)</option>
                                        <option value="Belgium (+32)">Belgium (+32)</option>
                                        <option value="Belize (+501)">Belize (+501)</option>
                                        <option value="Benin (+229)">Benin (+229)</option>
                                        <option value="Bermuda (+1441)">Bermuda (+1441)</option>
                                        <option value="Bhutan (+975)">Bhutan (+975)</option>
                                        <option value="Bolivia (+591)">Bolivia (+591)</option>
                                        <option value="Bosnia Herzegovina (+387)">Bosnia Herzegovina (+387)</option>
                                        <option value="Botswana (+267)">Botswana (+267)</option>
                                        <option value="Brazil (+55)">Brazil (+55)</option>
                                        <option value="Brunei (+673)">Brunei (+673)</option>
                                        <option value="Bulgaria (+359)">Bulgaria (+359)</option>
                                        <option value="Burkina Faso (+226)">Burkina Faso (+226)</option>
                                        <option value="Burundi (+257)">Burundi (+257)</option>
                                        <option value="Cambodia (+855)">Cambodia (+855)</option>
                                        <option value="Cameroon (+237)">Cameroon (+237)</option>
                                        <option value="Canada (+1)">Canada (+1)</option>
                                        <option value="Cape Verde Islands (+238)">Cape Verde Islands (+238)</option>
                                        <option value="Cayman Islands (+1345)">Cayman Islands (+1345)</option>
                                        <option value="Central African Republic (+236)">Central African Republic (+236)</option>
                                        <option value="Chile (+56)">Chile (+56)</option>
                                        <option value="China (+86)">China (+86)</option>
                                        <option value="Colombia (+57)">Colombia (+57)</option>
                                        <option value="Comoros (+269)">Comoros (+269)</option>
                                        <option value="Congo (+242)">Congo (+242)</option>
                                        <option value="Cook Islands (+682)">Cook Islands (+682)</option>
                                        <option value="Costa Rica (+506)">Costa Rica (+506)</option>
                                        <option value="Croatia (+385)">Croatia (+385)</option>
                                        <option value="Cuba (+53)">Cuba (+53)</option>
                                        <option value="Cyprus North (+90392)">Cyprus North (+90392)</option>
                                        <option value="Cyprus South (+357)">Cyprus South (+357)</option>
                                        <option value="Czech Republic (+42)">Czech Republic (+42)</option>
                                        <option value="Denmark (+45)">Denmark (+45)</option>
                                        <option value="Djibouti (+253)">Djibouti (+253)</option>
                                        <option value="Dominica (+1809)">Dominica (+1809)</option>
                                        <option value="Dominican Republic (+1809)">Dominican Republic (+1809)</option>
                                        <option value="Ecuador (+593)">Ecuador (+593)</option>
                                        <option value="Egypt (+20)">Egypt (+20)</option>
                                        <option value="El Salvador (+503)">El Salvador (+503)</option>
                                        <option value="Equatorial Guinea (+240)">Equatorial Guinea (+240)</option>
                                        <option value="Eritrea (+291)">Eritrea (+291)</option>
                                        <option value="Estonia (+372)">Estonia (+372)</option>
                                        <option value="Ethiopia (+251)">Ethiopia (+251)</option>
                                        <option value="Falkland Islands (+500)">Falkland Islands (+500)</option>
                                        <option value="Faroe Islands (+298)">Faroe Islands (+298)</option>
                                        <option value="Fiji (+679)">Fiji (+679)</option>
                                        <option value="Finland (+358)">Finland (+358)</option>
                                        <option value="France (+33)">France (+33)</option>
                                        <option value="French Guiana (+594)">French Guiana (+594)</option>
                                        <option value="French Polynesia (+689)">French Polynesia (+689)</option>
                                        <option value="Gabon (+241)">Gabon (+241)</option>
                                        <option value="Gambia (+220)">Gambia (+220)</option>
                                        <option value="Georgia (+7880)">Georgia (+7880)</option>
                                        <option value="Germany (+49)">Germany (+49)</option>
                                        <option value="Ghana (+233)">Ghana (+233)</option>
                                        <option value="Gibraltar (+350)">Gibraltar (+350)</option>
                                        <option value="Greece (+30)">Greece (+30)</option>
                                        <option value="Greenland (+299)">Greenland (+299)</option>
                                        <option value="Grenada (+1473)">Grenada (+1473)</option>
                                        <option value="Guadeloupe (+590)">Guadeloupe (+590)</option>
                                        <option value="Guam (+671)">Guam (+671)</option>
                                        <option value="Guatemala (+502)">Guatemala (+502)</option>
                                        <option value="Guinea (+224)">Guinea (+224)</option>
                                        <option value="Guinea - Bissau (+245)">Guinea - Bissau (+245)</option>
                                        <option value="Guyana (+592)">Guyana (+592)</option>
                                        <option value="Haiti (+509)">Haiti (+509)</option>
                                        <option value="Honduras (+504)">Honduras (+504)</option>
                                        <option value="Hong Kong (+852)">Hong Kong (+852)</option>
                                        <option value="Hungary (+36)">Hungary (+36)</option>
                                        <option value="Iceland (+354)">Iceland (+354)</option>
                                        <option value="India (+91)">India (+91)</option>
                                        <option value="Indonesia (+62)">Indonesia (+62)</option>
                                        <option value="Iran (+98)">Iran (+98)</option>
                                        <option value="Iraq (+964)">Iraq (+964)</option>
                                        <option value="Ireland (+353)">Ireland (+353)</option>
                                        <option value="Israel (+972)">Israel (+972)</option>
                                        <option value="Italy (+39)">Italy (+39)</option>
                                        <option value="Jamaica (+1876)">Jamaica (+1876)</option>
                                        <option value="Japan (+81)">Japan (+81)</option>
                                        <option value="Jordan (+962)">Jordan (+962)</option>
                                        <option value="Kazakhstan (+7)">Kazakhstan (+7)</option>
                                        <option value="Kenya (+254)">Kenya (+254)</option>
                                        <option value="Kiribati (+686)">Kiribati (+686)</option>
                                        <option value="Korea North (+850)">Korea North (+850)</option>
                                        <option value="Korea South (+82)">Korea South (+82)</option>
                                        <option value="Kuwait (+965)">Kuwait (+965)</option>
                                        <option value="Kyrgyzstan (+996)">Kyrgyzstan (+996)</option>
                                        <option value="Laos (+856)">Laos (+856)</option>
                                        <option value="Latvia (+371)">Latvia (+371)</option>
                                        <option value="Lebanon (+961)">Lebanon (+961)</option>
                                        <option value="Lesotho (+266)">Lesotho (+266)</option>
                                        <option value="Liberia (+231)">Liberia (+231)</option>
                                        <option value="Libya (+218)">Libya (+218)</option>
                                        <option value="Liechtenstein (+417)">Liechtenstein (+417)</option>
                                        <option value="Lithuania (+370)">Lithuania (+370)</option>
                                        <option value="Luxembourg (+352)">Luxembourg (+352)</option>
                                        <option value="Macao (+853)">Macao (+853)</option>
                                        <option value="Macedonia (+389)">Macedonia (+389)</option>
                                        <option value="Madagascar (+261)">Madagascar (+261)</option>
                                        <option value="Malawi (+265)">Malawi (+265)</option>
                                        <option value="Malaysia (+60)">Malaysia (+60)</option>
                                        <option value="Maldives (+960)">Maldives (+960)</option>
                                        <option value="Mali (+223)">Mali (+223)</option>
                                        <option value="Malta (+356)">Malta (+356)</option>
                                        <option value="Marshall Islands (+692)">Marshall Islands (+692)</option>
                                        <option value="Martinique (+596)">Martinique (+596)</option>
                                        <option value="Mauritania (+222)">Mauritania (+222)</option>
                                        <option value="Mayotte (+269)">Mayotte (+269)</option>
                                        <option value="Mexico (+52)">Mexico (+52)</option>
                                        <option value="Micronesia (+691)">Micronesia (+691)</option>
                                        <option value="Moldova (+373)">Moldova (+373)</option>
                                        <option value="Monaco (+377)">Monaco (+377)</option>
                                        <option value="Mongolia (+976)">Mongolia (+976)</option>
                                        <option value="Montserrat (+1664)">Montserrat (+1664)</option>
                                        <option value="Morocco (+212)">Morocco (+212)</option>
                                        <option value="Mozambique (+258)">Mozambique (+258)</option>
                                        <option value="Myanmar (+95)">Myanmar (+95)</option>
                                        <option value="Namibia (+264)">Namibia (+264)</option>
                                        <option value="Nauru (+674)">Nauru (+674)</option>
                                        <option value="Nepal (+977)">Nepal (+977)</option>
                                        <option value="Netherlands (+31)">Netherlands (+31)</option>
                                        <option value="New Caledonia (+687)">New Caledonia (+687)</option>
                                        <option value="New Zealand (+64)">New Zealand (+64)</option>
                                        <option value="Nicaragua (+505)">Nicaragua (+505)</option>
                                        <option value="Niger (+227)">Niger (+227)</option>
                                        <option value="Nigeria (+234)">Nigeria (+234)</option>
                                        <option value="Niue (+683)">Niue (+683)</option>
                                        <option value="Norfolk Islands (+672)">Norfolk Islands (+672)</option>
                                        <option value="Northern Marianas (+670)">Northern Marianas (+670)</option>
                                        <option value="Norway (+47)">Norway (+47)</option>
                                        <option value="Oman (+968)">Oman (+968)</option>
                                        <option value="Palau (+680)">Palau (+680)</option>
                                        <option value="Pakistan (+92)">Pakistan (+92)</option>
                                        <option value="Panama (+507)">Panama (+507)</option>
                                        <option value="Papua New Guinea (+675)">Papua New Guinea (+675)</option>
                                        <option value="Paraguay (+595)">Paraguay (+595)</option>
                                        <option value="Peru (+51)">Peru (+51)</option>
                                        <option value="Philippines (+63)">Philippines (+63)</option>
                                        <option value="Poland (+48)">Poland (+48)</option>
                                        <option value="Portugal (+351)">Portugal (+351)</option>
                                        <option value="Puerto Rico (+1787)">Puerto Rico (+1787)</option>
                                        <option value="Qatar (+974)">Qatar (+974)</option>
                                        <option value="Reunion (+262)">Reunion (+262)</option>
                                        <option value="Romania (+40)">Romania (+40)</option>
                                        <option value="Russia (+7)">Russia (+7)</option>
                                        <option value="Rwanda (+250)">Rwanda (+250)</option>
                                        <option value="San Marino (+378)">San Marino (+378)</option>
                                        <option value="Sao Tome & Principe (+239)">Sao Tome &amp; Principe (+239)</option>
                                        <option value="Saudi Arabia (+966)">Saudi Arabia (+966)</option>
                                        <option value="Senegal (+221)">Senegal (+221)</option>
                                        <option value="Serbia (+381)">Serbia (+381)</option>
                                        <option value="Seychelles (+248)">Seychelles (+248)</option>
                                        <option value="Sierra Leone (+232)">Sierra Leone (+232)</option>
                                        <option value="Singapore (+65)">Singapore (+65)</option>
                                        <option value="Slovak Republic (+421)">Slovak Republic (+421)</option>
                                        <option value="Slovenia (+386)">Slovenia (+386)</option>
                                        <option value="Solomon Islands (+677)">Solomon Islands (+677)</option>
                                        <option value="Somalia (+252)">Somalia (+252)</option>
                                        <option value="South Africa (+27)">South Africa (+27)</option>
                                        <option value="Spain (+34)">Spain (+34)</option>
                                        <option value="Sri Lanka (+94)">Sri Lanka (+94)</option>
                                        <option value="St. Helena (+290)">St. Helena (+290)</option>
                                        <option value="St. Kitts (+1869)">St. Kitts (+1869)</option>
                                        <option value="St. Lucia (+1758)">St. Lucia (+1758)</option>
                                        <option value="Sudan (+249)">Sudan (+249)</option>
                                        <option value="Suriname (+597)">Suriname (+597)</option>
                                        <option value="Swaziland (+268)">Swaziland (+268)</option>
                                        <option value="Sweden (+46)">Sweden (+46)</option>
                                        <option value="Switzerland (+41)">Switzerland (+41)</option>
                                        <option value="Syria (+963)">Syria (+963)</option>
                                        <option value="Taiwan (+886)">Taiwan (+886)</option>
                                        <option value="Tajikstan (+7)">Tajikstan (+7)</option>
                                        <option value="Thailand (+66)">Thailand (+66)</option>
                                        <option value="Togo (+228)">Togo (+228)</option>
                                        <option value="Tonga (+676)">Tonga (+676)</option>
                                        <option value="Trinidad & Tobago (+1868)">Trinidad &amp; Tobago (+1868)</option>
                                        <option value="Tunisia (+216)">Tunisia (+216)</option>
                                        <option value="Turkey (+90)">Turkey (+90)</option>
                                        <option value="Turkmenistan (+7)">Turkmenistan (+7)</option>
                                        <option value="Turkmenistan (+993)">Turkmenistan (+993)</option>
                                        <option value="Turks & Caicos Islands (+1649)">Turks &amp; Caicos Islands (+1649)</option>
                                        <option value="Tuvalu (+688)">Tuvalu (+688)</option>
                                        <option value="Uganda (+256)">Uganda (+256)</option>
                                        <option value="UK (+44)">UK (+44)</option>
                                        <option value="Ukraine (+380)">Ukraine (+380)</option>
                                        <option value="(+971) UAE">United Arab Emirates (+971)</option>
                                        <option value="Uruguay (+598)">Uruguay (+598)</option>
                                        <option value="USA (+1)">USA (+1)</option>
                                        <option value="Uzbekistan (+7)">Uzbekistan (+7)</option>
                                        <option value="Vanuatu (+678)">Vanuatu (+678)</option>
                                        <option value="Vatican City (+379)">Vatican City (+379)</option>
                                        <option value="Venezuela (+58)">Venezuela (+58)</option>
                                        <option value="Vietnam (+84)">Vietnam (+84)</option>
                                        <option value="Virgin Islands - British (+1284)">Virgin Islands - British (+1284)</option>
                                        <option value="Virgin Islands - US (+1340)">Virgin Islands - US (+1340)</option>
                                        <option value="Wallis & Futuna (+681)">Wallis &amp; Futuna (+681)</option>
                                        <option value="Yemen (North)(+969)">Yemen (North)(+969)</option>
                                        <option value="Yemen (South)(+967)">Yemen (South)(+967)</option>
                                        <option value="Zambia (+260)">Zambia (+260)</option>
                                        <option value="Zimbabwe (+263)">Zimbabwe (+263)</option>
                                    </select>
                                    <input type="number" id='PhoneNo' style={{ width: "70%", height: '35px', borderRadius: '5px', padding: '8px', border: '1px solid grey', fontSize: '14px' }} />
                                </div>
                            </div>
                        </div>

                        <div className='RegistrationFormColumn'>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'right', width: '100%' }}>
                                <label className="postnominal">Post-nominal letters</label>
                                <input type="text" id="PostNominalLetters" style={{ width: "100%", height: '35px', borderRadius: '5px', padding: '8px', border: '1px solid grey', fontSize: '14px' }} />
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'right', width: '100%', marginTop: '10px' }}>
                                <label style={{ fontWeight: '900', fontSize: '13px' }}>Gender</label>
                                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: '5px' }}>
                                    <div id='Male' className="newcontent" style={{ height: '35px', fontSize: '13px', borderRadius: '5px', padding: '10px', border: '1px solid grey', background: gender === 'Male' ? '#ffd700' : 'white' }} onClick={() => handleGenderClick('Male')}>Male</div>
                                    <div id='Female' className="newcontent" style={{ height: '35px', fontSize: '13px', borderRadius: '5px', padding: '10px', border: '1px solid grey', background: gender === 'Female' ? '#ffd700' : 'white' }} onClick={() => handleGenderClick('Female')}>Female</div>
                                    <div id='Other' className="newcontent" style={{ height: '35px', fontSize: '13px', borderRadius: '5px', padding: '10px', border: '1px solid grey', background: gender === 'Other' ? '#ffd700' : 'white' }} onClick={() => handleGenderClick('Other')}>Other</div>
                                </div>
                            </div> 
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'right', width: '100%', marginTop: '10px' }}>
                                <label style={{ fontWeight: '900', fontSize: '13px' }}>City</label>
                                <input type="text" id="City" style={{ width: "100%", height: '35px', borderRadius: '5px', padding: '8px', border: '1px solid grey', fontSize: '14px' }} />
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'right', width: '100%', marginTop: '10px' }}>
                                <label style={{ fontWeight: '900', fontSize: '13px' }}>State</label>
                                <select id="State" required style={{ width: "100%", height: '35px', borderRadius: '5px', paddingLeft: '5px', border: '1px solid grey', fontSize: '14px' }}>
                                    <option selected disabled>Select a State</option>
                                    <option value="Alabama">Alabama</option>
                                    <option value="Alaska">Alaska</option>
                                    <option value="Arizona">Arizona</option>
                                    <option value="Arkansas">Arkansas</option>
                                    <option value="California">California</option>
                                    <option value="Colorado">Colorado</option>
                                    <option value="Connecticut">Connecticut</option>
                                    <option value="Delaware">Delaware</option>
                                    <option value="Florida">Florida</option>
                                    <option value="Georgia">Georgia</option>
                                    <option value="Hawaii">Hawaii</option>
                                    <option value="Idaho">Idaho</option>
                                    <option value="Illinois">Illinois</option>
                                    <option value="Indiana">Indiana</option>
                                    <option value="Iowa">Iowa</option>
                                    <option value="Kansas">Kansas</option>
                                    <option value="Kentucky">Kentucky</option>
                                    <option value="Louisiana">Louisiana</option>
                                    <option value="Maine">Maine</option>
                                    <option value="Maryland">Maryland</option>
                                    <option value="Massachusetts">Massachusetts</option>
                                    <option value="Michigan">Michigan</option>
                                    <option value="Minnesota">Minnesota</option>
                                    <option value="Mississippi">Mississippi</option>
                                    <option value="Missouri">Missouri</option>
                                    <option value="Montana">Montana</option>
                                    <option value="Nebraska">Nebraska</option>
                                    <option value="Nevada">Nevada</option>
                                    <option value="New Hampshire">New Hampshire</option>
                                    <option value="New Jersey">New Jersey</option>
                                    <option value="New Mexico">New Mexico</option>
                                    <option value="New York">New York</option>
                                    <option value="North Carolina">North Carolina</option>
                                    <option value="North Dakota">North Dakota</option>
                                    <option value="Ohio">Ohio</option>
                                    <option value="Oklahoma">Oklahoma</option>
                                    <option value="Oregon">Oregon</option>
                                    <option value="Pennsylvania">Pennsylvania</option>
                                    <option value="Rhode Island">Rhode Island</option>
                                    <option value="South Carolina">South Carolina</option>
                                    <option value="South Dakota">South Dakota</option>
                                    <option value="Tennessee">Tennessee</option>
                                    <option value="Texas">Texas</option>
                                    <option value="Utah">Utah</option>
                                    <option value="Vermont">Vermont</option>
                                    <option value="Virginia">Virginia</option>
                                    <option value="Washington">Washington</option>
                                    <option value="West Virginia">West Virginia</option>
                                    <option value="Wisconsin">Wisconsin</option>
                                    <option value="Wyoming">Wyoming</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className='optionBoxxy' style={{   marginTop: '10px'}}>
                        <div className='RegistrationFormColumn'>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'right', width: '100%' }}>
                                <label style={{ fontWeight: '900', fontSize: '13px' }}>Password</label>
                                <input type="password" id="Password" style={{ width: "100%", height: '35px', borderRadius: '5px', padding: '8px', border: '1px solid grey', fontSize: '14px' }} />
                            </div>
                        </div>
                        <div className='RegistrationFormColumn'>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'right', width: '100%' }}>
                                <label style={{ fontWeight: '900', fontSize: '13px' }}>Confirm Password</label>
                                <input type="password" id="ConfirmPassword" style={{ width: "100%", height: '35px', borderRadius: '5px', padding: '8px', border: '1px solid grey', fontSize: '14px' }} />
                            </div>
                        </div>
                    </div>

                    <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', padding: '0px 30px', marginBottom: '25px'}}>
                        <div id="Error" style={{ color: 'red', fontSize: '11px', height: '15px', width: '100%', textAlign: 'center', marginTop: '10px'}}></div>
                        <button onClick={handleSubmit} id='SubmitButton' className='submit-button' style={{ width: '100%', height: '50px', borderRadius: '5px', padding: '8px', border: 'none', background: '#002366', color: 'white', fontWeight: '900', fontSize: '14px', margin: '10px 0px' }}>Next</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RegistrationVetsandTechsPage2;
