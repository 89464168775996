import { FaGoogle } from "react-icons/fa";
import { getAuth, signInWithPopup, GoogleAuthProvider, createUserWithEmailAndPassword } from 'firebase/auth';
import { getFirestore, setDoc, doc, serverTimestamp } from 'firebase/firestore';
import React, { useState } from 'react';
import Cover from '../../../1_MediaAssets/VetsandTechs/RegistrationVetsandTechs/Cover2.png'; 
import Texture from '../../../1_MediaAssets/OtherBasics/Texture.png';
import CutePets from "../../CutePets.js";
import { useNavigate, NavLink } from 'react-router-dom';

const RegistrationPetParentsPage1 = () => { 
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [state, setState] = useState('');
    const [phoneNo, setPhoneNo] = useState('');
    const navigate = useNavigate();

    const handleSubmit = () => {
        console.log("Registration process started"); // Log when registration starts
        document.getElementById("submitbutton").innerHTML = "Loading...";
        const auth = getAuth();
        const db = getFirestore();
        var validRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    
        if (!name || !email || !password || !confirmPassword || !state || !phoneNo) {
            console.log("Error: All fields are required"); // Log if fields are empty
            document.getElementById("errortext").innerHTML = "Please fill in all fields";
            document.getElementById("submitbutton").innerHTML = "Register";
            return;
        }
    
        if (!email.match(validRegex)) {
            console.log("Error: Invalid email format"); // Log if email is invalid
            document.getElementById("errortext").innerHTML = "Please enter a valid email address";
            document.getElementById("submitbutton").innerHTML = "Register";
            return;
        }
    
        if (phoneNo.length > 13 || phoneNo.length < 8) {
            console.log("Error: Invalid phone number length"); // Log if phone number length is invalid
            document.getElementById("errortext").innerHTML = "Please enter a valid phone number";
            document.getElementById("submitbutton").innerHTML = "Register";
            return;
        }
    
        if (password.length < 8) {
            console.log("Error: Password too short"); // Log if password is too short
            document.getElementById("errortext").innerHTML = "Password needs to be at least 8 characters long.";
            document.getElementById("submitbutton").innerHTML = "Register";
            return;
        }
    
        if (password !== confirmPassword) {
            console.log("Error: Passwords do not match"); // Log if passwords do not match
            document.getElementById("errortext").innerHTML = "Passwords do not match";
            document.getElementById("submitbutton").innerHTML = "Register";
            return;
        }
    
        createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                const user = userCredential.user;
                console.log("User created:", user); // Log user creation
                const userData = { 
                    Name: name,
                    EmailAddress: email,
                    PhoneNo: phoneNo,
                    State: state,
                    RegistrationTime: serverTimestamp(),
                };
    
                // Save to Firestore
                setDoc(doc(db, `Parents`, user.uid), userData)
                    .then(() => {
                        console.log("User data saved to Firestore"); // Log Firestore save success
                    })
                    .catch((firestoreError) => {
                        console.log("Error saving user data to Firestore:", firestoreError); // Log Firestore save error
                    });
    
                // Send the welcome email
                console.log("Sending welcome email..."); // Log before sending email
                fetch("http://localhost:5000/sendWelcomeEmailParent", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        email: email,
                        name: name,
                    }),
                })
                .then(response => response.json())
                .then(data => {
                    console.log("Email response:", data); // Log email response
                })
                .catch(emailError => {
                    console.log("Error sending welcome email:", emailError); // Log email sending error
                });
    
                document.getElementById("submitbutton").innerHTML = "Register";
                navigate('/RegistrationPetParentsPage2', { state: { userId: user.uid } });
            })
            .catch((error) => {
                console.log("Registration error:", error.message); // Log registration error
                document.getElementById("errortext").innerHTML = "Registration failed: " + error.message;
                document.getElementById("submitbutton").innerHTML = "Register";
            });
    };
    
    const handleGoogleSignUp = async () => {
        console.log("Google sign-up process started"); // Log when Google sign-up starts
        const auth = getAuth();
        const provider = new GoogleAuthProvider();
    
        try {
            const result = await signInWithPopup(auth, provider);
            const user = result.user;
            console.log("Google user signed in:", user); // Log Google user data
            const db = getFirestore();
            const userData = { 
                Name: user.displayName || 'Anonymous',
                EmailAddress: user.email,
                PhoneNo: '',
                State: '',
                RegistrationTime: serverTimestamp(),
            };
    
            await setDoc(doc(db, `Parents`, user.uid), userData);
            console.log("User data saved to Firestore from Google sign-up"); // Log Firestore save success
    
            // Send the welcome email
            console.log("Sending welcome email for Google sign-up..."); // Log before sending email
            fetch("http://localhost:5000/sendWelcomeEmailParent", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    email: user.email,
                    name: user.displayName || 'Anonymous',
                }),
            })
            .then(response => response.json())
            .then(data => {
                console.log("Email response:", data); // Log email response
            })
            .catch(emailError => {
                console.log("Error sending welcome email:", emailError); // Log email sending error
            });
    
            navigate('/RegistrationPetParentsPage2', { state: { userId: user.uid } });
        } catch (error) {
            console.log("Google Sign-In error:", error.message); // Log Google Sign-In error
            document.getElementById("errortext").innerHTML = "Google Sign-In failed: " + error.message;
        }
    };
    


    return (
        <div style={{minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden', backgroundImage:`url('${Cover}')`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundAttachment: 'fixed'}}>
            <div className='RegistrationWhiteBox' style={{backgroundImage:`url('${Texture}')`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', marginTop: '30px', marginBottom: '30px'}}>

                <div className="welcome-box">
                    <h2 style={{fontWeight: '900', color: '#002366', fontSize: '30px'}}>Welcome Pet Parent!</h2>
                    <h5 style={{fontWeight: '900', marginTop: '-8px', color: '#686869'}}>Tell us about yourself</h5>
                    <p style={{ fontWeight: '900', marginTop: '10px', color: '#686869' }}>Already have an account? 
                        <NavLink to='/LoginPetParents' style={{color: '#002366', textDecoration: 'underline', marginLeft: '10px'}}>
                            Login
                        </NavLink>
                    </p>
                    <CutePets />
                </div>

                <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column'}}>
                    <div className='optionBox' style={{margin: '0', height: '100%', justifyContent: 'flex-start', alignItems: 'flex-start'}}> 
                        <div className='OnlyColumnPage2'>
                            <div style={{display: 'flex', flexDirection: 'column', width: '100%', marginTop: '10px'}}>
                                <button onClick={handleGoogleSignUp} style={{ width: '100%', marginTop: '5px', backgroundColor: '#4285F4', color: 'white' }}><FaGoogle style={{margin: '0', padding: '0', fontSize: '20px', marginRight: '10px'}}/> Sign up with Google</button>
                            </div>
                            <div style={{display: 'flex', flexDirection: 'column', width: '100%', marginTop: '10px'}}>
                                <label style={{fontWeight: '900', fontSize: '13px'}}>Name</label>
                                <input type="text" value={name} onChange={(e) => setName(e.target.value)} style={{width:"100%", height:'35px', borderRadius: '5px', padding: '8px', border: '1px solid grey', fontSize: '14px'}}/>
                            </div>
                            <div style={{display: 'flex', flexDirection: 'column', width: '100%', marginTop: '10px'}}>
                                <label style={{fontWeight: '900', fontSize: '13px'}}>Email</label>
                                <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} style={{width:"100%", height:'35px', borderRadius: '5px', padding: '8px', border: '1px solid grey', fontSize: '14px'}}/>
                            </div>

                            <div style={{display: 'flex', flexDirection: 'row', width: '100%', marginTop: '10px', gap: '5px'}}>
                                <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
                                    <label style={{fontWeight: '900', fontSize: '13px'}}>Phone No.</label>
                                    <input type="number" value={phoneNo} onChange={(e) => setPhoneNo(e.target.value)} style={{width:"100%", height:'35px', borderRadius: '5px', padding: '8px', border: '1px solid grey', fontSize: '14px'}}/>
                                </div>
                                <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
                                    <label style={{fontWeight: '900', fontSize: '13px'}}>State</label>
                                    <select value={state} onChange={(e) => setState(e.target.value)} required style={{background: "white", border: "1px solid grey", borderRadius: '5px', width: "100%", height: '35px', color: "black", paddingLeft: '5px', paddingRight: '5px', fontSize: '14px', backgroundColor: 'white'}}>
                                        <option disabled value="">Select a state</option>
                                        <option value="Alabama">Alabama</option>
                                        <option value="Alaska">Alaska</option>
                                        <option value="Arizona">Arizona</option>
                                        <option value="Arkansas">Arkansas</option>
                                        <option value="California">California</option>
                                        <option value="Colorado">Colorado</option>
                                        <option value="Connecticut">Connecticut</option>
                                        <option value="Delaware">Delaware</option>
                                        <option value="District Of Columbia">District Of Columbia</option>
                                        <option value="Florida">Florida</option>
                                        <option value="Georgia">Georgia</option>
                                        <option value="Hawaii">Hawaii</option>
                                        <option value="Idaho">Idaho</option>
                                        <option value="Illinois">Illinois</option>
                                        <option value="Indiana">Indiana</option>
                                        <option value="Iowa">Iowa</option>
                                        <option value="Kansas">Kansas</option>
                                        <option value="Kentucky">Kentucky</option>
                                        <option value="Louisiana">Louisiana</option>
                                        <option value="Maine">Maine</option>
                                        <option value="Maryland">Maryland</option>
                                        <option value="Massachusetts">Massachusetts</option>
                                        <option value="Michigan">Michigan</option>
                                        <option value="Minnesota">Minnesota</option>
                                        <option value="Mississippi">Mississippi</option>
                                        <option value="Missouri">Missouri</option>
                                        <option value="Montana">Montana</option>
                                        <option value="Nebraska">Nebraska</option>
                                        <option value="Nevada">Nevada</option>
                                        <option value="New Hampshire">New Hampshire</option>
                                        <option value="New Jersey">New Jersey</option>
                                        <option value="New Mexico">New Mexico</option>
                                        <option value="New York">New York</option>
                                        <option value="North Carolina">North Carolina</option>
                                        <option value="North Dakota">North Dakota</option>
                                        <option value="Ohio">Ohio</option>
                                        <option value="Oklahoma">Oklahoma</option>
                                        <option value="Oregon">Oregon</option>
                                        <option value="Pennsylvania">Pennsylvania</option>
                                        <option value="Rhode Island">Rhode Island</option>
                                        <option value="South Carolina">South Carolina</option>
                                        <option value="South Dakota">South Dakota</option>
                                        <option value="Tennessee">Tennessee</option>
                                        <option value="Texas">Texas</option>
                                        <option value="Utah">Utah</option>
                                        <option value="Vermont">Vermont</option>
                                        <option value="Virginia">Virginia</option>
                                        <option value="Washington">Washington</option>
                                        <option value="West Virginia">West Virginia</option>
                                        <option value="Wisconsin">Wisconsin</option>
                                        <option value="Wyoming">Wyoming</option>
                                    </select>
                                </div>
                            </div>
        
                            <div style={{display: 'flex', flexDirection: 'row', width: '100%', marginTop: '10px', gap: '5px'}}>
                                <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
                                    <label style={{fontWeight: '900', fontSize: '13px'}}>Password</label>
                                    <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} style={{width:"100%", height:'35px', borderRadius: '5px', padding: '8px', border: '1px solid grey', fontSize: '14px'}}/>
                                </div>
                                <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
                                    <label style={{fontWeight: '900', fontSize: '13px'}}>Confirm Password</label>
                                    <input type="password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} style={{width:"100%", height:'35px', borderRadius: '5px', padding: '8px', border: '1px solid grey', fontSize: '14px'}}/>
                                </div>
                            </div>

                            <div id="errortext" style={{fontSize: '11px', color: 'red', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '10px', marginTop: '12px'}}></div>
                            <div style={{display: 'flex', flexDirection: 'column', width: '100%', margin: '10px 0px 20px 0px'}}>
                                <button onClick={handleSubmit} id="submitbutton" style={{ width: '100%' }}>Register</button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default RegistrationPetParentsPage1;
