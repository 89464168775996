import { FaLock } from "react-icons/fa6";
import { FaEnvelope } from "react-icons/fa";
import React, { useEffect, useState } from 'react';
import { getAuth, updatePassword, reauthenticateWithCredential, EmailAuthProvider, onAuthStateChanged, sendEmailVerification } from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import NavbarPetParentsDashboard from '../../../Navbars/NavbarPetParentsDashboard';
import LoadingScreen from '../../../LoadingScreen/index.js';

const LoginAndSecurity = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [passwordMessage, setPasswordMessage] = useState('');
  const [verificationMessage, setVerificationMessage] = useState('');
  const [emailVerified, setEmailVerified] = useState(false);

  const auth = getAuth();
  const db = getFirestore();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setUser(user);
        setEmailVerified(user.emailVerified);
        const userDoc = doc(db, 'Users', user.uid);
        const docSnap = await getDoc(userDoc);
        if (docSnap.exists()) {
        }
        setLoading(false);
      } else {
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, [auth, db]);

  const handleUpdatePassword = async () => {
    if (!user) return;

    if (newPassword.length < 8) {
      setPasswordMessage('New password must be at least 8 characters long.');
      return;
    }

    if (newPassword === oldPassword) {
      setPasswordMessage('New password must be different from the old password.');
      return;
    }

    try {
      const credential = EmailAuthProvider.credential(user.email, oldPassword);
      await reauthenticateWithCredential(user, credential);
      await updatePassword(user, newPassword);
      setPasswordMessage('Password updated successfully!');
    } catch (error) {
      if (error.code === 'auth/wrong-password') {
        setPasswordMessage('Old password is incorrect.');
      } else {
        setPasswordMessage('Error updating password. Please try again.');
      }
    }
    setTimeout(() => setPasswordMessage(''), 4000);
  };

  const handleSendVerificationEmail = async () => {
    if (user && !user.emailVerified) {
      try {
        await sendEmailVerification(user);
        setVerificationMessage('Verification email sent!');
      } catch (error) {
        setVerificationMessage('Error sending verification email. Please try again.');
      }
      setTimeout(() => setVerificationMessage(''), 4000);
    }
  };

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100vw', height: '100%', background: '#f5f5f5' }}>
      <div className='dashboardmain' style={{ paddingBottom: '50px' }}>
        <NavbarPetParentsDashboard />
        <div style={{ padding: '10px 0px', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'column', background: '#fff', borderRadius: '10px', width: '90%', marginTop: '50px', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)' }}>
            
            <div style={{ width: '100%', padding: '15px', borderBottom: '1px solid #dedcd7' }}>
                <h4 style={{ fontWeight: '900' }}><FaLock style={{fontSize: '25px', margin: '0 5px 3px 0', color: '#002366'}}/> Password</h4>
                <p style={{ fontSize: '14px', maxWidth: '700px' }}>It's good practice to change your password every 90 days. Do not share your password with anyone, and try to use unique passwords. Rex Vets employees will never ask for your password.</p>
                <div style={{ marginTop: '20px', display: 'flex', flexDirection: 'column' }}>
                    <label style={{ display: 'block', marginBottom: '5px' }}>Old Password</label>
                    <input type="password" value={oldPassword} onChange={(e) => setOldPassword(e.target.value)} style={{ width: '100%', maxWidth: '400px', padding: '8px', marginBottom: '10px', borderRadius: '5px', border: '1px solid #ccc' }} />
                    <label style={{ display: 'block', marginBottom: '5px' }}>New Password</label>
                    <input type="password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} style={{ width: '100%', maxWidth: '400px', padding: '8px', borderRadius: '5px', border: '1px solid #ccc' }} />
                    <button type="button" onClick={handleUpdatePassword} style={{ marginTop: '15px', width: '100%', maxWidth: '400px', padding: '10px 20px', borderRadius: '5px', cursor: 'pointer' }}>
                    Update password
                    </button>
                    {passwordMessage && <p style={{ color: passwordMessage.includes('successfully') ? 'green' : 'red', marginTop: '10px', fontSize: '13px', height: '14px' }}>{passwordMessage}</p>}
                </div>
            </div>

            <div style={{ width: '100%', padding: '15px', borderBottom: '1px solid #dedcd7', marginTop: '20px' }}>
                <h4 style={{ fontWeight: '900' }}><FaEnvelope style={{fontSize: '25px', margin: '0 10px 3px 0', color: '#002366'}}/> Login & Security</h4>
                <p style={{ fontSize: '14px', maxWidth: '700px' }}>Your email address is used to login to your Rex Vets account. A verified email is required to access certain areas. If you would like to change your email address, please contact us for assistance.</p>
                <div style={{ marginTop: '20px' }}>
                    <label style={{ display: 'block', marginBottom: '5px' }}>Email Address</label>
                    <input type="text" value={user?.email || ''} disabled style={{ width: '100%', maxWidth: '400px', padding: '8px', borderRadius: '5px', border: '1px solid #ccc' }} />
                    <p style={{ marginTop: '10px', fontSize: '13px' }}>{emailVerified ? '✓ Verified!' : 'Not Verified'}</p>
                    {!emailVerified && (
                    <button type="button" onClick={handleSendVerificationEmail} style={{ marginTop: '15px', padding: '10px 20px', width: '100%', maxWidth: '400px', borderRadius: '5px', cursor: 'pointer' }}>
                        Verify email
                    </button>
                    )}
                    {verificationMessage && <p style={{ color: verificationMessage.includes('sent') ? 'green' : 'red', marginTop: '10px', fontSize: '13px', height: '14px' }}>{verificationMessage}</p>}
                </div>
            </div>

        </div>
      </div>
    </div>
  );
};

export default LoginAndSecurity;