import { FaGoogle } from "react-icons/fa";
import Cover from '../../../1_MediaAssets/VetsandTechs/RegistrationVetsandTechs/Cover2.png';
import Texture from '../../../1_MediaAssets/OtherBasics/Texture.png';
import { NavLink, useLocation } from "react-router-dom";
import CutePets from "../../CutePets.js";
import { getAuth, signInWithEmailAndPassword, signInWithPopup, browserLocalPersistence, onAuthStateChanged, setPersistence } from "firebase/auth";
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { doc, getDoc, getFirestore } from "firebase/firestore";
import { provider } from '../../../../firebase/index.js';

const LoginPetParents = () => { 
    const navigate = useNavigate();
    const db = getFirestore();
    const auth = getAuth();
    const location = useLocation();
 
    // Check authentication status and user existence on component mount
    useEffect(() => {
        const checkAuthAndRedirect = async () => {
            try {
                await setPersistence(auth, browserLocalPersistence);
                onAuthStateChanged(auth, async (user) => {
                    if (user) {
                        const userId = user.uid;
                        const docRef = doc(db, "Parents", userId);
                        const docSnap = await getDoc(docRef);

                        if (docSnap.exists()) {
                            navigate('/DashboardPetParents', { state: { user: docSnap.data() } });
                        } else {
                            document.getElementById("message").innerHTML = "No such user! Contact admin!";
                        }
                    }
                });
            } catch (error) {
                console.error("Error checking authentication:", error);
            }
        };
        checkAuthAndRedirect();
    }, [auth, db, navigate]);

    useEffect(() => {
        if (location.state) {
            console.log(location.state.message);
            const messageElement = document.getElementById('message');
            if (messageElement) {
                messageElement.innerHTML = location.state.message + '!';
            }
        }
    }, [location.state]);

    const handleSubmit = () => {
        document.getElementById("buttoon").innerHTML = "Loading...";
    
        const email = document.getElementById('email').value;
        const password = document.getElementById('password').value;
    
        if (!email || !password) {
            document.getElementById("message").innerHTML = "INPUT ALL FIELDS!";
            document.getElementById("buttoon").innerHTML = "Sign in";
            return;
        }
    
        signInWithEmailAndPassword(auth, email, password)
            .then(async (userCredential) => {
                const user = userCredential.user;
                localStorage.setItem('userId', user.uid);
    
                const docRef = doc(db, "Parents", user.uid);
                try {
                    const docSnap = await getDoc(docRef);
                    if (docSnap.exists()) {
                        navigate('/DashboardPetParents', { state: { user: docSnap.data() } });
                    } else {
                        document.getElementById("message").innerHTML = "No such user! Contact admin support@rexvets.com";
                    }
                } catch (docError) {
                    console.error("Error fetching document:", docError);
                }
            })
            .catch((error) => {
                console.error("Authentication error:", error);
                let errorMessage = "An error occurred. Please try again.";
    
                switch (error.code) {
                    case 'auth/invalid-email':
                        errorMessage = "Invalid email address.";
                        break;
                    case 'auth/user-not-found':
                        errorMessage = "No user found with this email.";
                        break;
                    case 'auth/wrong-password':
                        errorMessage = "Incorrect password.";
                        break;
                    case 'auth/invalid-login-credentials':
                        errorMessage = "Invalid login credentials.";
                        break;
                    case 'auth/network-request-failed':
                        errorMessage = "Network error. Check your connection.";
                        break;
                    case 'auth/too-many-requests':
                        errorMessage = "Too many requests. Try again later.";
                        break;
                    default:
                        errorMessage = "An unexpected error occurred. Please try again.";
                }
    
                document.getElementById("message").innerHTML = errorMessage;
                document.getElementById("buttoon").innerHTML = "Sign in";
            });
    };
    
    const handleGoogleSignIn = async () => {
        try {
            const result = await signInWithPopup(auth, provider);
            const user = result.user;

            // Save user ID to localStorage
            localStorage.setItem('userId', user.uid);

            // Check if the user exists in the Parents collection
            const docRef = doc(db, "Parents", user.uid);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                navigate('/DashboardPetParents', { state: { user: docSnap.data() } });
            } else {
                document.getElementById("message").innerHTML = "No such user! Contact admin support@rexvets.com";
            }
        } catch (error) {
            console.error("Error during Google sign-in:", error);
            document.getElementById("message").innerHTML = "Error during Google sign-in.";
        }
    };

    function navigateUser() {
        navigate('/ForgotPasswordPetParents');
    }

    return (
        <div style={{ minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden', backgroundImage: `url('${Cover}')`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
            <div className='RegistrationWhiteBox' style={{ backgroundImage: `url('${Texture}')`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', marginTop: '30px', marginBottom: '30px' }}>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', padding: '30px', flexDirection: 'column' }}>
                    <h2 style={{ fontWeight: '900', color: '#002366', fontSize: '30px' }}>Welcome Back Pet Parent!</h2>
                    <h5 style={{ fontWeight: '900', marginTop: '-8px', color: '#686869' }}>Sign in to the dashboard</h5>
                    <p style={{ fontWeight: '900', marginTop: '10px', color: '#686869' }}>Do not have an account? 
                        <NavLink to='/RegistrationPetParentsPage1' style={{color: '#002366', textDecoration: 'underline', marginLeft: '10px'}}>
                            Register
                        </NavLink>
                    </p>
                    <CutePets />
                </div>
                <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column', marginTop: '-15px', marginBottom: '10px'}}>
                    <div className='optionBox' style={{ marginTop: '0', height: '100%', justifyContent: 'flex-start', alignItems: 'flex-start'}}>
                        <div className='OnlyColumnPage2' style={{ padding: '0px 30px 0px 30px' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginTop: '10px' }}>
                                <label style={{ fontWeight: '900', fontSize: '13px' }}>Email</label>
                                <input type="text" id='email' style={{ width: "100%", height: '35px', borderRadius: '5px', padding: '8px', border: '1px solid grey', fontSize: '14px' }} />
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginTop: '10px' }}>
                                <label style={{ fontWeight: '900', fontSize: '13px' }}>Password</label>
                                <input type="password" id='password' style={{ width: "100%", height: '35px', borderRadius: '5px', padding: '8px', border: '1px solid grey', fontSize: '14px' }} />
                            </div>
                            <p onClick={navigateUser} style={{ marginTop: "10px", textDecoration: 'underline', color: 'gray', cursor: 'pointer', fontSize: '13px' }}>Forgot Password</p>
                            <div id='message' style={{ margin: "10px 0px", color: 'red', fontSize: '11px', width: '100%', textAlign: 'center', height: '13px' }}></div>
                            
                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', flexDirection: 'column'}}>
                                <button id='buttoon' style={{ width: '100%' }} onClick={handleSubmit}>Sign in</button>
                                <button onClick={handleGoogleSignIn} style={{ width: '100%', marginTop: '5px', backgroundColor: '#4285F4', color: 'white' }}><FaGoogle style={{margin: '0', padding: '0', fontSize: '20px', marginRight: '10px'}}/> Sign in with Google</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoginPetParents;
