import React from "react";
import Navbar from '../../Navbars/NavbarMain/NavbarMain.js';
import Footer from '../../Navbars/Footer/index.js';
import { NavLink } from 'react-router-dom';

const SupportUs = () => {
  return (
    <>
    <Navbar />
    <div className="supportus-background">
      
        <div className="GetAPrescription-content">
          <h1 className="GetAPrescription-title">Support Rex Vets: How You Can Help</h1>
          <p className="OurMission-description">As a non-profit organization, Rex Vets depends on the kindness and generosity of our community to expand our telehealth services. There are several ways you can support our mission.
</p>
        </div>
      </div>
      <div className="supportus-options">
      <div className="parrow"><p style={{fontWeight:'700', marginRight:'30px', fontSize:'1.5rem'}}>Every act of support helps us provide critical care, no matter where pets and their families are located</p>
      </div>
      <div className="supportus-option">
      <div className="supportus-option-height"><i className="fas fa-donate supportus-icon"></i>
        <h3 className="supportus-title">Donate</h3>
        <p className="supportus-description">Your contribution directly funds our telehealth services, making virtual veterinary care accessible to all.</p></div>
        <NavLink to="/faq">
  <button className="supportus-button">Donate Now</button>
</NavLink>
      </div>
      <div className="supportus-option">
      <div className="supportus-option-height"><i className="fas fa-hands-helping supportus-icon"></i>
        <h3 className="supportus-title">Volunteer</h3>
        <p className="supportus-description">Help us spread the word about our telehealth services and support our virtual events and outreach programs.</p></div>
        <button className="supportus-button">Volunteer Opportunities</button>
      </div>
      <div className="supportus-option">
        <div className="supportus-option-height"><i className="fas fa-handshake supportus-icon"></i>
        <h3 className="supportus-title">Partner with Us</h3>
        <p className="supportus-description">Are you an organization or business committed to making a difference? Partner with Rex Vets to help us expand our telehealth reach.</p></div>
        <a href="#" className="supportus-button">Learn More</a>
        
      </div>
      
    </div>

<Footer/>
</>
  );
};

export default SupportUs;