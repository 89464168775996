import { MdAlternateEmail } from "react-icons/md";
import { FaTiktok } from "react-icons/fa6";
import { FaPhoneVolume, FaWhatsapp } from "react-icons/fa6";
import { NavLink } from 'react-router-dom';
import { FaRegHeart } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { IoLocation } from "react-icons/io5";
import { MdPlayArrow } from "react-icons/md";
import './Footer.css';
import React from 'react';
import { FaInstagram, FaFacebook} from 'react-icons/fa'; // Import icons from react-icons library
// import { FaXTwitter } from "react-icons/fa6";
import FooterBG from '../../1_MediaAssets/OtherBasics/FooterBG3.png';
import Logo from '../../1_MediaAssets/Homepage/Logo.png';
 
const Footer = () => {
  return ( 
    <div style={{width: '100vw', display: 'flex', justifyContent: 'center', alignItems: 'flex-end', background: '#002366', height: '100%', flexDirection: 'row', backgroundImage: `url('${FooterBG}')`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}>
    <footer className="main-footer">
      <div className="main-footer__top" style={{ width: '100%', padding: '0', margin: '0px 0px 30px 0px'}}>
        <div className="container" style={{width: '100vw', padding: '0', margin: '0'}}>
          <div className="row" style={{width: '100vw', padding: '0', margin: '0'}}>

            <div className="col-xl-3 col-lg-12 col-md-12 wow fadeInUp" style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', padding: '10px'}} data-wow-delay="100ms">
              <div className="footer-widget-two__column footer-widget-two__about">
                <div className="footer-widget-two__logo">
                  <a href="/">
                    <img src={Logo} style={{ width: '100px', marginTop: '10px' }} alt="Logo" />
                  </a>
                </div>
                <p className="footer-widget-two__about-text" style={{ fontSize: '14px', lineHeight: '1.5', color: 'white', width: '90%' }}>
                Rex Vets is a registered 501(c)(3) non-profit organization dedicated to providing free veterinary care through telehealth. EIN: .
                </p>
                
                  <div style={{display: 'flex', justifyContent:'flex-start', alignItems: 'center', gap: '10px', width: '80%', marginTop: '-10px'}}>                    
                    <a href="https://www.instagram.com/rexvets" style={{textDecoration: 'none', color: '#fff'}}> 
                        <FaInstagram size={25} style={{color: '#FFE699'}} />
                    </a>
                    <a href="https://www.facebook.com/profile.php?id=61565972409402" style={{textDecoration: 'none', color: '#fff'}}>
                        <FaFacebook size={25} style={{color: '#FFE699'}} />
                    </a> 
                    <a href="https://www.tiktok.com/@rexvets" style={{textDecoration: 'none', color: '#fff'}}>
                        <FaTiktok size={27} style={{color: '#FFE699'}} />
                    </a>
                    <a href="mailto:support@rexvets.com" style={{textDecoration: 'none', color: '#fff'}}>
                        <MdAlternateEmail size={27} style={{color: '#FFE699'}} />
                    </a>
                  </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-12 col-md-12 wow fadeInUp" style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', padding: '10px'}} data-wow-delay="200ms">
              <div className="footer-widget-two__column footer-widget-two__link">
                <div className="footer-widget-two__title-box">
                  <h1 className="footer-widget-two__title" style={{color: '#FFE699'}}>Sitemap</h1>
                </div>
                <div className="footer-widget-two__link-box">
                  <ul className="footer-widget-two__link-list list-unstyled">
                    <li><NavLink style={{ fontSize: '14px', lineHeight: '1.5', textDecoration: 'none' }} to="/"> <MdPlayArrow style={{marginRight: '4px'}} /> Home</NavLink></li>
                    <li><NavLink style={{ fontSize: '14px', lineHeight: '1.5', textDecoration: 'none' }} to="/PetParents"> <MdPlayArrow style={{marginRight: '4px'}} /> For pet parents</NavLink></li>
                    <li><NavLink style={{ fontSize: '14px', lineHeight: '1.5', textDecoration: 'none' }} to="/VetsandTechs"> <MdPlayArrow style={{marginRight: '4px'}} /> For vets & techs</NavLink></li>
                    <li><NavLink style={{ fontSize: '14px', lineHeight: '1.5', textDecoration: 'none' }} to="/About"> <MdPlayArrow style={{marginRight: '4px'}} /> About</NavLink></li>
                    <li><NavLink style={{ fontSize: '14px', lineHeight: '1.5', textDecoration: 'none' }} to="/Blogs"> <MdPlayArrow style={{marginRight: '4px'}} /> Blogs</NavLink></li>
                    <li><NavLink style={{ fontSize: '14px', lineHeight: '1.5', textDecoration: 'none' }} to="/Support"> <MdPlayArrow style={{marginRight: '4px'}} /> Support</NavLink></li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-12 col-md-12 wow fadeInUp" style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', padding: '10px'}} data-wow-delay="200ms">
              <div className="footer-widget-two__column footer-widget-two__link">
                <div className="footer-widget-two__title-box">
                  <h1 className="footer-widget-two__title" style={{color: '#FFE699'}}>Useful Links</h1>
                </div>
                <div className="footer-widget-two__link-box">
                  <ul className="footer-widget-two__link-list list-unstyled">
                    
                    <li><NavLink style={{ fontSize: '14px', lineHeight: '1.5', textDecoration: 'none' }} to="/NonProfitPage"> <MdPlayArrow style={{marginRight: '4px'}} /> Non Profit Status</NavLink></li>
                    <li><NavLink style={{ fontSize: '14px', lineHeight: '1.5', textDecoration: 'none' }} to="/SupportUs"> <MdPlayArrow style={{marginRight: '4px'}} /> Support Us</NavLink></li>
                    <li><NavLink style={{ fontSize: '14px', lineHeight: '1.5', textDecoration: 'none' }} to="/FinancialTransparency"> <MdPlayArrow style={{marginRight: '4px'}} /> Financial Transparency</NavLink></li>
                    <li><NavLink style={{ fontSize: '14px', lineHeight: '1.5', textDecoration: 'none' }} to="/PrivacyPolicy"> <MdPlayArrow style={{marginRight: '4px'}} /> Privacy Policy</NavLink></li>
                    <li><NavLink style={{ fontSize: '14px', lineHeight: '1.5', textDecoration: 'none' }} to="/RefundPolicy"> <MdPlayArrow style={{marginRight: '4px'}} /> Refund Policy</NavLink></li>
                    <li><NavLink style={{ fontSize: '14px', lineHeight: '1.5', textDecoration: 'none' }} to="/TermsandConditions"> <MdPlayArrow style={{marginRight: '4px'}} /> Terms and Conditions</NavLink></li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-12 col-md-12 wow fadeInUp" style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', padding: '10px'}} data-wow-delay="300ms">
              <div className="footer-widget-two__column footer-widget__contact">
                <div className="footer-widget-two__title-box">
                  <h1 className="footer-widget-two__title" style={{color: '#FFE699'}}>Contact Us</h1>
                </div>
                <ul className="footer-widget-two__contact-list" style={{margin: '0', padding: '0'}}>

                  <li>
                    <div className="icon">
                        <FaPhoneVolume style={{ fontSize: '24px', color: '#FFE699', marginRight: '6px' }} />
                    </div>
                    <div className="text">
                      <p style={{ fontSize: '14px', lineHeight: '1.5' }}><a style={{textDecoration: 'none'}} href="tel:+18888080495">Phone: +1 (888) 808-0495</a></p>
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                        <MdEmail style={{ fontSize: '30px', color: '#FFE699' }} />
                    </div>
                    <div className="text">
                      <p style={{ fontSize: '14px', lineHeight: '1.5' }}><a style={{textDecoration: 'none'}} href="mailto:support@rexvets.com">support@rexvets.com</a></p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="main-footer__bottom" >
        <div className="container">
          <div className="main-footer__bottom-inner">
            <p className="main-footer__bottom-text" style={{fontWeight: '300'}}>Made with <FaRegHeart style={{color: '#FFE699', marginBottom: '3px'}} /> for ©2024 Rex Vets.</p>
            <p className="main-footer__bottom-text" style={{fontWeight: '300'}}> All rights reserved.</p>
          </div>
        </div>
      </div>
    </footer>
    </div>
  );
};

export default Footer;
