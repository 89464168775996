import React, { useState, useRef, useEffect } from 'react';
import NavbarVetsandTechsDashboard from '../../../Navbars/NavbarVetsandTechsDashboard/index';
import { MdAccountCircle } from "react-icons/md";
import emailjs from '@emailjs/browser';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { FaCloudUploadAlt } from 'react-icons/fa';

const HelpVetsandTechs = () => {
  const form = useRef();
  const [userDetails, setUserDetails] = useState({});
  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const fetchUserDetails = async () => {
      const auth = getAuth();
      const user = auth.currentUser;
      if (user) {
        const db = getFirestore();
        const userId = user.uid;
        const q1 = query(collection(db, "Doctors/Technician/Technician"), where("uid", "==", userId));
        const q2 = query(collection(db, "Doctors/Veterinarian/Veterinarian"), where("uid", "==", userId));
  
        let querySnapshot = await getDocs(q1);
        if (querySnapshot.empty) {
          querySnapshot = await getDocs(q2);
        }
  
        if (!querySnapshot.empty) {
          const userDoc = querySnapshot.docs[0];
          setUserDetails(userDoc.data());
          setUserId(userDoc.id);
        }
      }
    };
    fetchUserDetails();
  }, []);
  

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file);

    const reader = new FileReader();
    reader.onloadend = () => {
      setImageUrl(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const uploadImageToFirebase = async () => {
    if (!image) return null;
    const storage = getStorage();
    const storageRef = ref(storage, `Help/${image.name}`);
    await uploadBytes(storageRef, image);
    const url = await getDownloadURL(storageRef);
    return url;
  };

  const sendEmail = async (e) => {
    e.preventDefault();
    document.getElementById("SubmitButton").innerHTML = "Loading...";

    const imageUploadUrl = await uploadImageToFirebase();

    const formData = new FormData(form.current);

    const emailParams = {
      fullName: userDetails.FirstName || '',
      emailAddress: userDetails.EmailAddress || '',
      phoneNo: userDetails.PhoneNo || '',
      state: userDetails.State || '',
      subject: formData.get('subject'),
      message: formData.get('message'),
      image: imageUploadUrl,
      userType: "Doctor",
      userID: userId,
    };

    emailjs.send('service_cs4xaie', 'template_5satimf', emailParams, 'lyc-l8dRR0bHdWw_O')
      .then((result) => {
        console.log(result.text);
        document.getElementById('errorMessage').style.color = "green";
        document.getElementById('errorMessage').innerHTML = "We will get back to you soon!";
        document.getElementById("SubmitButton").innerHTML = "Submit";
        setTimeout(() => {
          document.getElementById('errorMessage').innerHTML = "";
        }, 4000);
        e.target.reset();
        setImage(null);
        setImageUrl(null);
      }, (error) => {
        console.log(error.text);
        document.getElementById('errorMessage').innerHTML = "Error sending message. Please try again later.";
        setTimeout(() => {
          document.getElementById('errorMessage').innerHTML = "";
        }, 4000);
      });
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100vw', height: '100%', background: '#fff5db', paddingBottom: '200px' }}>
      <div className='dashboardmain'>
        <NavbarVetsandTechsDashboard />
        <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'column', background: '#fff', borderRadius: '10px', width: '90%', marginTop: '50px', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)' }}>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column', width: '100%', padding: '15px', borderBottom: '1px solid #dedcd7' }}>
            <h4 style={{ padding: '0', margin: '0', display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: '900' }}>
              <MdAccountCircle style={{ fontSize: '30px', margin: '0px 10px 0px 5px', color: '#0849c1' }} />
              Help
            </h4>
          </div>

          <form ref={form} onSubmit={sendEmail} className='HelpForm' style={{ width: '90%', padding: '15px', background: 'transparent', margin: '20px 0px' }}>
            <div style={{width: '100%', background: '#f2f2f2', borderRadius: '5px'}}>
              <input className='inputHelp' type="text" name="name" value={userDetails.FirstName || ''} readOnly />
            </div>
            <div style={{width: '100%', background: '#f2f2f2', borderRadius: '5px'}}>
              <input className='inputHelp' type="email" name="emailAddress" value={userDetails.EmailAddress || ''} readOnly />
            </div>
            <div style={{width: '100%', background: '#f2f2f2', borderRadius: '5px'}}>
              <input className='inputHelp' type="text" name="phoneNo" value={userDetails.PhoneNo || ''} readOnly />
            </div>
            <div style={{width: '100%', background: '#f2f2f2', borderRadius: '5px'}}>
              <input className='inputHelp' type="text" name="state" value={userDetails.State || ''} readOnly />
            </div>
            <div style={{width: '100%'}}>
              <input className='inputHelp' required placeholder="Subject" type="text" name="subject"/>
            </div>
            <div style={{width: '100%'}}>
              <textarea className='inputHelp' required placeholder="Message" cols="30" rows="3" name="message"></textarea>
            </div>
            <div className="file-input">
              <input type="file" name="image" onChange={handleImageChange} style={{ display: 'none' }} id="fileUpload" />
              <label htmlFor="fileUpload" className="file-input-label">
                <FaCloudUploadAlt style={{ fontSize: '30px', marginRight: '10px' }} />
                {image ? image.name : 'Upload Screenshot'}
              </label>
            </div>
            <div className='errorMessage' id='errorMessage' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', color: 'red', fontSize: '10px', height: '12px' }}></div>
            <button id="SubmitButton" style={{ fontSize: '14px', width: '100%', background: '#002366', borderRadius: '5px', height: '50px' }}>Submit</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default HelpVetsandTechs;
