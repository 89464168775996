import NavbarPetParentsDashboard from '../../../Navbars/NavbarPetParentsDashboard/index.js';
import { FaUserDoctor } from "react-icons/fa6";
import { useState, useEffect } from 'react';
import DocDP from './vetty.png'; 
import CatDP from '../../../1_MediaAssets/OtherBasics/cat.jpg'; 
import { MdArrowRightAlt } from "react-icons/md";
import { doc, getDoc, getFirestore } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { onAuthStateChanged, getAuth } from "firebase/auth";
import { NavLink } from 'react-router-dom';
import LoadingScreen from '../../../LoadingScreen/index.js';

const AppointmentsPetParent = () => {
    const db = getFirestore();
    const auth = getAuth();
    const [allAppointments, setAllAppointments] = useState([]);
    const [loading, setLoading] = useState(true);
    const [filter, setFilter] = useState("Upcoming");
    const navigate = useNavigate();

    const navigateDoctor = (appointment) => {
        navigate(`/AppointmentRoomPetParent/${appointment.id}`, {
            state: { appointmentId: appointment.id }
        });
    };

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                try {
                    const parentDocRef = doc(db, 'Parents', user.uid);
                    const parentDocSnap = await getDoc(parentDocRef);

                    if (parentDocSnap.exists()) {
                        const parentData = parentDocSnap.data();
                        const appointmentIds = parentData.Appointments || [];

                        const allAppointmentsData = [];

                        for (const appointmentId of appointmentIds) {
                            const appointmentDocRef = doc(db, 'Appointments', appointmentId);
                            const appointmentDocSnap = await getDoc(appointmentDocRef);

                            if (appointmentDocSnap.exists()) {
                                const appointmentData = appointmentDocSnap.data();
                                appointmentData.id = appointmentId;

                                if (appointmentData.PetRefID) {
                                    const petDocRef = doc(db, 'Parents', user.uid, 'pets', appointmentData.PetRefID);
                                    const petDocSnap = await getDoc(petDocRef);

                                    if (petDocSnap.exists()) {
                                        const petData = petDocSnap.data();
                                        appointmentData.PetName = petData.Name || 'Unknown Pet';
                                    } else {
                                        appointmentData.PetName = 'Unknown Pet';
                                    }
                                } else {
                                    appointmentData.PetName = 'Unknown Pet';
                                }

                                allAppointmentsData.push(appointmentData);
                            } else {
                                console.log("No such appointment document!");
                            }
                        }

                        setAllAppointments(allAppointmentsData);
                    } else {
                        console.log("No such parent document!");
                    }
                } catch (error) {
                    console.error("Error fetching user data or appointments:", error);
                }
            } else {
                console.log('User not authenticated');
                navigate('/login');
            }
            setLoading(false);
        });

        return () => unsubscribe();
    }, [auth, db, navigate]);

    if (loading) {
        return <LoadingScreen />;
    }

    // Filtrar citas basadas en el estado seleccionado
    const filteredAppointments = allAppointments.filter((appointment) => {
        if (filter === "Upcoming") {
            return appointment.status === "Upcoming";
        } else if (filter === "Past") {
            return (
                appointment.past === true ||
                (appointment.status === "Incomplete" || appointment.status === "Complete")
            );
        }
        return false;
    });

    return (
        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100vw', height: '100vh' }}>
            <div className='dashboardmain'>
                <NavbarPetParentsDashboard />
                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'column', background: '#fff', borderRadius: '10px', width: '75%', marginTop: '50px', boxShadow: '0 4px 8px rgba(0,0,0,0.1)' }}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column', width: '100%', padding: '15px', borderBottom: '1px solid #dedcd7' }}>
                        <h4 style={{ padding: '0', margin: '0', display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: '900' }}>
                            <FaUserDoctor style={{ fontSize: '30px', margin: '0px 10px 0px 5px', color: '#0849c1' }} />
                            Appointments
                        </h4>
                    </div>

                    {/* Botones de filtro */}
                    <div style={{ marginBottom: "20px", marginRight: "10px" }}>
                        <button style={{ marginRight: "10px" }} onClick={() => setFilter("Upcoming")}>Upcoming</button>
                        <button style={{ marginRight: "10px" }} onClick={() => setFilter("Past")}>Past</button>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', flexDirection: 'column' }}>
                        {filteredAppointments.length === 0 ? (
                            <div class="appointments1-container">
                                <div class="appointments1-content">
                                    <i class="fas fa-calendar-times appointments1-big-icon"></i>
                                    <p>You don't have any appointments in this category.</p>
                                    <NavLink to='/FindAVet'>
                                        <button class="appointments1-book-button" style={{ background: 'linear-gradient(to right, #0015a7, #5667df)', border: 'none', borderRadius: '5px', color: 'white', padding: '10px 20px', fontSize: '16px', cursor: 'pointer' }}>
                                            <i class="fas fa-arrow-right"></i> Book a vet appointment
                                        </button>
                                    </NavLink>
                                </div>
                            </div>
                        ) : (
                            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                {filteredAppointments.map((appointment, index) => (
                                    <div className='PatientDiv' key={index} onClick={() => navigateDoctor(appointment)}>
                                        <div className='SubPatientDiv' style={{ display: 'flex', alignItems: 'center' }}>
                                            <img className='DPhider' src={appointment.profilePictureURL || DocDP} alt='PetParentDP' style={{ height: '40px', width: '40px', borderRadius: '100px', marginLeft: '2vw' }} />
                                            <p style={{ fontSize: '13px', marginLeft: '10px' }}>{appointment.DoctorName || 'Doctor Name'}</p>
                                            <img className='DPhider' src={CatDP} alt='CatDP' style={{ height: '40px', width: '40px', borderRadius: '100px', marginLeft: '2vw' }} />
                                            <p style={{ fontSize: '13px', marginLeft: '10px' }}>
                                                {appointment.PetName || 'Pet Name'} <br />
                                                <span style={{ fontSize: '12px', color: 'grey' }}>{appointment.PetAge || '7 year(s) old'}</span>
                                            </p>
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                                            <p style={{ fontSize: '13px', marginLeft: '2vw' }}>
                                                {appointment.AppointmentDate || 'Jan 21, 2023'}<br />
                                                <span style={{ fontSize: '12px', color: 'grey' }}>{appointment.AppointmentTime || '04:30 PM - 05:30 PM'}</span><br />
                                                <span style={{ fontSize: '12px', color: 'grey'}}  className={appointment.status}>{appointment.status || 'N/A'}</span>
                                            </p>
                                        </div>
                                        <MdArrowRightAlt style={{ fontSize: '80px', color: '#8CC084', marginRight: '2vw' }} />
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AppointmentsPetParent;
