import CutePets from "../../CutePets.js";
import Texture from '../../../1_MediaAssets/OtherBasics/Texture.png';
import Cover from '../../../1_MediaAssets/VetsandTechs/RegistrationVetsandTechs/Cover2.png';
import DocIcon from '../../../1_MediaAssets/VetsandTechs/RegistrationVetsandTechs/DocIcon.png';
import { useState } from 'react';
import { useNavigate} from 'react-router-dom';
import '../../Pets.scss';

const RegistrationVetsandTechsPage1 = () => { 
    // eslint-disable-next-line 
    const [option, setOption] = useState('');
    const navigate = useNavigate();

    function Handleclick(v) {
        if (option === '') {
            return;
        }
        navigate(`${v}?selectedOption=${option}`); 
    }

    function optionClick(e) {
        if (e === 'Veterinarian') {
            setOption(e);
            document.getElementById(e).style.background = "#FFE699";
            document.getElementById('Technician').style.background = "#edeef0";
        }

        if (e === 'Technician') {
            setOption(e);
            document.getElementById(e).style.background = "#FFE699";
            document.getElementById('Veterinarian').style.background = "#edeef0";
        }
    }

    return (
            <div style={{backgroundImage:`url('${Cover}')`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundAttachment: 'fixed'}}>
                <div className='RegistrationWhiteBox' style={{backgroundImage:`url('${Texture}')`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover',}}>
                    <div style={{width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', padding: '30px', flexDirection: 'column'}}>
                        <h2 style={{fontWeight: '900', color: '#002366', fontSize: '30px'}}>Welcome </h2>
                        <h5 style={{fontWeight: '900', marginTop: '-8px', color: '#686869'}}>Rexvets for Veterinarians </h5>
                        <CutePets />
                    </div>

                    <div style={{width: '80%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column'}}>
                        <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>        
                            <p style={{margin: '0', padding: '0', fontWeight: '900'}}>What describes you best?</p>
                        </div>

                        <div className='optionBox'> 
                            <div style={{width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px'}}>
                                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-end', padding: '0', margin: '0'}}>
                                    <div id='Veterinarian' className="content" onClick={()=>{optionClick('Veterinarian')}}>
                                        <h5 style={{color: 'black', marginTop: '15px', fontSize: '14px', fontWeight: '900'}}>Veterinarian</h5>
                                        <img src={DocIcon} alt="Logo" style={{width: '40px'}}/>
                                        <p style={{color: 'black', marginTop: '10px', fontSize: '12px'}}>Designations such as <br></br>DVM, BVMS, BVSc</p>
                                    </div>
                                </div>    
                            </div>
                            
                            <div style={{width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px'}}>
                                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-end', padding: '0', margin: '0'}}>
                                    <div id='Technician' className="content" onClick={()=>{optionClick('Technician')}}>
                                        <h5 style={{color: 'black', marginTop: '15px', fontSize: '14px', fontWeight: '900'}}>Technician</h5>
                                        <img src={DocIcon} alt="Logo" style={{width: '40px'}}/>
                                        <p style={{color: 'black', marginTop: '10px', fontSize: '12px'}}>Designations such as <br></br>RVT, CLV, LVT</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='ButtonofWhiteBox'>
                            <button style={{ width: '200px' }} onClick={() => Handleclick('/RegistrationVetsandTechsPage2')}>Next</button>
                        </div>
                    </div>

                </div>
            </div>
        )
}

export default RegistrationVetsandTechsPage1