import Cover from '../../1_MediaAssets/OtherBasics/Texture.png';
import Logo from '../../1_MediaAssets/Homepage/Logo.png'
import { NavLink } from 'react-router-dom';

const Error = () => {

    return (
            <div style={{ backgroundImage:`url('${Cover}')`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundColor: '#002366', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100vw', height: '100vh', flexDirection: 'column'}}>
                <img src={Logo} alt='logo' style={{width: '70%', maxWidth: '200px'}} />
                <h3 style={{padding: '0', margin: '20px', fontWeight: '900', color: 'white', fontSize: '25px'}}>404 Page not found</h3>
                <NavLink to="/">
                    <button style={{width: '170px', borderRadius: '10px', background: '#FFE699', color: '#002366'}}>Home</button>
                </NavLink>
            </div>
        )
}

export default Error