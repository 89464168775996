import Cover from '../../../1_MediaAssets/VetsandTechs/RegistrationVetsandTechs/Cover2.png';
import Texture from '../../../1_MediaAssets/OtherBasics/Texture.png';
import { NavLink, useLocation } from "react-router-dom";
import CutePets from "../../CutePets.js";
import { getAuth, signInWithEmailAndPassword, browserLocalPersistence, onAuthStateChanged, setPersistence } from "firebase/auth";
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { query, where, collection, getFirestore, getDocs } from "firebase/firestore";

const LoginDoctors = () => { 
    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState('');
    const navigate = useNavigate();
    const db = getFirestore();
    const auth = getAuth();
    const location = useLocation();

    useEffect(() => {
        if (location.state) {
            setMessage(location.state.message);
        }
    }, [location.state]);

    useEffect(() => {
        const checkAuthAndSetPersistence = async () => {
            const user = auth.currentUser;
            if (user) { 
                try {
                    await setPersistence(auth, browserLocalPersistence);
                    onAuthStateChanged(auth, async (user) => {
                        if (user) {
                            const userId = user.uid;
                            const q1 = query(collection(db, "Doctors/Technician/Technician"), where("uid", "==", userId));
                            const q2 = query(collection(db, "Doctors/Veterinarian/Veterinarian"), where("uid", "==", userId));

                            try {
                                const querySnapshot1 = await getDocs(q1);
                                if (!querySnapshot1.empty) {
                                    navigate('/DashboardVetsandTechs', { state: { user: querySnapshot1.docs[0].data() } });
                                } else {
                                    const querySnapshot2 = await getDocs(q2);
                                    if (!querySnapshot2.empty) {
                                        navigate('/DashboardVetsandTechs', { state: { user: querySnapshot2.docs[0].data() } });
                                    } else {
                                        navigate('/DashboardPetParents');
                                    }
                                }
                            } catch (fetchError) {
                                console.error("Error fetching technician data:", fetchError);
                                setMessage("An error occurred while fetching data.");
                            }
                        }
                        setLoading(false);
                    });
                } catch (error) {
                    console.error("Error setting persistence or onAuthStateChanged:", error);
                    setMessage("An error occurred during authentication.");
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        };
        checkAuthAndSetPersistence();
    }, [auth, db, navigate]);

    const handleSubmit = async () => {
        setMessage('');
        document.getElementById("buttoon").innerHTML = "Loading...";
    
        const email = document.getElementById('email').value;
        const password = document.getElementById('password').value;
    
        if (!email || !password) {
            setMessage("Input all fields!");
            document.getElementById("buttoon").innerHTML = "Sign in";
            return;
        }
    
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;
            localStorage.setItem('userId', user.uid);

            const userId = user.uid;
            const q1 = query(collection(db, "Doctors/Technician/Technician"), where("uid", "==", userId));
            const q2 = query(collection(db, "Doctors/Veterinarian/Veterinarian"), where("uid", "==", userId));
            
            try {
                const querySnapshot1 = await getDocs(q1);
                if (!querySnapshot1.empty) {
                    navigate('/DashboardVetsandTechs', { state: { user: querySnapshot1.docs[0].data() } });
                } else {
                    const querySnapshot2 = await getDocs(q2);
                    if (!querySnapshot2.empty) {
                        navigate('/DashboardVetsandTechs', { state: { user: querySnapshot2.docs[0].data() } });
                    } else {
                        setMessage("No such document!");
                        navigate('/DashboardPetParents');
                    }
                }
            } catch (fetchError) {
                console.error("Error fetching technician data:", fetchError);
                setMessage("An error occurred while fetching data.");
            }
        } catch (error) {
            console.error("Authentication error:", error);
            let errorMessage = "An error occurred. Please try again.";
    
            switch (error.code) {
                case 'auth/invalid-email':
                    errorMessage = "Invalid email address.";
                    break;
                case 'auth/user-not-found':
                    errorMessage = "No user found with this email.";
                    break;
                case 'auth/wrong-password':
                    errorMessage = "Incorrect password.";
                    break;
                case 'auth/invalid-login-credentials':
                    errorMessage = "Invalid login credentials.";
                    break;
                case 'auth/network-request-failed':
                    errorMessage = "Network error. Check your connection.";
                    break;
                case 'auth/too-many-requests':
                    errorMessage = "Too many requests. Try again later.";
                    break;
                default:
                    errorMessage = "An unexpected error occurred. Please try again.";
            }
    
            setMessage(errorMessage);
            document.getElementById("buttoon").innerHTML = "Sign in";
        }
    };
    
    function navigateUser() {
        navigate('/ForgotPasswordDoctors');
    }

    return (
        <div style={{ minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden', backgroundImage: `url('${Cover}')`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
            <div className='RegistrationWhiteBox' style={{ backgroundImage: `url('${Texture}')`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', marginTop: '30px', marginBottom: '30px' }}>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', padding: '30px', flexDirection: 'column' }}>
                    <h2 style={{ fontWeight: '900', color: '#002366', fontSize: '30px' }}>Welcome Back Doctor!</h2>
                    <h5 style={{ fontWeight: '900', marginTop: '-8px', color: '#686869' }}>Sign in to the dashboard</h5>
                    <p style={{ fontWeight: '900', marginTop: '10px', color: '#686869' }}>Do not have an account? 
                        <NavLink to='/RegistrationVetsandTechsPage1' style={{color: '#002366', textDecoration: 'underline', marginLeft: '10px'}}>
                            Register
                        </NavLink>
                    </p>
                    <CutePets />
                </div>
                <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column', marginTop: '-15px' }}>
                    <div className='optionBox' style={{ marginTop: '0', height: '100%', justifyContent: 'flex-start', alignItems: 'flex-start', marginBottom: '10px' }}>
                        <div className='OnlyColumnPage2' style={{ padding: '0px 30px 0px 30px' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginTop: '10px' }}>
                                <label style={{ fontWeight: '900', fontSize: '13px' }}>Email</label>
                                <input type="text" id='email' style={{ width: "100%", height: '35px', borderRadius: '5px', padding: '8px', border: '1px solid grey', fontSize: '14px' }} />
                            </div> 
                            

                            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginTop: '10px' }}>
                                <label style={{ fontWeight: '900', fontSize: '13px' }}>Password</label>
                                <input type="password" id='password' style={{ width: "100%", height: '35px', borderRadius: '5px', padding: '8px', border: '1px solid grey', fontSize: '14px' }} />
                            </div>
                            <p onClick={navigateUser} style={{ marginTop: "10px", textDecoration: 'underline', color: 'gray', cursor: 'pointer', fontSize: '13px' }}>Forgot Password</p>

                            <div id='message' style={{ margin: '0px 0px 20px 0px', color: 'red', fontSize: '11px', width: '100%', textAlign: 'center', height: '13px' }}></div>
                        
                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', flexDirection: 'column'}}>
                                <button id='buttoon' style={{ width: '100%' }} onClick={handleSubmit}>Sign in</button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoginDoctors;
